import React from 'react'
import { Button, FormControlLabel, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import Stack from '@mui/material/Stack/Stack'

import { useAppDispatch, useAppSelector } from '../../redux/storeExports'
import {
  AuthenticationActions,
  getPreferredMfa,
  selectPreferredMfa,
} from '@meprism/shared/src/redux/authentication/authenticationSlice'

import { useAuthContext } from '@meprism/shared/src/context/AuthContext'
import {
  getCurrentAuthenticatedUser,
  setPreferredMfa,
} from '@meprism/shared/src/services/AuthenticationService'
import { useNavigate } from 'react-router-dom'
import { store } from '../../redux/store'
import { Toast } from '../toast/toast'

const styles = {
  saveAuthentication: {
    background: 'white',
    border: '1px solid #c67eff',
    color: '#c67eff',
    '&:hover': {
      color: 'white !important',
      background: '#c67eff',
    },
    '&:disabled': {
      color: 'white !important',
      background: '#e0c4f6',
    },
  },
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  marginInline: 10,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

export const MfaCard = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const authContext = useAuthContext()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      AuthenticationActions.setDraftMfa(
        event.target.checked ? 'SOFTWARE_TOKEN_MFA' : 'NOMFA',
      ),
    )
  }
  const { draftPreferredMfa, registeredPreferredMfa } =
    useAppSelector(selectPreferredMfa)
  const enableTwoFactor = draftPreferredMfa !== 'NOMFA'

  const onTwoFactorSubmit = async () => {
    authContext.setUser(await getCurrentAuthenticatedUser())
    if (draftPreferredMfa === 'SOFTWARE_TOKEN_MFA') {
      navigate('/totp', { state: { from: location.pathname } })
    } else {
      setPreferredMfa('NOMFA').then(() => {
        store.dispatch(getPreferredMfa())
        Toast.show({
          type: 'success',
          text1: 'Your MFA settings have been saved',
        })
      })
    }
  }

  return (
    <>
      <Grid item md={12} xs={12}>
        <Typography variant="h3">Authentication</Typography>
      </Grid>
      <Grid item md={12} xs={12}>
        <FormControlLabel
          control={
            <IOSSwitch checked={enableTwoFactor} onChange={handleChange} />
          }
          label="Two Factor Authentication"
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Stack spacing={2}>
          <Typography variant={'body2'} sx={{ fontSize: 14 }}>
            Use your mobile authenticator app to generate one-time passcodes
            code
          </Typography>
        </Stack>
      </Grid>
      <Grid item md={12} xs={12}>
        <Button
          sx={styles.saveAuthentication}
          onClick={onTwoFactorSubmit}
          disabled={draftPreferredMfa === registeredPreferredMfa}>
          Save Changes
        </Button>
      </Grid>
    </>
  )
}
