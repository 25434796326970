import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/storeExports'
import {
  postFamilyStatus,
  selectFamilyStatus,
} from '@meprism/shared/src/redux/product/ProductSlice'

const styles = {
  header: {
    color: '#040115',
    mb: 5,
  },
  invited: {
    color: '#ffc300 ',
    paddingLeft: '1rem',
  },

  accepted: {
    color: '#2ecc71',
    paddingLeft: '1rem',
  },
  buttonTheme: {
    border: '1px solid #d99ff9',
    backgroundColor: 'white !important',
    color: '#d99ff9 !important',
    '&:hover': {
      color: 'white !important',
      backgroundColor: '#d99ff9 !important',
    },
  },
}

const PlusOne = () => {
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery('(max-width:600px)')
  const familyPlan = useAppSelector(selectFamilyStatus)
  const [email, setEmail] = useState('')
  const [isSuccess, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const handleSubscribe = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const maxChars = 100; // Maximum characters allowed for email

    if (email.length > maxChars) {
      setError('Email exceeds maximum of 100 character limit')
      return;
    }
    if (!emailRegex.test(email) ) {
      setError('Invalid email format')
      return
    }
    if (email !== '') {
      await dispatch(postFamilyStatus(email)).unwrap()
      if (!familyPlan.error) {
        setSuccess(true)
      }
    }
  }

  return (
    <Box sx={{ flexDirection: isMobile ? 'column' : 'row' }}>
      {!isMobile && (
        <Typography variant="h3" sx={styles.header}>
          Plus One Management
        </Typography>
      )}
      <Box sx={{ marginBlock: '1rem' }}>
        <Typography variant="body2">
          Invite your plus one to your account. Once you have sent the invite,
          you cannot change this.{' '}
          <Link href="https://meprism1.atlassian.net/servicedesk/customer/portal/1">
            Contact mePrism support
          </Link>{' '}
          for further assistance.
        </Typography>
      </Box>
      {familyPlan.email ? (
        <Box>
          <Typography variant="body2">
            {familyPlan.email}
            <span
              style={
                ['invited', 'queued'].includes(familyPlan.invite_status)
                  ? styles.invited
                  : styles.accepted
              }>
              {['invited', 'queued'].includes(familyPlan.invite_status)
                ? 'Invited'
                : familyPlan.invite_status.charAt(0).toUpperCase() +
                  familyPlan.invite_status.slice(1)}
            </span>
          </Typography>
        </Box>
      ) : !isSuccess ? (
        <Grid container columnSpacing={5} rowSpacing={5}>
          <Grid item md={8} xs={12}>
            <TextField
              size="small"
              error={!!error}
              helperText={error ?? ''}
              onChange={(e) => {
                setEmail(e.target.value)}
              }
              placeholder="Enter email..."
              sx={{ background: 'white', width: '100%' }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Button sx={styles.buttonTheme} onClick={handleSubscribe}>
              Send Invite
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Typography variant="body2">
            {email}
            <span style={styles.invited}>Invited</span>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default PlusOne
