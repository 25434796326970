import React from 'react'
import {  useLocation } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  Box,
  BoxProps,
  Container,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material'
import data from '@meprism/shared/src/assets/icons/Data.svg'
import Link from '@mui/material/Link'
import infotip from '@meprism/shared/src/assets/icons/Infotip.svg'
import letter from '@meprism/shared/src/assets/icons/Letter.svg'
import logo from '@meprism/shared/src/assets/icons/logos/mePrismprivacynew.svg'
import background from '../../assets/images/IndividualCreateBackground.png'
import { ThemeProvider } from '@emotion/react'
import { MpReTheme, fonts, theme } from '../../theme/OnboardingTheme'
import icon4 from '@meprism/shared/src/assets/icons/lucide_scan-text.svg'
import icon5 from '@meprism/shared/src/assets/icons/no.svg'
import { PrivacyOnboardingView } from './PrivacyOnboardingView'
import MpTheme from '@meprism/shared/src/config/MpTheme'
export type SignInTab = 'create' | 'sign in'
function Item(props: BoxProps) {
  const { ...other } = props
  return <Box {...other} />
}
export const AuthCopy = ({ }: { mode: SignInTab }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <>
      <ThemeProvider theme={theme}>
        {!isMobile &&( <Box sx={{ px: 6, pt: 10 }}>
          <img src={logo} alt="Logo" style={{ width: 200, height: 50 }} />{' '}
        </Box>)}
       

        <Stack
          sx={{
            px: 10,
            pt: 10,
            pb: 20,
            '& .MuiTypography-root': {
              color: '#FFFFFF',
            },
            justifyContent: 'start',
            minHeight: '100vh' 
          }}>
          <Typography
            variant={'h1'}
            sx={{
              my: 2,
              fontWeight: 'bold',
              maxWidth: !isMobile ? '500px' : 'unset',
            }}>
            Create Your mePrism Profile
          </Typography>
          <Box
            sx={{
              display: 'flex',
              columnGap: 1,
              rowGap: 1,
              alignItems: 'center',
              gridTemplateColumns: 'auto, 5fr',
            }}>
            <Item>
              <img
                src={data}
                alt="Logo"
                style={{ marginRight: 8, width: 42, height: 42 }}
              />
            </Item>
            <Item>
              {' '}
              <Typography variant="body2">
              Profile data:
                <span style={{ marginLeft:'5px',color: '#EFEDFDB2' }}>
                Your full name, city, state and date of birth are essential for us to locate your data   on the data broker sites.  
                </span>
              </Typography>{' '}
            </Item>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mt:3,
              columnGap: 1,
              rowGap: 1,
              alignItems: 'center',
              gridTemplateColumns: 'auto, 1fr',
            }}>
            <Item>
              <img
                src={letter}
                alt="Logo"
                style={{ marginRight: 8, width: 42, height: 42 }}
              />
            </Item>
            <Item sx={{ gridColumn: 'span 5' }}>
              <Typography variant="body2">
              Authorization Letter:
                <span style={{ marginLeft:'5px',color: '#EFEDFDB2' }}>After entering your profile info, you’ll be prompted to sign an authorized agent letter. This step is crucial in dealing with uncooperative sites. </span>
                
              </Typography>{' '}
            </Item>
          </Box>
          <Box
            sx={{
              display: 'flex',
              columnGap: 1,
              rowGap: 1,
              mt:3,
              gridTemplateColumns: 'auto, 1fr',
              alignItems: 'center',
            }}>
            <Item>
              <img
                src={infotip}
                alt="Logo"
                style={{ marginRight: 8, width: 42, height: 42 }}
              />
            </Item>
            <Item>
              <Typography variant="body2">
              Pro Tip: 

                <span style={{ marginLeft:'5px',color: '#EFEDFDB2' }}>After your initial sign-up, adding past addresses and alternative names significantly enhances our search for your exposed data. 
</span>
             
              </Typography>
            </Item>
          </Box>
          <Box
        sx={{
          alignItems: 'left',
          marginTop: 4,
          p: 10,
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          border:
            '2px solid var(--text-colors-stroke, rgba(255, 255, 255, 0.15))',
          borderRadius: '20px',
          backdropFilter: 'blur(20.5px)',
        }}>
        <Typography variant="h4">
          Information <span style={{ color: '#C67EFF' }}>Security</span>
        </Typography>
        <Grid
  container
  spacing={4}
  sx={{
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 4,
  }}
>
  {/* Item 1 */}
  <Grid item xs={12} md={6}>
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <img
        src={icon4}
        alt="Icon2"
        style={{
          verticalAlign: 'middle',
          marginRight: 8,
          width: 24,
          height: 24,
        }}
      />
      <Typography variant="body3">
        mePrism is SOC 2 compliant with industry-grade cybersecurity standards for handling sensitive data
      </Typography>
    </Box>
  </Grid>

  {/* Item 2 */}
  <Grid item xs={12} md={6}>
    <Box sx={{ display: 'flex', alignItems: 'flex-start', marginTop: isMobile ? 4 : 0 }}>
      <img
        src={icon5}
        alt="Icon2"
        style={{
          verticalAlign: 'middle',
          marginRight: 8,
          width: 24,
          height: 24,
          marginLeft: 5,
        }}
      />
      <Typography variant="body3">
        We do not sell your information
      </Typography>
    </Box>
  </Grid>
</Grid>


      </Box>
        </Stack>
      </ThemeProvider>
    </>
  )
}
export const PrivacyOnboardingWrapper = () => {
  const location = useLocation()
  const textStyle = {
    ...fonts.Inter.Regular,
    fontSize: '16px',
  }
  const mode: SignInTab = location.pathname.includes('create')
    ? 'create'
    : 'sign in'
  const isWide = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} sx={{ background: '#1A1B20', minHeight: '100vh' ,position:'relative' }}>
        <Grid container>
          <Grid item xs={12} md={5} sx={{mb:10}}>
         {!isWide &&(<Box sx={{ px: 1, pt: 10 }}>
          <img src={logo} alt="Logo" style={{ width: 200, height: 50 }} />{' '}
        </Box>)} 
            {!isWide ? (<Tabs
                sx={{ px: 3, pt: 10,".MuiTab-root":{ alignItems: "start", },  }}
                value={2}
                indicatorColor="secondary"
                textColor="primary"
                centered={false}>
                <Tab
                style={{minWidth:"20%"}}
                  label="1."
                  sx={{
                    flexGrow: 0,
                    
                    textTransform: 'none',
                    fontFamily: fonts.Inter.SemiBold,
                   
                  }}
                />
                <Tab
                  label="2."
                  style={{minWidth:"20%"}}
                  sx={{
                    
                    flexGrow: 0,
                  
                    margin:'0 2px',
                    textTransform: 'none',
                    fontFamily: fonts.Inter.SemiBold,
                  }}
                />
                <Tab
                  label="3.Set Profile"
                  sx={{
                    width:'40%', 
                    color: 'inherit',  
                    '&.Mui-selected': {
                      color: MpReTheme.colors.tertiary.pink.default,
                    },
                    flexGrow: 1,
                    margin:'0 2px',
                    textTransform: 'none',
                    fontFamily: fonts.Inter.SemiBold,
                  }}
                />
                <Tab
                  label="4."
                  style={{minWidth:"20%"}}
                  sx={{
                    flexGrow: 0,
                    margin:'0 2px',
                    textTransform: 'none',
                    fontFamily: fonts.Inter.SemiBold,
                  }}
                />
              </Tabs>):(<Tabs
              
              sx={{ px: 3, pt: 10,".MuiTab-root":{ alignItems: "start", }, }}
              value={2}
              indicatorColor="secondary"
              textColor="primary"
              centered>
              <Tab
                label="1.Sign Up"
                sx={{
                  margin:'0 5px',
                  textTransform: 'none',
                  fontFamily: fonts.Inter.SemiBold,
                  borderBottom: '2px solid #EFEDFDB2',
                }}
              />
              <Tab
                label="2.Verify"
                sx={{
                  margin:'0 5px',
                  textTransform: 'none',
                  fontFamily: fonts.Inter.SemiBold,
                  borderBottom: '2px solid #EFEDFDB2',
                }}
              />
              <Tab
                label="3.Set Profile"
                sx={{
                  margin:'0 5px',
                  textTransform: 'none',
                  fontFamily: fonts.Inter.SemiBold,
                  color: 'inherit',
                  '&.Mui-selected': {
                    color: MpReTheme.colors.tertiary.pink.default,
                  },
                }}
              />
              <Tab
                label="4.Authorization"
                sx={{
                  margin:'0 5px',
                  textTransform: 'none',
                  fontFamily: fonts.Inter.SemiBold,
                  borderBottom: '2px solid #EFEDFDB2',
                }}
              />
            </Tabs>)}
            <PrivacyOnboardingView />
            {!isWide && ( <Box sx={{  maxWidth: MpTheme.layouts.widths.sm,width: '100%'}}>
              <Stack
                direction={'row'}
                sx={{ alignItems: 'center', mt: 15,}}
                spacing={isWide?15:10}
                justifyContent={'space-between'}>
                <Link
                  href={'https://meprism.com/terms-conditions'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Terms & Conditions
                </Link>
                <Link
                  href={'https://meprism.com/privacy-policy'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Privacy Policy
                </Link>
                <Link
                  href={'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Contact Us
                </Link>
              </Stack>
            </Box>)}
           
          </Grid>
          <Grid
            item
            xs={0}
            md={7}
            sx={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}>
              <Box sx={{position:'relative',height:'100%'}}>
            <AuthCopy mode={mode} />
            <Box sx={{mb:10,position:'absolute',bottom: !isWide?'-5px':'-10px'}}>
            <Typography variant="body4" sx={{ mx: 10 }}>
              Are you a Business?
              <Link
                href={
                  'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17'
                }
                style={{ textDecoration: 'underline', fontSize: '16px' }}
                sx={{
                  ml:2,
                  ...textStyle,
                  color: MpReTheme.colors.tertiary.pink.default,
                }}>
                Contact Us
              </Link>
            </Typography></Box></Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}
