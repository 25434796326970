import { Box, ButtonBase, Typography } from '@mui/material'
import React from 'react'
import MpSvgIcon from '../atom/MpSvgIcon'

interface IsThisYouProps {
  style?: any
  onAccuratePress: (accurate: boolean) => void
  validated: undefined | boolean
}

const IsThisYou = (props: IsThisYouProps) => {
  const onThumbsUpPress = () => {
    if (props.validated !== true) {
      props.onAccuratePress(true)
    }
  }

  const onThumbsDownPress = () => {
    if (props.validated !== false) {
      props.onAccuratePress(false)
    }
  }

  return (
    <Box
      sx={[
        {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '20px',
          marginBottom: '32px',
        },
        props.style,
      ]}>
      {props.validated === true || props.validated === undefined ? (
        <ButtonBase onClick={onThumbsDownPress}>
          <MpSvgIcon icon={'notMe'} />
          <Typography
            variant={'label'}
            color={'error'}
            sx={{ marginLeft: '7px' }}>
            This Is Not Me
          </Typography>
        </ButtonBase>
      ) : (
        <ButtonBase onClick={onThumbsUpPress}>
          <MpSvgIcon icon={'me'} />
          <Typography
            variant={'label'}
            color={'success.main'}
            sx={{ marginLeft: '7px' }}>
            This Is Me
          </Typography>
        </ButtonBase>
      )}
    </Box>
  )
}

export default IsThisYou
