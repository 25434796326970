import { z } from 'zod'

export const phoneNumberSchema = z.string().transform((p, ctx) => {
  const stripped = p.replace(/[^\d]/g, '')
  if (!stripped) {
    // empty phones are allowed
    return stripped
  }
  // we expect 10 digits without the country code
  if (stripped.length !== 10) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Invalid phone number',
    })
    return p
  }
  return stripped ? `+1${stripped}` : stripped
})
