import {
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  TextField,
  Grid,
  Button,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import tick from '@meprism/shared/src/assets/icons/tick.svg'
import MainHeader from '../shared/organism/MainHeader'
import { fonts, theme } from '../../theme/DashboardTheme'
import { useAppDispatch, useAppSelector } from '../../redux/storeExports'
import {
  createPortalSession,
  fetchProducts,
  purchaseSubscription,
  redeemCodeForWeb,
  selectActiveGrant,
  selectPendingOffer,
  selectProductsByPaymentInterval,
} from '../../redux/product/ProductSlice'
import { PaymentInterval } from '@meprism/shared/src/redux/product/ProductTypes'

import { Logger } from '@meprism/app-utils'
const styles = {
  price: {
    ...fonts.Inter.Medium,
    fontSize: '50px',
    color: '#040115',
  },
  perMonth: {
    ...fonts.Inter.SemiBold,
    fontSize: '18px',
  },
  caption: {
    ...fonts.Inter.Bold,
    fontSize: '16px',
  },
  body: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'start',
    color: '#1A1B20',
    ...fonts.Inter.Regular,
    fontSize: '16px',
  },
  marginCaption: {
    marginBottom: '18px',
  },
  icon: {
    marginTop:'5px',
    marginRight: '5px',
  },
  borderHighlight: {
    border: '5px solid #C67EFF',
  },
  attachedText: {
    position: 'absolute',
    background: '#C67EFF',
    ...fonts.Inter.SemiBold,
    fontSize: '16px',
    padding: '5px 0px 5px 0px',
    textAlign: 'center',
    color: '#fff',
    top: '-1px',
    left: 0,
    right: 0,
    fontWeight: 'bold',
  },
} as const

const SubscriptionView = () => {
  const dispatch = useAppDispatch()
  const interval: PaymentInterval = 'yearly'
  const products = useAppSelector((state) =>
    selectProductsByPaymentInterval(state, interval),
  )
  const offer = useAppSelector(selectPendingOffer)
  const [code, setCode] = useState(offer?.code || '')
  const activeGrant = useAppSelector(selectActiveGrant)
  useEffect(() => {
    dispatch(fetchProducts())
  }, [dispatch])
  const individualProduct = Object.values(products)[1]
  const individualPrice = individualProduct?.price_cents ?? 0
  const individualMonthlyPrice = individualProduct?.sku.includes('year')
    ? individualPrice / 12
    : individualPrice
  const formattedIndividualMonthlyPrice = (
    Math.floor(individualMonthlyPrice) / 100
  ).toFixed(2)
  const formattedIndividualAnnualPrice = (individualPrice / 100).toFixed(2)

  const familyProduct = Object.values(products)[0]
  const familyPrice = familyProduct?.price_cents ?? 0
  const familyMonthlyPrice = familyProduct?.sku.includes('year')
    ? familyPrice / 12
    : familyPrice
  const formattedFamilyMonthlyPrice = (
    Math.floor(familyMonthlyPrice) / 100
  ).toFixed(2)
  const formattedFamilyAnnualPrice = (familyPrice / 100).toFixed(2)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const onManageSubscription = async () => {
    const response = await dispatch(createPortalSession()).unwrap()
    window.location.href = response.url
  }

  return (
    <Container
      fixed
      maxWidth={'lg'}
      sx={{  margin: '1rem' }}>
      <MainHeader title={'Subscription'} />
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}>
        <Card
          sx={{
            width: isMobile ? '100%' : '33%',
            marginBottom: '1rem',
            borderRadius: '20px',
            position: 'relative',
            ...(!activeGrant ? styles.borderHighlight:{})
          }}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 10,
            }}>
            {!activeGrant && (
              <Typography sx={{ ...styles.attachedText }}>
                Current plan
              </Typography>
            )}

            <Typography variant="h3" component="div" sx={styles.marginCaption}>
              Free
            </Typography>
            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography sx={styles.price} mr={2}>
                $0
              </Typography>
              <Typography sx={styles.perMonth}>per month</Typography>
            </Stack>
            <Typography sx={styles.caption} component="div">
              No credit card required
            </Typography>
            <Stack display={'flex'} flexDirection={'column'} mt={2} mb={10}>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick}  /> Find your leaked data on
                Google and data broker sites
              </Typography>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick} />
                See your results in minutes in your Privacy Dashboard
              </Typography>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick} /> Customer Support and
                self-help resources
              </Typography>
            </Stack>
          </CardContent>
        </Card>

        {/* Card 2 */}
        <Card
          sx={{
            width: isMobile ? '100%' : '33%',
            marginBottom: '1rem',
            borderRadius: '20px',
            ...(activeGrant && activeGrant.sku && 
              activeGrant?.sku.split('.')[2] === 'pro' &&
              styles.borderHighlight),
            position: 'relative',
          }}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 10,
            }}>
            {activeGrant && activeGrant.sku && activeGrant?.sku.split('.')[2] === 'pro' && (
              <Typography sx={{ ...styles.attachedText }}>
                Current plan
              </Typography>
            )}

            <Typography variant="h3" component="div" sx={styles.marginCaption}>
              Individual
            </Typography>
            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography sx={styles.price} mr={2}>
                $ {formattedIndividualMonthlyPrice}
              </Typography>
              <Typography sx={styles.perMonth}>per month</Typography>
            </Stack>
            <Typography sx={styles.caption} component="div">
              $ {formattedIndividualAnnualPrice} billed annually
            </Typography>
            <Stack display={'flex'} flexDirection={'column'} mt={2} mb={10}>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick} /> Removal from Google and
                dozens of data broker websites
              </Typography>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick} />
                Persistent scanning every 30 days with continuous removals
              </Typography>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick} /> Alerts when your data
                reappears online
              </Typography>
              <Typography sx={styles.body}>
                {' '}
                <img style={styles.icon} src={tick} /> Privacy Dashboard and
                progress reports
              </Typography>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick} /> Priority Customer Support
              </Typography>
            </Stack>
            {!activeGrant&&(<Button
            variant={'text'}
            sx={{width:'100%',height:'50px'}}
            onClick={async () => {
              try {
                const session = await dispatch(
                  purchaseSubscription({
                    sku: individualProduct && individualProduct?.sku,
                    ext_price_id: individualProduct && individualProduct?.price_id,
                    success_url: window.location.origin + '/subscribe/success',
                    cancel_url: window.location.href,
                  }),
                ).unwrap();
                window.location.href = session.url;
              } catch (error) {
                Logger.error('OH NO CANT SUBSCRIBE');
              }
            }}>
           Get Started
          </Button>)}
          {activeGrant &&  ((activeGrant.sku &&activeGrant?.sku.split('.')[2] === 'pro'))&&(  <Button
                variant={'contained'}
                sx={{
                  width:'100%',
                  height: 50,
                  borderRadius: '10px',
                  boxShadow: 'unset',
                  border: '2px solid #C67EFF',
                  background: 'transparent',
                  color: '#C67EFF',
                  '&.Mui-disabled': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                  ':hover': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                }}
                onClick={onManageSubscription}>
                Manage Subscription
              </Button>)}
          </CardContent>
        </Card>

        {/* Card 3 */}
        <Card
          sx={{
            width: isMobile ? '100%' : '33%',
            marginBottom: '1rem',
            borderRadius: '20px',
            ...(activeGrant && ((activeGrant.sku && activeGrant?.sku.split('.')[2] === 'fam') || activeGrant.grant_source.includes('family')) &&
            styles.borderHighlight),          
              position:'relative'
          }}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 10,
            }}>
            {activeGrant &&  ((activeGrant.sku &&activeGrant?.sku.split('.')[2] === 'fam') ||activeGrant.grant_source.includes('family'))&&(
              <Typography sx={{ ...styles.attachedText }}>
                Current plan
              </Typography>
            )}

            <Typography variant="h3" component="div" sx={styles.marginCaption}>
              Family
            </Typography>
            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography sx={styles.price} mr={2}>
                $ {formattedFamilyMonthlyPrice}
              </Typography>
              <Typography sx={styles.perMonth}>per month</Typography>
            </Stack>
            <Typography sx={styles.caption} component="div">
              $ {formattedFamilyAnnualPrice} billed annually
            </Typography>
            <Stack display={'flex'} flexDirection={'column'} mt={2} mb={10}>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick} /> Covers two people
              </Typography>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick} /> Removal from Google and
                dozens of data broker websites
              </Typography>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick} /> Persistent scanning every
                30 days with continuous removals
              </Typography>
              <Typography sx={styles.body}>
                {' '}
                <img style={styles.icon} src={tick} /> Alerts when your data
                reappears online
              </Typography>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick} /> Privacy Dashboard and
                progress reports
              </Typography>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick} /> Priority Customer Support
              </Typography>
              <Typography sx={styles.body} mr={2}>
                <img style={styles.icon} src={tick} /> Ability to add &apos;plus
                one&apos; child account
              </Typography>
            </Stack>
            {!activeGrant && (
              <Button variant={'text'}  sx={{width:'100%',height:'50px'}} onClick={async () => {
                try {
                  const session = await dispatch(
                    purchaseSubscription({
                      sku: familyProduct && familyProduct?.sku,
                      ext_price_id: familyProduct && familyProduct?.price_id,
                      success_url: window.location.origin + '/subscribe/success',
                      cancel_url: window.location.href,
                    }),
                  ).unwrap();
                  window.location.href = session.url;
                } catch (error) {
                  Logger.error('OH NO CANT SUBSCRIBE');
                }
              }}>
                Get Started
              </Button>
            )}
            
             {activeGrant && activeGrant.sku && activeGrant?.sku.split('.')[2] === 'pro'  &&(<Button
            variant={'text'}
            sx={{width:'100%',height:'50px'}}
            onClick={() => window.open('https://meprism1.atlassian.net/servicedesk/customer/portal/1', '_blank')}>
           Contact us to upgrade
          </Button>)}
          {activeGrant &&  ((activeGrant.sku &&activeGrant?.sku.split('.')[2] === 'fam') ||activeGrant.grant_source.includes('family'))&&(  <Button
                variant={'contained'}
                sx={{
                  width:'100%',
                  height: 50,
                  borderRadius: '10px',
                  boxShadow: 'unset',
                  border: '2px solid #C67EFF',
                  background: 'transparent',
                  color: '#C67EFF',
                  '&.Mui-disabled': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                  ':hover': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                }}
                onClick={onManageSubscription}>
                Manage Subscription
              </Button>)}
        
          </CardContent>
        </Card>
      </div>
      <Card sx={{ marginBottom: '1rem', borderRadius: '20px', width: '100%' }}>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 10,
          }}>
          <Typography variant="h3" component="div" sx={styles.marginCaption}>
            Have a Code?
          </Typography>

          <Typography variant="h4" color={'#615F6B'}>
            Redeem Code
          </Typography>
          <Grid container sx={{ width: '100%' }}>
            <Grid item md={5} xs={12} mt={2} mr={!isMobile ? 5:0}>
              <TextField
                sx={{ background: '#fff' }}
                fullWidth
                value={code}
                onChange={(event) => setCode(event.target.value)}
              />
            </Grid>
            <Grid item md={4} xs={12} mt={2}>
              <Button
                variant={'contained'}
                sx={{
                  width:isMobile? '100%':'40%',
                  height: 50,
                  borderRadius: '10px',
                  boxShadow: 'unset',
                  border: '2px solid #C67EFF',
                  background: 'transparent',
                  color: '#C67EFF',
                  '&.Mui-disabled': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                  ':hover': {
                    background: 'transparent',
                    color: '#C67EFF',
                  },
                }}
                onClick={() => dispatch(redeemCodeForWeb(code))}>
                Apply
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}

export default SubscriptionView
