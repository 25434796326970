import React, { PropsWithChildren, Suspense } from 'react'
import {
  Await,
  defer,
  LoaderFunction,
  Navigate,
  useLoaderData,
  useLocation,
} from 'react-router-dom'
import {
  fetchInitialAuthStatus,
  IsUserAuthenticatedSelector,
} from '../../redux/authentication/AuthenticationSlice'
import { store } from '../../redux/store'
import { LoadingFallback } from '../shared/atom/LoadingFallback'

export const requireAuthLoader: LoaderFunction = async () => {
  const hasFetched = await store.dispatch(fetchInitialAuthStatus()).unwrap()
  return defer({
    fetchInitialAuthStatus: hasFetched,
  })
}

const RequireAuth = (props: PropsWithChildren) => {
  const isUserAuthenticated = IsUserAuthenticatedSelector()

  const location = useLocation()
  const query1 = location.search.split('?')?.[1]?.split('=')
  let pageUrl = '/login/create'
  if (query1?.[0] === 'irclickid') {
    pageUrl = pageUrl + `?irclickid=${query1[1]}`
  }

  if (!isUserAuthenticated) {
    return (
      <Navigate
        to={pageUrl}
        replace
        state={{ from: window.location.pathname }}
      />
    )
  }

  return <>{props.children}</>
}

const AuthWrapper = (props: PropsWithChildren) => {
  const data = useLoaderData() as any

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Await resolve={data.fetchInitialAuthStatus}>
        <RequireAuth>{props.children}</RequireAuth>
      </Await>
    </Suspense>
  )
}

export default AuthWrapper
