import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useMediaQuery, Grid, Box } from '@mui/material';
import { theme } from '../../../theme/DashboardTheme'

interface PieChartCardProps {
  title: string;
removed:number;
removing:number;
  color1: string;
  color2:string;
  icon1: string; 
  icon2: string; 
  bgcolor:string;
  title1:string;
  title2:string;
  totalcount1:number;
  totalcount2:number;
}


const ExposureStatsCard: React.FC<PieChartCardProps> = ({ title,color2, color1,icon1,bgcolor,title1,title2,icon2,totalcount1,totalcount2 }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card sx={{ borderRadius: 5 }}>
      <CardContent>
        <Typography variant="h3" sx={{ mb: 14}}>
          {title}
        </Typography>
        <Grid container spacing={isMobile ? 3 : 0} alignItems="center">
          {/* Left side with Pie Chart */}

          {/* Right side with text */}
          <Grid item xs={12} md={12}>
            <Box display="flex" alignItems="center" mb={2} justifyContent="space-between" p={2} bgcolor={bgcolor} borderRadius={2}>
              <Box display="flex" alignItems="center" p={3}>
              <Box p={2} bgcolor={color1} borderRadius={2} alignItems="center" mr={1}>
                  <img src={icon1} alt="icon1" width={18} height={18} style={{ display: 'block', margin: 'auto' }} />
                </Box>
                <Typography variant="h4" ml={2}>{title1}</Typography>
              </Box>
              {/* Corner number */}
              <Typography variant="h2">{totalcount1}</Typography>
            </Box>
            <Box mt={5}display="flex" alignItems="center" justifyContent="space-between" p={2} bgcolor={bgcolor} borderRadius={2}>
              <Box display="flex" alignItems="center" p={3}>
                {/* Icon */}
                <Box p={2} bgcolor={color2} borderRadius={2} alignItems="center" mr={1}>
                  <img src={icon2} alt="icon1" width={18} height={18} style={{ display: 'block', margin: 'auto' }} />
                </Box>
             
                {/* Text */}
                <Typography variant="h4" ml={2}>{title2}</Typography>
              </Box>
              {/* Corner number */}
              <Typography variant="h2">{totalcount2}</Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExposureStatsCard;