import { addAppListener, AppStartListening } from './listenerMiddleware'
import {
  AuthenticationActions,
  selectMuid,
} from '@meprism/shared/src/redux/authentication/authenticationSlice'
import { AppThunk } from './store'
import { Hub } from 'aws-amplify'
import { setUpProductAuthListener } from '@meprism/shared/src/redux/product/ProductSlice'

export const setUpOauthListener =
  (): AppThunk<Promise<string>> => (dispatch) => {
    let destination = '/'
    const unsubscribeFromHub = Hub.listen(
      'auth',
      ({ payload: { event, data } }) => {
        if (event === 'customOAuthState' && data) {
          destination = data
        }
      },
    )
    return new Promise((resolve, reject) => {
      dispatch(
        addAppListener({
          actionCreator: AuthenticationActions.addBlockingRequest,
          effect: async (action, listenerApi) => {
            if (action.payload !== 'OAUTH') {
              return
            }
            try {
              const wasSuccessful = await listenerApi.condition(
                (_, state) => !!selectMuid(state),
                3000,
              )
              if (wasSuccessful) {
                resolve(destination)
              } else {
                reject('/login')
              }
            } catch (error) {
              reject('/login')
            }
            listenerApi.unsubscribe()
            unsubscribeFromHub()
            listenerApi.dispatch(
              AuthenticationActions.removeBlockingRequest('OAUTH'),
            )
          },
        }),
      )

      // and now that we're listening, we can trigger it ourselves
      dispatch(AuthenticationActions.addBlockingRequest('OAUTH'))
    })
  }

export const setUpExtraListeners = (listen: AppStartListening) => {
  setUpProductAuthListener(listen)
}
