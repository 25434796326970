import React, { useState } from 'react'
// import Eye from '@mui/icons-material/Visibility'
import Eye from '@meprism/shared/src/assets/icons/showPassword.svg'
import SlashEye from '@meprism/shared/src/assets/icons/Hide.svg'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '../MaterialExports'

const defaultProps = {
  color: 'primary',
  autoComplete: 'current-password',
} as const

export type PasswordFieldProps = TextFieldProps

const PasswordField = (props: PasswordFieldProps) => {
  const [shouldShowPassword, setShouldShowPassword] = useState<boolean>(false)

  const mergedProps = {
    ...defaultProps,
    ...props,
  }

  return (
    <TextField
      type={shouldShowPassword ? 'text' : 'password'}
      InputProps={{
        placeholder:props.placeholder,
        endAdornment: (
          <InputAdornment position={'end'}>
            <IconButton
              aria-label="Toggle Password Visibility"
              onClick={() => setShouldShowPassword(!shouldShowPassword)}
              edge="end">
              {shouldShowPassword ? <img src={SlashEye} alt="Logo" style={{ width: 24, height: 24 }} /> :    <img src={Eye} alt="Logo" style={{ width: 24, height: 24 }} />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...mergedProps}
    />
  )
}

export default PasswordField
