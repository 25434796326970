import React from 'react'
import { Box, Button, Typography } from '../MaterialExports'
import MpDividerLine from '../atom/MpDividerLine'
import MpInfoModal from './MpInfoModal'
import { useAppSelector } from '../../../redux/storeExports'
import { PeopleFinderSelectors } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import { MpColorPalette } from '../../../theme/theme'
import { Link } from 'react-router-dom'
import Paper from '@mui/material/Paper/Paper'

const MpScanCard = () => {
  const stats = useAppSelector(PeopleFinderSelectors.selectExposureStats)

  return (
    <Paper sx={{ borderRadius: '24px' }}>
      <Box sx={{ paddingLeft: '64px', paddingTop: '32px' }}>
        <Typography
          variant={'title'}
          sx={{ color: MpColorPalette.purple[500] }}>
          {stats.sites_in_found}
        </Typography>
        <Typography
          variant={'body1'}
          sx={{ fontWeight: 600, marginBottom: '20px' }}>
          Websites selling your personal information
        </Typography>
        <Box
          sx={{ flexDirection: 'row', display: 'flex', marginBottom: '20px' }}>
          <Box>
            <Typography variant={'h1'}>{stats.sites_in_removed}</Typography>
            <Typography variant={'body1'}>Removed</Typography>
          </Box>
          <Box sx={{ marginLeft: 5 }}>
            <Typography variant={'h1'}>{stats.sites_in_progress}</Typography>
            <Typography variant={'body1'}>Removing</Typography>
          </Box>
        </Box>
        <MpInfoModal
          title={'What does mePrism Delete and How Long Will it Take'}
          label={'What we remove & how long it takes'}>
          <Typography variant="body2" sx={{ color: '#3A155A' }}>
            meprism removes your data from dozens of websites that can show up
            in google results
          </Typography>
          <Typography variant="body2" sx={{ color: '#3A155A' }}>
            We DO NOT:
          </Typography>
          <Typography variant="body2" sx={{ color: '#3A155A' }}>
            <ul>
              <li>
                Remove accounts or passwords for websites that you have signed
                up for
              </li>
              <li>
                Prevent emails from retailers who you have an account with
              </li>
            </ul>
          </Typography>
          <Typography variant="body2" sx={{ color: '#3A155A' }}>
            Remember that you will see results over the first 48hrs after a
            scan. However, some removals may take up to 3 months.
          </Typography>
        </MpInfoModal>
      </Box>
      <MpDividerLine sx={{ marginBottom: '12px' }} />
      <Link to={'/settings'}>
        <Button
          variant="text"
          sx={{ marginBottom: '12px', marginLeft: '64px' }}>
          Improve Results Accuracy
        </Button>
      </Link>
    </Paper>
  )
}

export default MpScanCard
