export enum Environments {
  local = 'local',
  sandbox = 'sandbox',
  development = 'development',
  staging = 'staging',
  production = 'production',
}
export type LogLevels = 'DEBUG' | 'INFO' | 'ERROR'
export const currentEnvironment = (): Environments => {
  if (process.env.REACT_APP_LOCAL) {
    return Environments.local
  } else if ((process.env.REACT_APP_API_FQDN as string).includes('sandbox')) {
    return Environments.sandbox
  } else if (
    (process.env.REACT_APP_API_FQDN as string).includes('development')
  ) {
    return Environments.development
  } else if ((process.env.REACT_APP_API_FQDN as string).includes('staging')) {
    return Environments.staging
  }

  return Environments.production
}

export const logLevel = (loggerLevel: LogLevels): LogLevels => {
  if (currentEnvironment() === Environments.local) {
    return 'DEBUG'
  } else {
    return loggerLevel
  }
}

export const bytesToGbConverter = (size: number): number => {
  return size / (1000 * 1000 * 1000)
}

export const compositeCMSString = (
  cmsString: string,
  replacement: string | string[],
  replacing = '{0}',
): string => {
  try {
    if (Array.isArray(replacement)) {
      let replacedString = cmsString
      replacement.forEach((replace, index) => {
        replacedString = replacedString.replace(`{${index}}`, replace)
      })
      return replacedString
    } else {
      return cmsString.replace(replacing, replacement)
    }
  } catch (error) {
    // fail silently. don't worry it's safe
    return ''
  }
}

export const isObjectEmpty = (obj: any): boolean =>
  obj && Object.keys(obj).length === 0

export const isValidDate = (d: any): boolean => {
  return d instanceof Date && !isNaN(d.getTime())
}

export const formatDate = (date: string): string => {
  const newDate = new Date(date)

  return isValidDate(newDate)
    ? `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`
    : ''
}

// zips 2 arrays in order - if the lengths are different, returns an array of the larger length with missing elements
// returned as undefined
export const zip = <T, U>(a1: T[], a2: U[]): [T?, U?][] => {
  if (a2.length > a1.length) {
    return a2.map((a, i) => [a1[i], a])
  } else {
    return a1.map((a, i) => [a, a2[i]])
  }
}

// from https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html
export const assertNever = (x: never): never => {
  throw new Error('Unexpected object: ' + x)
}
