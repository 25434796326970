import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser'
import { MixpanelAnalyticsEvent } from './AnalyticsEvents'

type PageMap = { [key: string]: string }
const pageMap: PageMap = {
  '/': 'home',
  '/login': 'login',
  '/data-upload': 'manualUpload',
  '/data-upload/status': 'manualUpload.status',
  '/logout': 'logout',
  '/survey': 'survey',
  '/study-consent': 'studyConsent',
  '/study': 'study',
  '/download-the-app': 'downloadTheApp',
}

const autoTrackPageView = () => {
  const page = window.location.pathname
  pageView(pageMap[page] ? pageMap[page] : page)
}
/*
  Send and event name with click wrapper
  event: string
*/
const pageView = (event: string) => {
  event = event + '.view'
  mixpanel.track(event)
}

/*
  Send and event name with click wrapper
  event: string
*/
const click = (event: string, properties?: Dict | undefined) => {
  event = event + '.click'
  mixpanel.track(event, properties)
}

/*
  Send error even with optional properties
*/
const error = (event: string, properties?: unknown) => {
  const fullEvent = `ERROR: ${event}`
  if (properties) {
    mixpanel.track(fullEvent, {
      errorString: JSON.stringify(properties),
    })
  } else {
    mixpanel.track(fullEvent)
  }
}

const track = (
  event_name: string,
  properties?: Dict | undefined,
  optionsOrCallback?: RequestOptions | Callback | undefined,
  callback?: Callback | undefined,
): void => {
  mixpanel.track(event_name, properties, optionsOrCallback, callback)
}
const reset = (): void => {
  mixpanel.reset()
}

const trackTypedEvent = async (typedEvent: MixpanelAnalyticsEvent) => {
  const { event, ...rest } = typedEvent
  return mixpanel.track(event, rest)
}

const timeTypedEvent = async (event: MixpanelAnalyticsEvent['event']) => {
  return mixpanel.time_event(event)
}

const getProperty = async (property_name: string) =>
  mixpanel.get_property(property_name)

const register = async (properties: Record<string, any>) =>
  mixpanel.register(properties)

const identify = async (uniqueId?: string) => mixpanel.identify(uniqueId)

// Do not ask me why this exists -
// we just LOVE re-exporting other people's code
// or providing wrappers that don't do anything
export const AnalyticsService = {
  click,
  error,
  pageView,
  autoTrackPageView,
  track,
  reset,
  trackTypedEvent,
  timeTypedEvent,
  getProperty,
  register,
  identify,
}
