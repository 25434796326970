const isoDatePattern =
  /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})(\.\d+)?([+-]\d{2}:?\d{2}|Z)$/

const getTzOffsetFromString = (input: string) => {
  const groups = input.match(isoDatePattern)
  return groups ? groups?.[4] : undefined
}

const hasTzOffset = (input: string) => {
  return !!getTzOffsetFromString(input)
}

export const dateDefaultUtc = (input: string): string => {
  if (input && !hasTzOffset(input)) {
    // if there's no offset, we can just append a Z to identify utc
    return input + 'Z'
  }
  return input
}

export const timeAgo = ({
  years = 0,
  months = 0,
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
}: {
  years?: number
  months?: number
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}) => {
  const d = new Date()
  d.setFullYear(d.getFullYear() - years)
  d.setMonth(d.getMonth() - months)
  d.setHours(d.getHours() - days * 24 - hours)
  d.setMinutes(d.getMinutes() - minutes)
  d.setSeconds(d.getSeconds() - seconds)
  return d
}

const timeIntervals = [
  { label: 'y', seconds: 31536000 },
  { label: 'mo', seconds: 2592000 },
  { label: 'd', seconds: 86400 },
  { label: 'h', seconds: 3600 },
  { label: 'm', seconds: 60 },
  { label: 's', seconds: 1 },
] as const

const longLabels = {
  y: 'year',
  mo: 'month',
  d: 'day',
  h: 'hour',
  m: 'minute',
  s: 'second',
} as const

export const prettyPrintTimeSince = (date: Date) => {
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000)
  const interval = timeIntervals.find((i) => i.seconds < seconds)
  if (!interval) {
    return 'Just now'
  }
  const count = Math.floor(seconds / interval.seconds)
  return `${count}${interval.label} ago`
}

type IntervalConfig = {
  intervalType?: 'short' | 'long'
  separator?: string
  ifNotFound?: string
  roundThreshold?: number
}
export const prettyPrintTimeInterval = (
  d1: Date,
  d2: Date,
  {
    intervalType = 'short',
    separator = ' ',
    ifNotFound = 'Just now',
    roundThreshold = 1,
  }: IntervalConfig,
) => {
  const seconds = Math.abs(Math.floor((d1.getTime() - d2.getTime()) / 1000))
  const interval = timeIntervals.find(
    (i) => i.seconds * roundThreshold < seconds,
  )
  if (!interval) {
    return ifNotFound
  }
  const count = Math.floor(seconds / interval.seconds)
  const label =
    intervalType === 'short' ? interval.label : longLabels[interval.label]
  return `${count}${separator}${label}`
}
