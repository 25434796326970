import React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'

export const BrokerSkeleton = () => {
  return (
    <Box
      sx={{
        borderRadius: '20px',
        border: '1px solid #EFEFEF',
        p: 5,
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          width: '100%',
        }}>
        <Skeleton variant={'circular'} height={20} width={20} sx={{ mr: 5 }} />
        <Typography variant={'body1'} sx={{ flex: 1 }}>
          <Skeleton />
        </Typography>
      </Box>
      <Typography variant={'body2'}>
        <Skeleton />
      </Typography>
    </Box>
  )
}
