import { Box, Modal, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import { fonts } from '../../../theme/theme'
import MpDividerLine from '../atom/MpDividerLine'
import MpSvgIcon from '../atom/MpSvgIcon'
import { Button } from '../MaterialExports'

interface MpInfoModalProps {
  label: string
  title: string
  children: ReactNode
}

export const defaultModalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //maxWidth: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  paddingLeft: '50px',
  paddingRight: '50px',
  paddingTop: '30px',
  paddingBottom: '30px',
  overflowY: 'scroll',
  '@media(max-width: 500px)': {
    top: '0',
    transform: 'translate(-50%, 0%)',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '30px',
    minWidth: '80%',
    maxHeight: '90%',
  },
}

const MpInfoModal = (props: MpInfoModalProps) => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          color: '#3A155A',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <MpSvgIcon icon={'info'} />
        <Typography variant={'label'} sx={{ marginLeft: '14px' }}>
          {props.label}
        </Typography>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          backdropFilter: 'blur(5px)',
        }}>
        <Box sx={defaultModalStyle}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography
              variant={'h1'}
              sx={{ font: fonts.Jakarta.Bold, color: '#3A155A' }}>
              {props.title}
            </Typography>
            <Button variant={'text'} onClick={handleClose}>
              <MpSvgIcon icon={'close'} />
            </Button>
          </Box>
          <MpDividerLine />
          {props.children}
        </Box>
      </Modal>
    </>
  )
}

export default MpInfoModal
