import React from 'react'
import { Box } from '../MaterialExports'

interface MpDividerLineProps {
  sx?: any
}

const MpDividerLine = (props: MpDividerLineProps) => {
  return (
    <Box
      sx={{
        border: '1px solid #EDEDED',
        marginTop: '16px',
        marginBottom: '40px',
        ...props.sx,
      }}
    />
  )
}

export default MpDividerLine
