import { Box, Button, Modal, TextField, Typography } from '@mui/material'
import React, { ReactNode } from 'react'
import { defaultModalStyle } from './MpInfoModal'

interface ConsentAgreementModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  name: string
  setName: (name: string) => void
  textInputProps: any
  extra?: ReactNode
  onSubmit?: () => void
}

// Important: if you change this file, you must also increment the
// version in shared/redux/ProfileSlice
// so that we know how to differentiate user agreements

const ConsentAgreementModal = (props: ConsentAgreementModalProps) => {
  const handleClose = () => {
    if (props.onSubmit && props.name !== '') {
      props.onSubmit()
    }
    if (props.name !== '') {
      props.setOpen(false)
    }
  }
  return (
    <Modal open={props.open} onClose={handleClose}>
      <Box sx={defaultModalStyle}>
        <Typography variant="h2" sx={{ textAlign: 'center', mb: '20px' }}>
          AUTHORIZATION LETTER
        </Typography>
        {props.extra && props.extra}
        <Typography variant="body2" sx={{ mt: props.extra ? '12px' : '0px' }}>
          I, {props.name === '' ? 'YOUR NAME' : props.name}, hereby authorize
          mePrism Inc. (“mePrism”) to act as my authorized agent under the
          California Consumer Privacy Act (CCPA) and all other current and
          future privacy and data security laws. This authorization grants
          mePrism the power to take any necessary and reasonable actions to
          protect my privacy rights, including but not limited to removing,
          suppressing, or opting-out my personal information from any unwanted
          sources.
        </Typography>
        <Typography variant="body2" sx={{ mt: '12px' }}>
          mePrism is authorized to contact third parties, such as data brokers,
          people search companies, and data aggregators, to exercise my rights
          to delete and opt-out of the sale and disclosure of my personal
          information. I acknowledge that mePrism will not be held liable for
          any actions taken under this authorization, provided that they act
          reasonably.
        </Typography>
        <Typography variant="body2" sx={{ mt: '12px' }}>
          This authorization will remain in full force and effect until I cancel
          my service with mePrism.
        </Typography>
        <Box
          sx={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'flex-end',
            mt: '20px',
          }}>
          <Typography variant={'body2'}>Signed: </Typography>
          <TextField
            value={props.name}
            onChange={(event) => props.setName(event.target.value)}
            sx={{ ml: '10px', mb: '0px', pb: '0px' }}
            {...props.textInputProps}
          />

          <Typography sx={{ ml: '10px', fontFamily: 'cursive' }}>
            {props.name}
          </Typography>
        </Box>
        <Box
          sx={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            mt: '10px',
          }}>
          <Typography variant={'body2'}>Dated: </Typography>
          <Typography variant={'body2'} sx={{ ml: '10px' }}>
            {new Date().toLocaleDateString()}{' '}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 'auto',
            mt: '20px',
          }}>
          <Button
            onClick={handleClose}
            variant={'contained'}
            disabled={props.name === ''}>
            Save and adopt as signature
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default ConsentAgreementModal
