import TextField from '@mui/material/TextField'
import React, { PropsWithChildren, Suspense, useState } from 'react'
import PasswordField from '../shared/atom/PasswordField'
import { signInWithEmail } from '../../services/AuthenticationService'
import {
  Await,
  defer,
  Link,
  LoaderFunction,
  Navigate,
  useAsyncValue,
  useLoaderData,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { AnalyticsService } from '../../services/AnalyticsService'
import { AuthLoader } from '../gateways/AuthLoader'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack/Stack'
import MpTheme from '@meprism/shared/src/config/MpTheme'
import { CognitoUser, Auth } from '@aws-amplify/auth'
import {
  GlobalAuthContextRef,
  useAuthContext,
} from '@meprism/shared/src/context/AuthContext'
import { LoadingFallback } from '../shared/atom/LoadingFallback'
import { Logger } from '@meprism/app-utils'
import { MpReTheme, fonts, theme } from '../../theme/OnboardingTheme'
import { ThemeProvider, useMediaQuery } from '@mui/material'

export const loginWithEmailLoader: LoaderFunction = async ({ request }) => {
  const searchParams = new URL(request.url).searchParams
  const token = searchParams.get('token')
  if (token) {
    try {
      const jsonString = atob(token)
      const payload = JSON.parse(jsonString)
      const email = payload?.email
      const password = payload.password
      const user: CognitoUser = await Auth.signIn(email, password)
      GlobalAuthContextRef.current?.setUser(user)
      return defer({
        user,
      })
    } catch (error) {
      Logger.error(`Error attempting autosignin: ${error}`)
    }
  }
  return defer({ user: Promise.resolve(null) })
}

const LoginWithEmailRedirector = (props: PropsWithChildren) => {
  const user = useAsyncValue() as CognitoUser | null
  const authContext = useAuthContext()

  if (user) {
    authContext.setUser(user)
    return (
      <Navigate
        to={
          user?.challengeName === 'NEW_PASSWORD_REQUIRED'
            ? '/completePassword'
            : '/'
        }
      />
    )
  }
  return <>{props.children}</>
}

const LoginWithEmailWrapper = () => {
  const data = useLoaderData() as any

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Await resolve={data.user}>
        <LoginWithEmailRedirector>
          <LoginWithEmailInner />
        </LoginWithEmailRedirector>
      </Await>
    </Suspense>
  )
}

export const LoginWithEmailInner = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const textStyle = {
    ...fonts.Inter.Regular,
    fontSize: '14px',
  }
  const location = useLocation()
  const from = (location?.state as any)?.from || '/'
  const [queryParams] = useSearchParams()
  const [email, setEmail] = useState<string>(queryParams.get('email') ?? '')
  const [password, setPassword] = useState<string>(
    queryParams.get('password') ?? '',
  )
  const [errorText, setErrorText] = useState<string>('')
  const navigate = useNavigate()

  const onLogin = async () => {
    try {
      const user: CognitoUser = await signInWithEmail(email, password)
      GlobalAuthContextRef.current?.setUser(user)

      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        navigate('/completePassword', { replace: true })
      }
      if (user?.challengeName === 'SOFTWARE_TOKEN_MFA') {
        //authContext.setUser(user)

        navigate('/otp', {
          state: {
            actionType: 'SIGNIN_SOFTWARE_TOKEN_MFA',
            username: email,
            password,
            from,
            destination: 'your authenticator app',
          },
        })
        return
      } else {
        navigate(from)
      }
    } catch (error) {
      if (error instanceof Error) {
        switch (error?.name) {
          case 'UserNotFoundException':
          case 'NotAuthorizedException':
            setErrorText('The email or password entered is incorrect.')
            break
          case 'UserNotConfirmedException':
            setErrorText(
              'Your email address has not yet been verified. Check your email for a verification code.',
            )
            navigate('/otp', {
              state: {
                actionType: 'SIGNUP_EMAIL',
                username: email,
                password,
                from,
              },
            })
            break
          case 'LimitExceededException':
            setErrorText(
              `Unfortunately, you have exceeded a limit on the number of failed login attempts. Please wait 15 minutes and try again.`,
            )
            AnalyticsService.error('CognitoLimit', error)
            break
          default:
            AnalyticsService.error(`Unhandled Authorization error: `, error)
            setErrorText(
              'An error occurred processing your login request. Please try again later.',
            )
            break
        }
      } else {
        AnalyticsService.error(`Unhandled Authorization error: `, error)
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthLoader>
        <Stack
          sx={{
            maxWidth: MpTheme.layouts.widths.sm,
            mx: 'auto',
            width: '100%',
          }}
          spacing={5}>
          <TextField
            fullWidth
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            color={'primary'}
            variant={'outlined'}
            placeholder="Email"
            autoComplete="email"
            InputLabelProps={{ shrink: true }}
          />
          <PasswordField
            placeholder="Password"
            fullWidth
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            variant={'outlined'}
            InputLabelProps={{ shrink: true }}
          />
          <Box>
            <Typography color={'error'}>{errorText}</Typography>
          </Box>
          <Link
            to={'/resetPassword'}
            state={{ from }}
            style={{
              textAlign: 'center',
              ...textStyle,
              textDecoration: 'underline',
              color: '#6F8EF5',
            }}>
            Forgot Password?
          </Link>
          <Box>
            <Button
              variant={'contained'}
              fullWidth
              disabled={email == '' || password == ''}
              onClick={onLogin}>
              Sign In
            </Button>
          </Box>
          {!isMobile && (
            <Box
              sx={{
                maxWidth: MpTheme.layouts.widths.sm,
                width: '100%',
                position: 'absolute',
                bottom: !isMobile ? '30px' : '80px',
              }}>
              <Stack
                direction={'row'}
                sx={{ alignItems: 'center', mt: 15 }}
                spacing={!isMobile ? 15 : 10}
                justifyContent={'space-between'}>
                <Link
                  to={'https://meprism.com/terms-conditions'}
                  style={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Terms & Conditions
                </Link>
                <Link
                  to={'https://meprism.com/privacy-policy'}
                  style={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Privacy Policy
                </Link>
                <Link
                  to={
                    'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17'
                  }
                  style={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Contact Us
                </Link>
              </Stack>
            </Box>
          )}
        </Stack>
      </AuthLoader>
    </ThemeProvider>
  )
}

export default LoginWithEmailWrapper
