import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React from 'react'
import { useAppSelector } from '../../../redux/storeExports'
import {
  selectAccountEmail,
  selectMuid,
} from '@meprism/shared/src/redux/authentication/authenticationSlice'
import ResetPassword from '../../Authentication/ResetPassword'
import { theme } from '../../../theme/DashboardTheme'
import { MfaCard } from '../../Authentication/MfaCard'

const styles = {
  card: { borderRadius: 3, background: '#f6f6f6' },
  fontBold: { fontFamily: 'InterSemiBold', color: 'black' },
  fontGray: {
    color: '#767676',
  },
}

const AccSecurity = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const accountEmail = useAppSelector(selectAccountEmail)
  const muid = useAppSelector(selectMuid)
  const mainComponent = () => {
    return (
      <Grid container columnSpacing={5} rowSpacing={5}>
        {!isMobile && (
          <Grid item xs={12} md={12}>
            <Typography variant="h3">Account & Security</Typography>
          </Grid>
        )}
        <Grid item xs={12} md={5}>
          <Card sx={styles.card}>
            <CardContent>
              <Typography variant="subtitle2" sx={styles.fontBold}>
                Sign in Email
              </Typography>
              <Box sx={{ paddingBlockStart: '0.5rem' }}>
                <Typography variant="caption" sx={styles.fontGray}>
                  {accountEmail}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={7}>
          <Card sx={styles.card}>
            <CardContent>
              <Grid container columnSpacing={5}>
                <Grid item md={10}>
                  <Typography variant="subtitle2" sx={styles.fontBold}>
                    mePrism ID
                  </Typography>
                  <Box sx={{ paddingBlockStart: '0.5rem' }}>
                    <Typography variant="caption" sx={styles.fontGray}>
                      {muid}{' '}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={2} sx={{ textAlign: 'right' }}>
                  <Typography
                    variant="caption"
                    onClick={() =>
                      navigator.clipboard.writeText(muid as string)
                    }
                    sx={{
                      cursor: 'pointer',
                      color: '#c67eff',
                    }}>
                    Copy
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <ResetPassword />
        <Grid item md={12} xs={12}>
          <Divider />
        </Grid>

        <MfaCard />
      </Grid>
    )
  }
  return (
    <>
      {isMobile ? (
        mainComponent()
      ) : (
        <Card sx={{ borderRadius: 5, height: '90vh' }}>
          <CardContent>{mainComponent()}</CardContent>
        </Card>
      )}
    </>
  )
}

export default AccSecurity
