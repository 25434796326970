import {
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React from 'react'
import closeicon from '@meprism/shared/src/assets/icons/successclose.png'
import tickmark from '@meprism/shared/src/assets/icons/check-close.svg'
import { theme } from '../../../theme/DashboardTheme'

interface ErrorSubmitModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const ErrorSubmitModal = (props: ErrorSubmitModalProps) => {
  const handleClose = () => {
    props.setOpen(false)
  }
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Modal
      open={props.open}
      sx={{
        backdropFilter: 'blur(5px)',
        background: 'rgba(0, 0, 0, 0.01)',
      }}
      onClose={handleClose}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          outline: 'none',
          position: 'absolute',
          top: '50%',
          left: '50%',
          maxWidth: '600px',
          transform: 'translate(-50%, -50%)',
          width: !isMobile ? '30%' : '80%',
          background: '#fff',
          p: 10,
          borderRadius: '10px',
        }}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: -60,
            right: 10,
            zIndex: 1,
            color: '#ffffff',
          }}>
          <img src={closeicon} style={{ width: '42px' }} />
        </IconButton>
        <Stack
          justifyContent="center"
          alignItems="center"
          flexDirection={'column'}>
          <img src={tickmark} style={{ width: '100px' }} />
          <Typography variant="h2" mb={2} mt={10} textAlign="center">
            Oh, No.....
          </Typography>

          <Typography variant="body2" textAlign="center" color={'#615F6B'}>
            We couldn&apos;t apply your changes.
          </Typography>
        </Stack>

        <Grid item xs={6} sm={6} mt={5}>
          <Button
            variant={'contained'}
            color={'darkPurple'}
            onClick={handleClose}
            sx={{
              width: '100%',
              borderRadius: '10px',
              boxShadow: 'unset',
              border: '2px solid #C67EFF',
              background: 'transparent',
              color: '#C67EFF',
              '&.Mui-disabled': {
                background: 'transparent',
                color: '#C67EFF',
              },
              ':hover': {
                background: 'transparent',
                color: '#C67EFF',
              },
            }}>
            Try Again
          </Button>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ErrorSubmitModal
