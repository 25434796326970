import { states } from '../utils/helpers'
import { timeAgo } from '../utils/dateHelpers'
import { z } from 'zod'
import {
  CURRENT_AUTH_LETTER_VERSION,
  ProfileFields,
} from '../redux/profile/ProfileSlice'
import { phoneNumberSchema } from './util'

export const addressSchema = z.object({
  city: z
    .string()
    .nonempty('City is required')
    .max(100, 'City cannot be longer than 100 characters'),
  state: z.enum(states, {
    errorMap: () => ({
      message: 'Must be a valid 2 letter state code',
    }),
  }),
  streetAddress: z.string()
      .max(100, 'City cannot be longer than 100 characters')
  // zip: z.string()
  //     .max(10, 'zipcode cannot be longer than 100 characters'),
})

export const registerSchema = z.object({
  firstName: z
    .string()
    .nonempty('First name is required')
    .max(50, 'Name cannot be more than 50 characters')
      .regex(/^[a-zA-Z-]+$/, 'First name can only contain letters and hyphens (-)'),
  middleName: z
    .string()
    .max(50, 'Name cannot be more than 50 characters')
      .regex(/^[a-zA-Z-]+$/, 'Middle name can only contain letters and hyphens (-)')
      .optional(),
  lastName: z
    .string()
    .nonempty('Last name is required')
    .max(50, 'Name cannot be more than 50 characters')
      .regex(/^[a-zA-Z-]+$/, 'Last name can only contain letters and hyphens (-)'),
  city: z
    .string()
    .nonempty('City is required')
    .max(100, 'City cannot be longer than 100 characters'),
  state: z.enum(states, {
    errorMap: () => ({
      message: 'Must be a valid 2 letter state code',
    }),
  }),
  streetAddress: z.string(),
  email: z.string().email().or(z.literal('')),
  phoneNumber: phoneNumberSchema,
  birthdate: z.coerce
    .date()
    .max(
      timeAgo({ years: 18 }),
      'You must be at least 18 years old to use mePrism',
    )
    .min(
      timeAgo({ years: 100 }),
      'You must be under 100 years old to use mePrism',
    ),
})
// .superRefine(({ noMiddleName, middleName }, ctx) => {
//   if (!middleName && !noMiddleName) {
//     ctx.addIssue({
//       path: ['middleName'],
//       code: 'custom',
//       message:
//         'You must provide a middle name/initial, or indicate you do not have one.',
//     })
//   }
// })

// React Hook Form only permits Object types when used in dynamic forms
// hence we represent all our names as objects
export const profileSchema = z.object({
  firstName: z
    .object({
      value: z
        .string()
        .nonempty('First name is required')
        .max(50, 'Name cannot be more than 50 characters')
          .regex(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, 'First name can only contain letters and hyphens (-)'),
    })
    .array(),
  middleName: z
    .object({
      value: z
        .string()
        .max(50, 'Name cannot be more than 50 characters')
          .regex(/^(?:[a-zA-Z]+(?:[\s-][a-zA-Z]+)*)?$/, 'Middle name can only contain letters and hyphens (-)')
          .optional(),
    })
    .array(),
  lastName: z
    .object({
      value: z.string().max(50, 'Name cannot be more than 50 characters')
      .nonempty('Last name is required')
          .regex(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, 'Last name can only contain letters and hyphens (-)'),
    })
    .array(),
  phoneNumber: z
    .object({
      value: phoneNumberSchema,
    })
    .array(),
  email: z.object({
    value: z.string().email().max(100).or(z.literal('')),
  }).array(),
  birthdate: z.coerce
    .date()
    .max(
      timeAgo({ years: 18 }),
      'You must be at least 18 years old to use mePrism',
    )
    .min(
      timeAgo({ years: 100 }),
      'You must be under 100 years old to use mePrism',
    ),
  address: addressSchema.array(),
  mp_auth_letter_signature: z.string(),
  gender: z.string(),
  auth_letter_version: z.string(),
})

export const asProfilePost = (
  schema: z.TypeOf<typeof profileSchema>,
): ProfileFields => ({
  names: {
    first: schema.firstName.map(({ value }) => value).filter(Boolean),
    middle: schema.middleName.map(({ value }) => value),
    last: schema.lastName.map(({ value }) => value).filter(Boolean),
  },
  emails: schema.email
    .map(({ value }) => value)
    .filter(Boolean)
    .map((address) => ({ address, validated: false })),
  birthdate: schema.birthdate.toISOString(),
  phones: schema.phoneNumber
    .map(({ value }) => value)
    .filter(Boolean)
    .map((number) => ({ validated: false, number })),
  address: schema.address.map(({ streetAddress, ...rest }) => ({
    street1: streetAddress,
    country: 'US',
    ...rest,
  })),
  gender: schema.gender,
  mp_auth_letter_signature: schema.mp_auth_letter_signature,
  auth_letter_version: schema.auth_letter_version,
})

export const fromProfileFields = (
  fields: ProfileFields,
): z.TypeOf<typeof profileSchema> => ({
  firstName:
    fields.names.first.length > 0
      ? fields.names.first.map((value) => ({ value }))
      : [{ value: '' }],
  lastName:
    fields.names.last.length > 0
      ? fields.names.last.map((value) => ({ value }))
      : [{ value: '' }],
  middleName:
    fields.names.middle.length > 0
      ? fields.names.middle.map((value: any) => ({ value }))
      : [{ value: '' }],
  email: fields.emails.map(({ address }) => ({ value: address })),
  phoneNumber: fields.phones.map(({ number }) => ({
    // need to strip off country code for display
    value: number.replace(/^\+1/, ''),
  })),
  birthdate: new Date(fields.birthdate),
  // below is mad because it thinks we can't convert a string to state
  // @TODO fix the type ignore
  // @ts-ignore
  address:
    fields.address.length > 0
      ? fields.address.map(({ city, state, street1: streetAddress }) => ({
          city,
          state,
          streetAddress: streetAddress ?? '',
        }))
      : [{ city: '', state: '', streetAddress: '' }],
  mp_auth_letter_signature: fields.mp_auth_letter_signature || '',
  gender: fields.gender || '',
  auth_letter_version:
    fields.auth_letter_version || CURRENT_AUTH_LETTER_VERSION,
})

export const emptyProfileSchema = (): z.TypeOf<typeof profileSchema> => ({
  firstName: [{ value: '' }],
  middleName: [{ value: '' }],
  lastName: [{ value: '' }],
  address: [
    {
      city: '',
      state: '' as 'CA',
      streetAddress: '',
      // zip:'',
    },
  ],
  email: [{ value: '' }],
  phoneNumber: [{ value: '' }],
  birthdate: new Date(),
  mp_auth_letter_signature: '',
  gender: '',
  auth_letter_version: CURRENT_AUTH_LETTER_VERSION,
})
