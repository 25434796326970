import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useMediaQuery, Grid, Box, Button } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import MpSvgIcon from './MpSvgIcon'
import { theme } from '../../../theme/DashboardTheme'
import { Grant } from '@meprism/shared/src/redux/product/ProductTypes'
import { useNavigate } from 'react-router-dom'

interface PieChartCardProps {
  title: string
  removed: number
  removing: number
  color1: string
  color2: string
  icon1: string
  icon2: string
  bgcolor: string
  title1: string
  title2: string
  totalcount1: number
  totalcount2: number
  grant: Grant | undefined
}
interface PieChartProps {
  data: { value: number; label: string; color: string }[]
}

const PieChart: React.FC<PieChartProps> = ({ data }) => {
  const total = data.reduce((acc, { value }) => acc + value, 0)

  let startAngle = 0
  const isFirstValueZero = data[0]?.value === 0
  const isSecondValueZero = data[1]?.value === 0

  return (
    <svg width="200" height="200" viewBox="0 0 200 200">
      {isFirstValueZero || isSecondValueZero ? (
        // If either the first or second value is zero, fill the entire circle with the color of the non-zero category
        <circle
          cx="100"
          cy="100"
          r="90"
          fill={isFirstValueZero ? data[1]?.color : data[0]?.color}
        />
      ) : (
        // Render pie chart slices
        data.map(({ value, color }, index) => {
          const percentage = (value / total) * 100
          const endAngle = startAngle + (percentage * 360) / 100

          const largeArcFlag = percentage >= 50 ? 1 : 0

          const startX = 100 + Math.cos((startAngle * Math.PI) / 180) * 90
          const startY = 100 + Math.sin((startAngle * Math.PI) / 180) * 90

          const endX = 100 + Math.cos((endAngle * Math.PI) / 180) * 90
          const endY = 100 + Math.sin((endAngle * Math.PI) / 180) * 90

          const path = `M ${startX} ${startY} A 90 90 0 ${largeArcFlag} 1 ${endX} ${endY} L 100 100 Z`

          startAngle = endAngle

          return <path key={index} d={path} fill={color} />
        })
      )}
    </svg>
  )
}

const PieChartCard: React.FC<PieChartCardProps> = ({
  title,
  removed,
  removing,
  color2,
  color1,
  icon1,
  bgcolor,
  title1,
  title2,
  icon2,
  totalcount1,
  totalcount2,
  grant,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()
  const pieChartData = [
    { value: removing, label: 'Category A', color: color1 },
    { value: removed, label: 'Category B', color: color2 },
  ]
  return (
    <Card
      sx={{ borderRadius: 5, background: grant ? 'inherit' : 'transparent' }}>
      <CardContent>
        <Grid container spacing={3} display={'flex'}  alignItems="center" justifyContent="space-between">
          <Grid item md={6}>
            <Typography variant="h3" sx={{ mb: 5 }}>
              {title}
            </Typography>
          </Grid>
          {!grant && (
            <Grid item md={6} sx={{ textAlign: 'right' }}>
              <MpSvgIcon icon="freeuserlock" />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={isMobile ? 3 : 0} alignItems="center">
          {/* Left side with Pie Chart */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box>
              <PieChart data={pieChartData} />
            </Box>
          </Grid>

          {/* Right side with text */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              alignItems="center"
              mb={2}
              justifyContent="space-between"
              p={2}
              bgcolor={bgcolor}
              borderRadius={2}>
              <Box display="flex" alignItems="center">
                <Box
                  p={2}
                  bgcolor={color1}
                  borderRadius={2}
                  alignItems="center"
                  mr={1}>
                  <img
                    src={icon1}
                    alt="icon1"
                    width={18}
                    height={18}
                    style={{ display: 'block', margin: 'auto' }}
                  />
                </Box>
                <Typography variant="h4" ml={2}>
                  {title1}
                </Typography>
              </Box>
              {/* Corner number */}
              <Typography variant="h2">{totalcount1}</Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={2}
              bgcolor={bgcolor}
              borderRadius={2}>
              <Box display="flex" alignItems="center">
                {/* Icon */}
                <Box
                  p={2}
                  bgcolor={color2}
                  borderRadius={2}
                  alignItems="center"
                  mr={1}>
                  <img
                    src={icon2}
                    alt="icon1"
                    width={18}
                    height={18}
                    style={{ display: 'block', margin: 'auto' }}
                  />
                </Box>

                {/* Text */}
                <Typography variant="h4" ml={2}>
                  {title2}
                </Typography>
              </Box>
              {/* Corner number */}
              <Typography variant="h2">{totalcount2}</Typography>
            </Box>
            {!grant && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}>
                <Button
                  endIcon={<LockOutlinedIcon />}
                  onClick={() => navigate('/subscription')}>
                  Subscribe to remove
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PieChartCard
