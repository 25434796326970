import React, { useState } from 'react'
import {useLocation } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  Box,
  BoxProps,
  Container,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material'
import fast from '@meprism/shared/src/assets/icons/medal.svg'
import Link from '@mui/material/Link'
import Realtime from '@meprism/shared/src/assets/icons/star.svg'
import logo from '@meprism/shared/src/assets/icons/logos/mePrismprivacynew.svg'
import background from '../../assets/images/IndividualCreateBackground.png'
import { ThemeProvider } from '@emotion/react'
import { MpReTheme, fonts, theme } from '../../theme/OnboardingTheme'
import icon4 from '@meprism/shared/src/assets/icons/lucide_scan-text.svg'
import icon5 from '@meprism/shared/src/assets/icons/no.svg'
import ConsentAgreement from './ConsentAgreement'

export type SignInTab = 'create' | 'sign in'
function Item(props: BoxProps) {
  const { ...other } = props
  return <Box {...other} />
}
export const AuthCopy = ({  }: { mode: SignInTab }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <>
      <ThemeProvider theme={theme}>
        {!isMobile &&( <Box sx={{ px: 10, pt: 10 }}>
          <img src={logo} alt="Logo" style={{ width: 200, height: 50 }} />{' '}
        </Box>)}
       

        <Stack
          sx={{
            px: 10,
            pt: 10,
            pb: 20,
            '& .MuiTypography-root': {
              color: '#FFFFFF',
            },
            justifyContent: 'flex-end',
          }}>
          <Typography
            variant={'h1'}
            sx={{
              my: 2,
              fontWeight: 'bold',
              maxWidth: !isMobile ? '600px' : 'unset',
            }}>
          Quick guide to your authorization letter
          </Typography>
          <Box
            sx={{
              display: 'flex',
              columnGap: 1,
              rowGap: 1,
              alignItems: 'center',
              gridTemplateColumns: 'auto, 5fr',
              py:5
            }}>
            <Item>
              <img
                src={fast}
                alt="Logo"
                style={{ marginRight: 8, width: 42, height: 42 }}
              />
            </Item>
            <Item>
              {' '}
              <Typography variant="body2">
              <span style={{ color: '#EFEDFDB2' }}>
              Data brokers may require written proof before removing your personal information.</span>  
               
              </Typography>{' '}
            </Item>
          </Box>
          <Box
            sx={{
              display: 'flex',
              columnGap: 1,
              rowGap: 1,
              alignItems: 'center',
              gridTemplateColumns: 'auto, 1fr',
              py:5
            }}>
            <Item>
              <img
                src={Realtime}
                alt="Logo"
                style={{ marginRight: 8, width: 42, height: 42 }}
              />
            </Item>
            <Item sx={{ gridColumn: 'span 5' }}>
              <Typography variant="body2">
              <span style={{ color: '#EFEDFDB2' }}>This letter verifies that mePrism acts on your behalf in such situations.
 </span>
             
              
              </Typography>{' '}
            </Item>
          </Box>
          <Box
            sx={{
              display: 'flex',
              columnGap: 1,
              rowGap: 1,
              alignItems: 'center',
              gridTemplateColumns: 'auto, 1fr',
              py:5
            }}>
            <Item>
              <img
                src={Realtime}
                alt="Logo"
                style={{ marginRight: 8, width: 42, height: 42 }}
              />
            </Item>
            <Item sx={{ gridColumn: 'span 5' }}>
              <Typography variant="body2">
              <span style={{ color: '#EFEDFDB2',marginRight:'5px' }}>This letter is solely for removing your information from the web and carries no other legal implications.
  </span>
 
              </Typography>{' '}
            </Item>
          </Box>
          <Box
        sx={{
          alignItems: 'left',
          marginTop: 4,
          p: 10,
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          border:
            '2px solid var(--text-colors-stroke, rgba(255, 255, 255, 0.15))',
          borderRadius: '20px',
          backdropFilter: 'blur(20.5px)',
        }}>
        <Typography variant="h4">
          Information <span style={{ color: '#C67EFF' }}>Security</span>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
            marginTop: 4,
          }}>
          {/* Item 1 */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
            }}>
            <img
              src={icon4}
              alt="Icon2"
              style={{
                verticalAlign: 'middle',
                marginRight: 8,
                width: 24,
                height: 24,
              }}
            />
            <Typography variant="body3">
            mePrism is SOC 2 compliant with industry-grade cybersecurity standards for handling sensitive data
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginTop:isMobile? 4:'unset'
            }}>
            <img
              src={icon5}
              alt="Icon2"
              style={{
                verticalAlign: 'middle',
                marginRight: 8,
                width: 24,
                height: 24,
                marginLeft: 5,
              }}
            />
            <Typography variant="body3">
            We do not sell your information
            </Typography>
          </Box>
        </Box>
      </Box>
        </Stack>
      </ThemeProvider>
    </>
  )
}

export const AuthorizationAgentWrapper = () => {
  const location = useLocation()
  const textStyle = {
    ...fonts.Inter.Regular,
    fontSize: '14px',
  }
  const mode: SignInTab = location.pathname.includes('create')
    ? 'create'
    : 'sign in'
  const isWide = useMediaQuery(theme.breakpoints.up('sm'))
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} sx={{ background: '#1A1B20' }}>
        <Grid container>
          <Grid item xs={12} md={5}>
            <Box sx={{position:'relative',height:'100%'}}>
          {!isWide ? (<Tabs
              sx={{ mx: 7, pt: 10 ,".MuiTab-root":{ alignItems: "start", },}}
              value={3}
              indicatorColor="secondary"
              textColor="primary"
              centered>
              <Tab
              style={{minWidth:'20%'}}
                label="1."
                sx={{
                  margin:'0 3px',
                  textTransform: 'none',
                  fontFamily: fonts.Inter.SemiBold,
                }}
              />
              <Tab
                label="2."
                style={{minWidth:'20%'}}
                sx={{
                  margin:'0 3px',
                  textTransform: 'none',
                  fontFamily: fonts.Inter.SemiBold,
                }}
              />
              <Tab
                label="3."
                style={{minWidth:'20%'}}
                sx={{
                  margin:'0 3px',
                  textTransform: 'none',
                  fontFamily: fonts.Inter.SemiBold,
                }}
              />
              <Tab
                label="4.Authorization"
                sx={{
                  margin:'0 3px',
                  width:'40%',
                  textTransform: 'none',
                  fontFamily: fonts.Inter.SemiBold,
                  color: 'inherit',
                  '&.Mui-selected': {
                    color: MpReTheme.colors.tertiary.pink.default,
                  },
                }}
              />
            </Tabs>):(<Tabs
              sx={{ px: 5, pt: 10 ,".MuiTab-root":{ alignItems: "start", },}}
              value={3}
              indicatorColor="secondary"
              textColor="primary"
              centered>
              <Tab
                
                label="1.Sign Up"
                sx={{
                  margin:'0 2px',
                  textTransform: 'none',
                  fontFamily: fonts.Inter.SemiBold,
                }}
              />
              <Tab
                label="2.Verify"
                sx={{
                  margin:'0 2px',
                  textTransform: 'none',
                  fontFamily: fonts.Inter.SemiBold,
                }}
              />
              <Tab
                label="3.Set Profile"
                sx={{
                  margin:'0 2px',
                  textTransform: 'none',
                  fontFamily: fonts.Inter.SemiBold,
                }}
              />
              <Tab
                label="4.Authorization"
                sx={{
                  margin:'0 2px',
                  textTransform: 'none',
                  fontFamily: fonts.Inter.SemiBold,
                  color: 'inherit',
                  '&.Mui-selected': {
                    color: MpReTheme.colors.tertiary.pink.default,
                  },
                }}
              />
            </Tabs>)}
            
            <Box sx={{ px: 7,my:20 }}>
              <ConsentAgreement
               open={open}
               setOpen={setOpen}
               name={name}
               setName={setName}
               textInputProps={{ label: '' }}
              />
              <Stack
                direction={'row'}
                sx={{ alignItems: 'center', mt:30, position:'absolute',bottom:'10px' }}
                spacing={!isWide?12:20}
                justifyContent={'center'}>
                <Link
                flexGrow={1}
                  href={'https://meprism.com/terms-conditions'}
                 
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Terms & Conditions
                </Link>
                <Link
                  flexGrow={1}
                  href={'https://meprism.com/privacy-policy'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Privacy Policy
                </Link>
                <Link
                 flexGrow={1}
                  href={'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Contact Us
                </Link>
              </Stack>
            </Box></Box>
          </Grid>
          <Grid
            item
            xs={0}
            md={7}
            sx={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              alignItems: 'flex-end',
            }}>
              <Box sx={{position:'relative',height:'100%'}}>
            <AuthCopy mode={mode} />
            <Typography variant="body4" sx={{ mx: 10, position:'absolute',bottom : !isWide?'30px':'10px'}}>
              Are you a Business?
              <Link
                href={
                  'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17'
                }
                style={{ textDecoration: 'underline', fontSize: '16px',marginLeft:'5px' }}
                sx={{
                  ...textStyle,
                  color: MpReTheme.colors.tertiary.pink.default,
                  ml:3
                }}>
                Contact us
              </Link>
            </Typography></Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}
