import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import React, { useState } from 'react'
import { useAppDispatch } from '../../../app/hooks'
import { useNavigate } from 'react-router-dom'
import { deleteUserAccount } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import { theme } from '../../../theme/DashboardTheme'

const styles = {
  bulletIcon: {
    fontSize: '8px',
    color: 'black',
  },
  delAcc: {
    background: 'white',
    border: '1px solid #c67eff',
    color: '#C67EFF',
    '&:hover': {
      color: 'white !important',
      background: ' #c67eff',
    },
    '&:disabled': {
      color: 'white !important',
      background: ' #e0c4f6',
    },
  },
  listIconWidth: {
    minWidth: '35px',
  },
}

const DeleteAcc = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isOpenDialog, setOpenDialog] = useState(false)

  const handleDeleteAcc = async () => {
    await dispatch(deleteUserAccount())
    navigate('/login')
  }

  const closeDeleteAccDialog = () => {
    setOpenDialog(false)
  }

  const mainComponent = () => {
    return (
      <Grid container columnSpacing={5} rowSpacing={5}>
        {!isMobile && (
          <Grid item xs={12} md={12}>
            <Typography variant="h3">Delete Account</Typography>
          </Grid>
        )}
        <Grid item md={12}>
          <List>
            <ListItem>
              <ListItemIcon sx={styles.listIconWidth}>
                <CircleIcon sx={styles.bulletIcon} />
              </ListItemIcon>
              <ListItemText>
                This will permanently delete your mePrism account and you will
                lose all your opt out progress & profile info.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={styles.listIconWidth}>
                <CircleIcon sx={styles.bulletIcon} />
              </ListItemIcon>
              <ListItemText>
                We will no longer be supporting you to remove your personal
                information from the internet.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={styles.listIconWidth}>
                <CircleIcon sx={styles.bulletIcon} />
              </ListItemIcon>
              <ListItemText>
                The authorization agreement will be deemed terminated.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={styles.listIconWidth}>
                <CircleIcon sx={styles.bulletIcon} />
              </ListItemIcon>
              <ListItemText>This action can not be undone.</ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item md={12}>
          <Button sx={styles.delAcc} onClick={() => setOpenDialog(true)}>
            Delete Account
          </Button>
        </Grid>
        <Dialog
          open={isOpenDialog}
          onClose={closeDeleteAccDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle>
            Are you sure you want to delete your account?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              This action is irreversible. Your account will be deleted
              permanently.
            </DialogContentText>
            <DialogActions>
              <Button
                size="small"
                variant="outlined"
                onClick={closeDeleteAccDialog}>
                Cancel
              </Button>
              <Button size="small" variant="outlined" color="error" onClick={handleDeleteAcc}>
                Delete
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Grid>
    )
  }

  return (
    <>
      {isMobile ? (
        mainComponent()
      ) : (
        <Card sx={{ borderRadius: 5, height: '90vh' }}>
          <CardContent>{mainComponent()}</CardContent>
        </Card>
      )}
    </>
  )
}

export default DeleteAcc
