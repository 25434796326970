import { TaskAbortError } from '@reduxjs/toolkit'
import { Logger } from '@meprism/app-utils'
import { createBaseAsyncThunk } from '@meprism/shared'
import { startAppListening } from '../listenerMiddleware'
import {
  checkForEntitlementUpdates,
  createProductSlice,
  fetchCodeStatus,
  fetchGrants,
  fetchMpProducts,
  fetchOffer,
  purchaseMpSubscription,
  redeemCode,
  selectPurchaseableMpProducts,
} from '@meprism/shared/src/redux/product/ProductSlice'
export * from '@meprism/shared/src/redux/product/ProductSlice'
import Cookies from 'universal-cookie'
import { Toast } from '../../components/toast/toast'

// this poll is handled by listener below
export const pollForEntitlements = createBaseAsyncThunk(
  'pollForEntitlements',
  async (_, { dispatch }) => {
    return setInterval(() => {
      dispatch(checkForEntitlementUpdates())
    }, 1000)
  },
)

export const checkPendingOffers = createBaseAsyncThunk(
  'checkPendingOffers',
  async (_, { dispatch }) => {
    const cookies = new Cookies()
    try {
      const offerId = cookies.get('mpOfferId')
      if ((offerId && typeof offerId === 'string') || true) {
        dispatch(fetchOffer(offerId))
      }
    } catch (error) {
      // nothing to do here
      // user could have ex. denied us cookie permission
    }
  },
)

export const redeemCodeForWeb = createBaseAsyncThunk(
  'redeemCodeForWeb',
  async (userCode: string, { dispatch }) => {
    const res = await dispatch(fetchCodeStatus({ code: userCode })).unwrap()
    if (!res) {
      return
    }
    if (res.is_valid === false) {
      Toast.show({
        type: 'error',
        text1: res.error
          ? res.error
          : "There was a problem with your code. Please make sure it's valid before trying again",
      })
      return
    }
    if (res.code_type === 'corporate') {
      await dispatch(redeemCode({ code: userCode })).unwrap()
      dispatch(fetchGrants())
    } else if (res.code_type === 'stripe') {
      const session = await dispatch(
        purchaseSubscription({
          sku: res.products[0].sku,
          ext_price_id: res.products[0].price_id,
          success_url: window.location.origin + '/subscribe/success',
          cancel_url: window.location.href,
          promo_code: res.code,
        }),
      ).unwrap()
      window.location.href = session.url
    }
  },
)

// this listener handles the poll from above
startAppListening({
  actionCreator: pollForEntitlements.fulfilled,
  effect: async (action, listenerApi) => {
    const interval = action.payload

    try {
      const didFindEntitlements = await listenerApi.condition(
        (_, currentState) =>
          !!currentState?.authentication?.entitlements?.length,
        5000,
      )

      if (didFindEntitlements) {
        listenerApi.extra.Toast.show({
          type: 'success',
          text1: 'Your subscription has been processed. Enjoy the app!',
        })
        // make sure we get updated grants
        listenerApi.dispatch(fetchGrants())
      } else {
        Logger.error(
          `No entitlements after subscription after timeout. This indicates a mismatch between entitlements and purchase results`,
        )
        listenerApi.extra.Toast.show({
          type: 'warning',
          text1: 'We are still processing your subscription',
          text2:
            'This is taking a little longer than normal, but we are on it.',
        })
      }
    } catch (error) {
      if (error instanceof TaskAbortError) {
        // in this case we can do nothing
        Logger.debug('Subscription listener aborted')
      } else {
        Logger.error(`Error in subscription listener: ${error}`)
      }
    }
    clearInterval(interval)
  },
})

const { reducer } = createProductSlice({
  reducers: {},
  additionalReducers: () => {},
})

export const ProductReducer = reducer

export type ProductReducerType = ReturnType<typeof ProductReducer>

// this is to avoid more extensive refactoring...
export const selectPurchaseableProducts = selectPurchaseableMpProducts
export const fetchProducts = fetchMpProducts
export const purchaseSubscription = purchaseMpSubscription
