import React from 'react'
import { MpIconAndText } from '..'
import MpTheme from '@meprism/shared/src/config/MpTheme'

type ValidationHintProps = {
  isValid: boolean
  text: string
}

const ValidationHint = ({ isValid, text }: ValidationHintProps) => {
  const iconType = isValid ? ('successnew' as const) : ('errornew' as const)
  const color = isValid
    ? MpTheme.colors.success.default
    : MpTheme.colors.error.default

  return (
    <MpIconAndText
      icon={iconType}
      text={text}
      textImageSpace={8}
      width={16}
      height={16}
      color={color}
      typographyProps={{ variant: 'caption', sx: { color: 'primary.dark' } }}
      boxProps={{ sx: { my: 1 } }}
    />
  )
}

export default ValidationHint
