import { responsiveFontSizes, ThemeOptions } from '@mui/material/styles'
import { createTheme } from '@mui/material'

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true
    body4: true
    body5: true
    title: true
    subheader: true
    label: true
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties
    body4: React.CSSProperties
    body5: React.CSSProperties
    title: React.CSSProperties
    subheader: React.CSSProperties
    label: React.CSSProperties
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties
    body4?: React.CSSProperties
    body5?: React.CSSProperties
    title?: React.CSSProperties
    subheader?: React.CSSProperties
    label?: React.CSSProperties
  }
  interface Palette {
    purple: Palette['grey']
    pink: Palette['grey']
    cyan: Palette['grey']
    green: Palette['grey']
    white: Palette['primary']
    darkPurple: Palette['primary']
    lightPurple: Palette['primary']
  }
  interface PaletteOptions {
    purple: PaletteOptions['grey']
    pink: PaletteOptions['grey']
    cyan: PaletteOptions['grey']
    green: PaletteOptions['grey']
    darkPurple: PaletteOptions['primary']
    lightPurple: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true
    darkPurple: true
    lightPurple: true
  }
}

export const MpReColorPalette = {
  lightgrey:'#1A1B20',
  primary: {
    main: '#EFEDFDB2',
  },
  secondary: {
    main: '#C67EFF',
  },
  purple: {
    50: '#F4EBFC',
    100: '#E9D7F9',
    200: '#D3AEF3',
    300: '#BD86EC',
    400: '#A75DE6',
    500: '#9135E0',
    600: '#742AB3',
    700: '#572086',
    800: '#3A155A',
    900: '#1D0B2D',
  },
  blue: {
    50: '#E6F5FE',
    100: '#CCEAFD',
    200: '#99D5FB',
    300: '#66C1F9',
    400: '#33ACF7',
    500: '#2197F5',
    600: '#1A79C4',
    700: '#145B93',
    800: '#0C3C62',
    900: '#031E31',
  },
  grey: {
    50: '#F9F9F9',
    100: '#E3E3E3',
    200: '#C8C8C8',
    300: '#ACACAC',
    400: '#919191',
    500: '#757575',
    600: '#5E5E5E',
    700: '#464646',
    800: '#2F2F2F',
    900: '#171717',
  },
  pink: {
    50: '#FAE8F8',
    100: '#F5D2F1',
    200: '#EBA5E3',
    300: '#E177D5',
    400: '#D74AC7',
    500: '#CD1D89',
    600: '#A41794',
    700: '#7B116F',
    800: '#520C4A',
    900: '#290625',
  },
  cyan: {
    50: '#E6F5FE',
    100: '#CCEAFD',
    200: '#99D5FB',
    300: '#66C1F9',
    400: '#33A6F7',
    500: '#2197F5',
    600: '#1A79C4',
    700: '#145B93',
    800: '#0C3C62',
    900: '#031E31',
  },
  green: {
    50: '#e9faf5',
    100: '#D2F5EA',
    200: '#A5EBD6',
    300: '#78E0C1',
    400: '#4BD6AD',
    500: '#1ECC98',
    600: '#18A37A',
    700: '#127A5B',
    800: '#0C523D',
    900: '#06291E',
  },
  white: {
    main: '#ffffff',
    light: '#ffffff',
    dark: '#ffffff',
    contrastText: '#9135E0',
  },
  darkPurple: {
    main: '#3A155A',
    light: '#9135E0',
    dark: '#12005e',
    contrastText: '#ffffff',
  },
  lightPurple: {
    main: '#F8EFFF',
    light: '#F8EFFF',
    dark: '#D3AEF3',
    contrastText: '#9135E0',
  },
  background: {
    default: '#1A1B20',
  },
  text: {
    primary: '#3A155A',
    secondary: '#757575',
  },
  divider: '#EDEDED',
  error: {
    main: '#FF3D00',
    light: '#FFF3F0',
    dark: '#541400',
  },
  warning: {
    main: '#E66000',
    light: '#FFF3EB',
    dark: '#5C2600',
  },
  success: {
    main: '#3DA010',
    light: '#ECFFF1',
    dark: '#005418',
  },
  info: {
    main: '#426BF0',
    light: '#F1F4FE',
    dark: '#284090',
  },
} as const

const baseSize = 16
export const px2rem = (size = 16) => {
  return `${size / baseSize}rem`
}

export const MpReTheme = {
  colors: {
    primary: {
      default: '#9135E0',
    },
    secondary: {
      default: '#9135E0',
      600: '#6F8EF5',
      700: '#284090',
      800: '#1A2B60',
      900: '#0D1530',
      400: '#6889F3',
      300: '#8EA6F6',
      200: '#B3C4F9',
      100: '#D9E1FC',
      50: '#ECF0FE',
    },
    white: '#FFFFFF',
    gray: {
      default: '#757575',
      600: '#5E5E5E',
      700: '#464646',
      800: '#2F2F2F',
      900: '#171717',
      400: '#919191',
      300: '#ACACAC',
      200: '#C8C8C8',
      100: '#E3E3E3',
      50: '#F1F1F1',
    },
    tertiary: {
      pink: {
        default: '#C67EFF',
      },
      cyan: {
        default: '#2197F5',
        600: '#1A79C4',
        700: '#145B93',
        800: '#0C3C62',
        900: '#031E31',
        400: '#33A6F7',
        300: '#66C1F9',
        200: '#99D5FB',
        100: '#CCEAFD',
        50: '#E6F5FE',
      },
      green: {
        default: '#1ECC98',
        600: '#A41794',
        700: '#7B116F',
        800: '#520C4A',
        900: '#290625',
        400: '#4BD6AD',
        300: '#78E0C1',
        200: '#A5EBD6',
        100: '#D2F5EA',
        50: '#E9FAF5',
      },
    },
    informational: { default: '#426BF0', light: '#F1F4FE', dark: '#284090' }, // UPDATED -> Can we do shades like in the web theme?
    success: { default: '#3DA010', light: '#ECFFF1', dark: '#005418' }, // UPDATED -> Can we do shades like in the web theme?
    warning: { default: '#E66000', light: '#FFF3EB', dark: '#5C2600' }, // UPDATED -> Can we do shades like in the web theme?
    error: { default: '#FF3D00', light: '#FFF3F0', dark: '#541400' }, // UPDATED -> Can we do shades like in the web theme?
  },
}
export const Inter = {
    fontFamily: 'Inter',
  }
  export const Allison = {
    fontFamily: 'Allison',
  }
  export const fontssign={
    Allison: {
      Regular:{
        fontFamily: 'AllisonRegular',
      }
   
  }
}
  export const fonts = {
    Inter: {
      ExtraLight: {
        // 200
        fontFamily: 'PlusInterExtraLight',
      },
      200: {
        fontFamily: 'PlusInterExtraLight',
      },
      Light: {
        // 300
        fontFamily: 'PlusInterLight',
      },
      300: {
        // 300
        fontFamily: 'PlusInterLight',
      },
      Regular: {
        // 400
        fontFamily: 'InterRegular',
      },
      400: {
        // 400
        fontFamily: 'InterRegular',
      },
      Medium: {
        // 500
        fontFamily: 'InterMedium',
      },
      500: {
        // 500
        fontFamily: 'InterMedium',
      },
      SemiBold: {
        // 600
        fontFamily: 'InterSemiBold',
      },
      600: {
        // 600
        fontFamily: 'InterSemiBold',
      },
      Bold: {
        // 700
        fontFamily: 'InterBold',
      },
      700: {
        // 700
        fontFamily: 'InterBold',
      },
      ExtraBold: {
        // 800
        fontFamily: 'PlusInterExtraBold',
      },
      800: {
        // 800
        fontFamily: 'PlusInterExtraBold',
      },
    },
  };

  export const MpReThemeOptions: ThemeOptions = {
    palette: MpReColorPalette,
    spacing: 4,
    breakpoints: {
      values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1440 },
    },
      typography: {
      fontSize: baseSize,
      ...Inter,
      h1: {
        ...fonts.Inter.Bold,
        fontSize: px2rem(60),
        fontStyle: 'normal',
        fontWeight: 700,
        '@media (max-width: 600px)': {
          fontSize: px2rem(30),
        },
        lineHeight: 75 / 60, // 125%
        letterSpacing: '-2.1px',
        color: '#ffffff',
      },
          h2: {
        ...fonts.Inter.Bold,
        fontSize: px2rem(26),
        color: '#ffffff',
      },
      h3: {
        ...fonts.Inter.Bold,
        fontSize: 22,
        lineHeight: 22 / 20,
        color: '#ffffff',
      },
      h4: {
        ...fonts.Inter.Medium,
        fontSize: 20,
        letterSpacing: 0.1,
        lineHeight: 16 / 14,
        color: '#ffffff',
      },
      h5: {
        ...fonts.Inter.ExtraBold,
        fontSize: 12,
        lineHeight: 14 / 12,
        letterSpacing: 1.4,
      },
          body1: {
        ...fonts.Inter.Regular,
        fontSize: px2rem(14),
        lineHeight: 1.5,
        color: '#000',
      },
      subheader: {
        ...fonts.Inter.Regular,
        fontSize: px2rem(14),
        lineHeight: 1.5,
        color: '#fff',
      },
      label: {
        ...fonts.Inter.Regular,
        fontSize: px2rem(15),
        color: '#EFEDFDB2',
      },
      body2: {
        ...fonts.Inter.Medium,
        fontSize: px2rem(16),
        letterSpacing: '0.02rem',
        '@media (max-width: 600px)': {
          fontSize: px2rem(12),
        },
        lineHeight: 1.5,
        color: 'var(--text-colors-light-grey, rgba(239, 237, 253, 0.70))',
  
      },
      body3: {
        ...fonts.Inter.Regular,
        fontSize: px2rem(14),
        letterSpacing: '0.02rem',
        lineHeight: px2rem(21),
        color:'#ffffff'
      },
      body4: {
        ...fonts.Inter.Medium,
        fontSize: px2rem(16),
        letterSpacing: '0.02rem',
        lineHeight: 1.5,
        color:'#ffffff'
      },
      body5: {
        ...fonts.Inter.Bold,
        fontSize: px2rem(16),
        letterSpacing: '0.02rem',
        lineHeight: 1.5,
        color:'#ffffff'
      },
      caption: {
        ...fonts.Inter.SemiBold,
        fontSize: px2rem(18),
        color: '#ffffff',
        lineHeight: 17 / 14,
      },
    },
   
    components: {
      MuiButton: {
        styleOverrides: {
          contained: { 
            background: '#1A1B20',
           border: '1px solid #EFEDFDB2', 
            color:'#ffffff'
          },
          root: {
            ':hover':{
              background:'#426BF0',
              color:'#ffffff'
            },
           "&:disabled": {
              backgroundColor: '#EFEDFDB2',
              color:'#ffffff'
            },
            color:'#ffffff',
            background:'#426BF0',
            borderRadius: '8px',
            textTransform: 'none',
            ...fonts.Inter.Bold,
          },
          textSizeMedium: {
            // This override ensures variant=text has matching styles to variant=contained
            padding: '6px 16px',
          },
          outlined: {
            borderWidth: 2,
            // borderColor: 'white',
            // backgroundColor: '#3A155A',
            // color: 'white',
            ':hover': {
              borderWidth: 2,
              borderColor: '#EFEDFDB2',
              // backgroundColor: '#3A155A',
              color: '#ffffff',
            },
          },
          text: {
            // textTransform: 'none',
            // ...fonts.Jakarta.Bold,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
          color: 'primary',
          size: 'small',
        },
      },
      
      MuiOutlinedInput: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            height:50,
            color: '#EFEDFDB2',
            cursor:'pointer',
            border: '1px solid #EFEDFDB2',
            borderRadius: '10px',
            '&:hover': {
              borderColor: '#EFEDFDB2',
            },
            '&:focus': {
              borderColor: '#EFEDFDB2',
            },
            input: {
              '&:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 100px #1A1B20 inset',
                WebkitTextFillColor: '#EFEDFDB2',
              },
            },
          },
        },
      },
      MuiTab:{
        styleOverrides:{
          root: {
            borderBottom:'2px solid #EFEDFDB2',
            fontSize: px2rem(14),
            '@media (max-width: 600px)': {
              fontSize: px2rem(12),
            },
            fontFamily:'InterSemiBold'
  
          },
        }
      },
      MuiLink:{
        styleOverrides:{
          root: {
            color: '#6F8EF5',
            '@media (max-width: 600px)': {
              fontSize: px2rem(10),
            },
            textDecoration: 'underline', 
            '&:hover': {
              textDecoration: 'underline',
            },
            '&:focus': {
              textDecoration: 'underline',
            },
          },
        }
      }
    },
    
  }
  

const nonResponsiveTheme = createTheme(MpReThemeOptions)

export const theme = responsiveFontSizes(nonResponsiveTheme)
