import React from 'react'
import Badge, { BadgeProps } from '@mui/material/Badge'
import { styled } from '@mui/material/styles'

type OmittedBadgeProps = Omit<BadgeProps, 'variant' | 'badgeContent' | 'color'>
interface MpExperimentalBadgeProps extends OmittedBadgeProps {
  rightPosition: number
  hideBadge?: boolean
}
const MpExperimentalBadge = (props: MpExperimentalBadgeProps) => {
  const { rightPosition, hideBadge, ...badgeProps } = props
  const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
      right: rightPosition,
      top: 0,
    },
  }))
  return (
    <StyledBadge
      badgeContent={hideBadge ? 0 : 'Experimental'}
      color="warning"
      {...badgeProps}>
      {props.children}
    </StyledBadge>
  )
}

MpExperimentalBadge.defaultProps = {
  rightPosition: -40,
}
export default MpExperimentalBadge
