import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { theme } from '../../theme/DashboardTheme'
import backgroundretail from '../../assets/images/BGUserWeb.jpeg'
import backgroundmobretail from '../../assets/images/BgUserMob.jpg'
import logo from '@meprism/shared/src/assets/icons/logos/mePrismPrivacy.svg'

export const SubscribeLayout = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      sx={{
        height: '100dvh',
        backgroundImage: isMobile
          ? `url(${backgroundmobretail})`
          : `url(${backgroundretail})`,
        backgroundColor: theme.palette.grey[100],
        repeat: 'no-repeat',
        backgroundSize: 'cover',
        overflow: 'auto',
      }}>
      <Container
        sx={{
          maxWidth: '95% !important',
          mt: 4,
          mb: 4,
          mx: 1,
          overflow: 'auto',
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <img src={logo} />
        </Box>
        <Outlet />
      </Container>
    </Box>
  )
}
