import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { submitChangePassword } from '../../services/AuthenticationService'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../app/hooks'
import { logOutUser } from '../../redux/authentication/AuthenticationSlice'
import ValidationHint from '../shared/atom/MpValidationHint'
import { Visibility, VisibilityOff } from '@mui/icons-material'

const styles = {
  textField: {
    background: 'white',
    width: '100%',
  },
  resetPassword: {
    background: 'white',
    border: '1px solid #c67eff',
    color: '#c67eff',
    '&:hover': {
      color: 'white !important',
      background: '#c67eff',
    },
    '&:disabled': {
      color: 'white !important',
      background: '#e0c4f6',
    },
  },
  textDanger: {
    color: 'red',
  },
}

const ResetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const minPasswordLength = 10
  const [password, setPassword] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  })
  const [showPassword, setShowPassword] = useState({
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  })
  const { showOldPassword, showNewPassword, showConfirmPassword } = showPassword
  const { oldPassword, newPassword, confirmPassword } = password
  const [shouldDisplayPasswordInfo, setShouldDisplayPasswordInfo] =
    useState<boolean>(false)
  const [shouldShowMatchingPasswords, setShouldShowMatchingPasswords] =
    useState<boolean>(false)
  const [hasSufficientLength, setHasSufficientLength] = useState<boolean>(false)
  const [hasUpperCase, setHasUpperCase] = useState<boolean>(false)
  const [hasSymbol, setHasSymbol] = useState<boolean>(false)
  const [hasMatchingPassword, setHasMatchingPassword] = useState<boolean>(true)
  const [isErrResetPwd, setErrResetPwd] = useState<null | string>(null)
  const isPasswordValid =
    hasSufficientLength && hasUpperCase && hasSymbol && hasMatchingPassword

  useEffect(() => {
    setHasSufficientLength(newPassword.length >= minPasswordLength)
    setHasUpperCase(new RegExp(/[A-Z]/).test(newPassword))
    setHasSymbol(new RegExp(/[^A-Za-z0-9]/).test(newPassword))
    setHasMatchingPassword(newPassword === confirmPassword)
  }, [newPassword, confirmPassword])

  const handleResetPassword = async () => {
    try {
      setErrResetPwd(null)
      await submitChangePassword(oldPassword, newPassword)
      await dispatch(logOutUser()).unwrap()
      navigate('/login')
    } catch (error) {
      if (error.name === 'NotAuthorizedException')
        setErrResetPwd('Wrong old password')
      else setErrResetPwd('Could not reset password. Please try again later.')
    }
  }

  const handleSetPassword = (key: string, value: string) => {
    setPassword({ ...password, [key]: value })
  }

  const handleClickShowPassword = (key: string, value: boolean) => {
    setShowPassword({ ...showPassword, [key]: !value })
  }

  return (
    <Grid item xs={12} md={12}>
      <Typography variant="h3">Reset Password</Typography>
      <Box sx={{ marginBlock: 3 }}>
        <Typography variant="label">
          You will be logged out of your current session and will be required to
          log back in with your new password.
        </Typography>
      </Box>
      <Grid container rowSpacing={5} columnSpacing={5}>
        <Grid item xs={12} md={4}>
          <Typography variant="caption">Old Password *</Typography>
          <br />
          <FormControl variant="outlined">
            <OutlinedInput
              sx={styles.textField}
              onChange={(e) => handleSetPassword('oldPassword', e.target.value)}
              type={showOldPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{ fontSize: '10px' }}
                    aria-label="toggle password visibility"
                    onClick={() =>
                      handleClickShowPassword(
                        'showOldPassword',
                        showOldPassword,
                      )
                    }
                    edge="end">
                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="caption">New Password *</Typography>
          <br />
          <FormControl variant="outlined">
            <OutlinedInput
              sx={styles.textField}
              onFocus={() => setShouldDisplayPasswordInfo(true)}
              onChange={(e) => handleSetPassword('newPassword', e.target.value)}
              type={showNewPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      handleClickShowPassword(
                        'showNewPassword',
                        showNewPassword,
                      )
                    }
                    edge="end">
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="caption">Confirm New Password *</Typography>
          <br />
          <FormControl variant="outlined">
            <OutlinedInput
              sx={styles.textField}
              onChange={(e) =>
                handleSetPassword('confirmPassword', e.target.value)
              }
              onFocus={() => setShouldShowMatchingPasswords(true)}
              type={showConfirmPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      handleClickShowPassword(
                        'showConfirmPassword',
                        showConfirmPassword,
                      )
                    }
                    edge="end">
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <Box>
            {isErrResetPwd && (
              <ValidationHint isValid={false} text={isErrResetPwd} />
            )}
            {shouldDisplayPasswordInfo && (
              <Box>
                <ValidationHint
                  isValid={hasSufficientLength}
                  text={`At least ${minPasswordLength} characters`}
                />
                <ValidationHint
                  isValid={hasUpperCase}
                  text={'Contains at least one upper case letter'}
                />
                <ValidationHint
                  isValid={hasSymbol}
                  text={'Contains number or symbol'}
                />
                {shouldShowMatchingPasswords && (
                  <ValidationHint
                    isValid={hasMatchingPassword}
                    text={'Passwords match'}
                  />
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item md={12}>
          <Button
            disabled={!isPasswordValid}
            sx={styles.resetPassword}
            onClick={handleResetPassword}>
            Reset Password
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResetPassword
