import React, { ReactNode, useState } from 'react'
import { MpButton, MpTypography } from '../shared'
import {
  Controller,
  FieldArrayPath,
  UseFieldArrayReturn,
} from 'react-hook-form'
import {
  Box,

  Checkbox,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import MpSvgIcon from '../shared/atom/MpSvgIcon'
import MpTheme from '@meprism/shared/src/config/MpTheme'
import { DatePicker } from '@mui/x-date-pickers'
import * as SigninTheme from '../../theme/OnboardingTheme'
// import * as themenew from '../../theme/theme'
import { Logger } from '@meprism/app-utils'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import {
  ProfileFormHookReturn,
  ProfileSchemaType,
  useProfileForm,
} from '../../utils/hooks/useProfileForm'
import { FieldArray } from 'react-hook-form/dist/types/fieldArray'
import { TextFieldProps } from '@mui/material/TextField'
import { MpReTheme, fonts as fontsnew } from '../../theme/OnboardingTheme'

// Address is not a single string schema, and we have to render it differently
// anyway because of its multiple inline inputs, no room for an inline remove, etc
type SantizedFieldArrayPath = Exclude<
  FieldArrayPath<ProfileSchemaType>,
  'address'
>

interface RemovableMappedInputProps<
  TFieldArrayName extends SantizedFieldArrayPath,
> {
  array: UseFieldArrayReturn<ProfileSchemaType, TFieldArrayName, 'id'>
  formKey: TFieldArrayName
  minimumLength?: number
  maximumLength?: number
  errors: ProfileFormHookReturn['formState']['errors']
  register: ProfileFormHookReturn['register']
  label: string
  labelIcon?: ReactNode
  defaultValue: FieldArray<ProfileSchemaType, TFieldArrayName>
  header?: string
  inputProps?: TextFieldProps['InputProps']
  control?: ProfileFormHookReturn['control']
  required?: boolean
}

const styles = {
  inputContainer: {
    // maxWidth: MpTheme.layouts.widths.sm,
    width: '100%',
  },
  header: {
    mb: 5,
  },
} as const

const RemovableMappedInput = <TFieldArrayName extends SantizedFieldArrayPath>({
  array,
  formKey,
  // minimumLength = 0,
  // maximumLength = 10,
  errors,
  register,
  label,
  // labelIcon,
  // defaultValue,
  header = label,
  inputProps,
  control,
  required,
}: RemovableMappedInputProps<TFieldArrayName>) => {
  const err = errors?.[formKey]

  return (
    <Box sx={styles.inputContainer}>
      {array.fields.map((field, index) => {
        return (
          <Box
            key={field.id}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            {control ? (
              <Controller
                render={({ field: controlledField }) => (
                  <TextField
                    error={!!(Array.isArray(err) ? err?.[index]?.value : false)}
                    helperText={
                      Array.isArray(err)
                        ? err?.[index]?.value?.message || ' '
                        : ' '
                    }
                    {...(index === 0 && { label: '', placeholder: header })}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ ...inputProps }}
                    fullWidth
                    {...controlledField}
                    label={'foo'}
                  />
                )}
                name={`${formKey}.${index}.value` as const}
                control={control}
              />
            ) : (
              <TextField
                error={!!(Array.isArray(err) ? err?.[index]?.value : false)}
                helperText={
                  Array.isArray(err) ? err?.[index]?.value?.message || ' ' : ' '
                }
                InputLabelProps={{ shrink: true }}
                required={required}
                {...(index === 0 && { label: '', placeholder: header })}
                InputProps={{ ...inputProps }}
                {...register(`${formKey}.${index}.value` as const)}
                fullWidth
              />
            )}
            {/* {index >= minimumLength && (
              <Box>
                <MpButton variant={'text'} onClick={() => array.remove(index)}>
                  Remove
                </MpButton>
                <FormHelperText> </FormHelperText>
              </Box>
            )} */}
          </Box>
        )
      })}
      {/* {array.fields.length < maximumLength && (
        <Box sx={{ width: '100%', display: 'flex' }}>
          <Button
            variant={'text'}
            onClick={() => array.append(defaultValue)}
            startIcon={labelIcon}
            sx={{
              alignSelf: 'flex-start',
              '&.MuiButton-text': { fontFamily: fonts.Jakarta.Regular },
            }}>
            Add {label}
          </Button>
        </Box>
      )} */}
    </Box>
  )
}

export const PrivacyOnboardingView = () => {
  const navigate = useNavigate()
  const [privacyChecked, setPrivacyChecked] = useState(false)
  // const [noMiddleNameChecked, setNoMiddleNameChecked] = useState(false)

  // const [consentAgreementOpen, setConsentAgreementOpen] = useState(false)

  const {
    firstNameArray,
    lastNameArray,
    middleNameArray,
    addressArray,
    register,
    errors,
    control,
    handleSubmit,
    submitHandler,
  } = useProfileForm({
    onSubmitSuccess: async () => {
      try {
        // this one activates profile with OneRep
        // this API is prone to change!
        // await dispatch(putProfile()).unwrap()
        // TODO: Activate a scan somehow
        // await dispatch(setupProfile()).unwrap()
        navigate('/authorization')
      } catch (error) {
        Logger.error(`Error setting up OneRep profile: ${error}`)
        toast.error('Your profile could not be processed')
      }
    },
    onSubmitError: (error) => {
      Logger.error(`Error setting up mePrism profile: ${error}`)
      toast.error('Your profile could not be processed')
    },
  })

  // const setName = (name: string) => setValue('mp_auth_letter_signature', name)

  const textStyle = {
    ...fontsnew.Inter.Regular,
    fontSize: '14px',
  }
  // const onboardingTheme = SigninTheme.theme
  // const defaultTheme = themenew.theme
  const isMobile = useMediaQuery(SigninTheme.theme.breakpoints.down('sm'))
  return (
    <>
      <Box sx={{ textAlign: 'left', alignItems: 'center', px: 3, mt: 16,maxWidth: MpTheme.layouts.widths.sm,
            mx: 'auto' , }}>
        <MpTypography variant={'h2'} color={'#ffffff'}>
          Create Your mePrism Profile
        </MpTypography>
      </Box>
      <Stack
        component={'form'}
        onSubmit={handleSubmit(submitHandler)}
        spacing={2}
        sx={{
          
          maxWidth: MpTheme.layouts.widths.sm,
          mx: 'auto' ,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: 3,
          mt: 5,
          textAlign: 'center',
          '& .MuiFormLabel-asterisk': {
            color: 'red',
          },
        }}>
        <Stack
          spacing={1}
          sx={{
            maxWidth: 'sm',
            alignItems: 'center',
            width: '100%',
            px: 'auto',
          }}>
          <RemovableMappedInput
            array={firstNameArray}
            formKey={'firstName'}
            errors={errors}
            register={register}
            header={'First Name *'}
            label={'a Nickname'}
            minimumLength={1}
            required
            labelIcon={
              <MpSvgIcon
                icon={'profile'}
                color={MpTheme.colors.primary.default}
              />
            }
            defaultValue={{ value: '' }}
          />
          <RemovableMappedInput
            array={middleNameArray}
            formKey={'middleName'}
            errors={errors}
            register={register}
            label={'Middle Name or Initial'}
            labelIcon={
              <MpSvgIcon
                icon={'profile'}
                color={MpTheme.colors.primary.default}
              />
            }
            defaultValue={{ value: '' }}
          />
          <RemovableMappedInput
            array={lastNameArray}
            formKey={'lastName'}
            errors={errors}
            register={register}
            header={'Last Name *'}
            label={'a Last Name'}
            minimumLength={1}
            required
            labelIcon={
              <MpSvgIcon
                icon={'profile'}
                color={MpTheme.colors.primary.default}
              />
            }
            defaultValue={{ value: '' }}
          />
          <Box style={styles.inputContainer}>
            <Stack divider={<Divider sx={{ mb: 4 }} />}>
              {addressArray.fields.map((field, index) => (
                <Box key={field.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}>
                    {/* {index > 0 && (
                      <>
                        <MpTypography variant={'h2'} sx={{ color: '#767676' }}>
                          Past Location {index}
                        </MpTypography>
                        <Button
                          variant={'text'}
                          onClick={() => addressArray.remove(index)}>
                          Remove Address
                        </Button>
                      </>
                    )} */}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}>
                    <TextField
                      sx={{ width: '60%' }}
                      placeholder={'City *'}
                      required
                      error={!!errors?.address?.[index]?.city}
                      helperText={
                        errors?.address?.[index]?.city?.message || ' '
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register(`address.${index}.city` as const)}
                    />
                    <TextField
                      placeholder="State *"
                      sx={{ width: '30%' }}
                      required
                      error={!!errors?.address?.[index]?.state}
                      helperText={
                        errors?.address?.[index]?.state?.message || ' '
                      }
                      InputLabelProps={{ shrink: true }}
                      {...register(`address.${index}.state` as const, {
                        setValueAs: (v) => (v ? v.toUpperCase() : ''), // Handle null or undefined values
                      })}
                    />
                  </Box>
                  {/*<TextField*/}
                  {/*  label={'Street Address'}*/}
                  {/*  fullWidth*/}
                  {/*  error={!!errors?.address?.[index]?.streetAddress}*/}
                  {/*  helperText={!!errors?.address?.[index]?.message || ' '}*/}
                  {/*  InputLabelProps={{ shrink: true }}*/}
                  {/*  {...register(`address.${index}.streetAddress` as const)}*/}
                  {/*/>*/}
                </Box>
              ))}
            </Stack>
          </Box>
          <Box sx={{alignItems:'left',width:'100%'}}>
          <Typography style={{textAlign:'left',color: '#EFEDFDB2'}}>Birthdate: MM/DD/YYYY *</Typography>
          <Controller
            name={'birthdate'}
            control={control}
            render={({ field }) => (
             
              <DatePicker
              
              OpenPickerButtonProps={{ style: { color: '#6F8EF5' } }}
                renderInput={(params) => (
                  <>
                 
                  <TextField
                    fullWidth
                    {...params}
                    error={!!errors?.birthdate}
                    helperText={errors?.birthdate?.message ?? ' '}
                  /></>
                  
                )}
                {...field}
              />
            )}
          /></Box>
        </Stack>
        <Divider flexItem />
        <Stack
          spacing={5}
          sx={{
            maxWidth: 'sm',
            display: 'flex',
              alignItems: 'flex-start',
            width: '100%',
            px: 'auto',
          }}>
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'flex-start',
            }}>
            <Checkbox
              checked={privacyChecked}
              onChange={() => setPrivacyChecked(!privacyChecked)}
            />
            <MpTypography variant={'caption'} sx={{mt:3,textAlign:'left'}}>
              I have read and agree to the{' '}
              <Link
              style={{textDecoration:'underline',fontSize:'16px' }}
                underline={'hover'}
                href={'https://meprism.com/privacy-policy'}>
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link  style={{textDecoration:'underline',fontSize:'16px'}}underline={'hover'} href={'https://meprism.com/terms'}>
                Terms of Use
              </Link>
              .
            </MpTypography>
          </Box>
          {/* {name === '' ? (
            <MpButton
              variant={'contained'}
              size={'large'}
              color={'darkPurple'}
              disabled={!privacyChecked}>
              Continue
            </MpButton>
          )  */}
          {/* : ( */}
            <MpButton
              type={'submit'}
              variant={'contained'}
              size={'large'}
              color={'darkPurple'}
              sx={{width:'100%'}}
              onClick={handleSubmit(submitHandler)}
              disabled={!privacyChecked}>
                Continue
            </MpButton>
          {/* )} */}
        </Stack>
        {!isMobile &&(<Box sx={{  maxWidth: MpTheme.layouts.widths.sm,width: '100%',position:'absolute',bottom:!isMobile?'30px':'80px'}}>
              <Stack
                direction={'row'}
                sx={{ alignItems: 'center', mt: 15,}}
                spacing={!isMobile?15:10}
                justifyContent={'space-between'}>
                <Link
                  href={'https://meprism.com/terms-conditions'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Terms & Conditions
                </Link>
                <Link
                  href={'https://meprism.com/privacy-policy'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Privacy Policy
                </Link>
                <Link
                  href={'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Contact Us
                </Link>
              </Stack>
            </Box>)}
        
      </Stack>
    </>
  )
}
