import React, { Suspense } from 'react'
import Typography from '@mui/material/Typography'
import { BrokerSkeleton } from './BrokerSkeleton'
import { Await, useRouteLoaderData } from 'react-router-dom'
import BrokerList from './BrokerList'

const BrokerListLoading = () => (
  <>
    {[0, 0, 0, 0, 0].map((_, index) => (
      <BrokerSkeleton key={index} />
    ))}
  </>
)

const BrokerListError = () => {
  return (
    <Typography variant={'body1'} color={'error'}>
      Oops! We had trouble retrieving your results. Please try again, or contact
      support
    </Typography>
  )
}

export const BrokerListSuspense = () => {
  const loaderData = useRouteLoaderData('main') as any

  return (
    <Suspense fallback={<BrokerListLoading />}>
      <Await resolve={loaderData.brokerList} errorElement={<BrokerListError />}>
        <BrokerList />
      </Await>
    </Suspense>
  )
}
