import {
  PeopleFinderSelectors,
  putDataBrokerAccuracy,
  selectLastScanDate,
} from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import { Box, Grid, Paper, SxProps, Typography } from '@mui/material'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/storeExports'
import MpDividerLine from '../../shared/atom/MpDividerLine'
import IsThisYou from '../../shared/molecule/IsThisYou'
import { useParams } from 'react-router-dom'
import { MpSvgIcon } from '../../shared'
import {
  prettyPrintTimeInterval,
  prettyPrintTimeSince,
} from '@meprism/shared/src/utils/dateHelpers'
import { fonts } from '../../../theme/theme'
import { selectActiveGrant } from '../../../redux/product/ProductSlice'

const BrokerDetails = () => {
  const broker = useAppSelector(PeopleFinderSelectors.selectBrokerDetails)
  const grant = useAppSelector(selectActiveGrant)
  const params = useParams()
  const dispatch = useAppDispatch()
  const lastScanComplete = useAppSelector(selectLastScanDate)

  const overlay: SxProps =
    params?.scan_result_id === broker?.summary?.scan_result_id
      ? { position: 'sticky', top: '100px' }
      : { filter: 'opacity(.35)', position: 'sticky', top: 0 }

  const renderedUpdateDate: Date =
    lastScanComplete &&
    new Date(lastScanComplete) > new Date(broker?.summary.update_time as string)
      ? new Date(lastScanComplete)
      : new Date(broker?.summary.update_time as string)

  const nextScanDate = new Date(renderedUpdateDate.getTime())
  nextScanDate.setMonth(nextScanDate.getMonth() + 1)
  console.log(broker?.detail.status,"brokerstatus")
  return (
    <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
      <Box sx={overlay}>
        {broker?.detail.status === 'removed' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '20px',
            }}>
            <MpSvgIcon icon={'deleted'} />
            <Typography
              variant={'body2'}
              sx={{ marginLeft: '7px', fontFamily: fonts.Jakarta[600] }}
              color={'success.main'}>
              Removed{' '}
              {prettyPrintTimeSince(
                new Date(broker?.detail.updated_at as string),
              )}
            </Typography>
          </Box>
        )}
        <Paper sx={{ borderRadius: '24px', padding: '40px' }}>
          <Typography variant={'h1'} noWrap>
            {broker?.detail.data_broker}
          </Typography>
          <Typography variant={'body2'} sx={{ marginTop: '4px' }}>
            Updated{' '}
            {new Date(broker?.detail.updated_at as string).toLocaleDateString(
              'en-US',
              {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              },
            )}
          </Typography>
          {broker?.detail?.status !== 'removed' && grant && (
            <Typography variant={'body2'}>
              Next scan in{' '}
              {prettyPrintTimeInterval(new Date(), nextScanDate, {
                separator: '',
              })}
            </Typography>
          )}
        </Paper>
        {broker?.detail.status !== 'removed' && (
          <IsThisYou
            onAccuratePress={(yes) =>
              dispatch(
                putDataBrokerAccuracy({
                  scan_result_id: broker?.summary.scan_result_id as string,
                  accurate: yes,
                }),
              )
            }
            validated={broker?.summary.accurate}
          />
        )}
        <Typography
          variant={'body2'}
          sx={{
            color: '#3A155A',
            marginTop: '10px',
          }}>
          First Name
        </Typography>
        <Typography
          variant={'body1'}
          sx={{ color: '#3A155A', fontWeight: 600 }}>
          {broker?.detail.first_name}
        </Typography>
        <MpDividerLine sx={{ marginTop: '12px', marginBottom: '12px' }} />
        {broker?.detail.middle_name && (
          <>
            <Typography variant={'body2'} sx={{ color: '#3A155A' }}>
              Middle Name
            </Typography>
            <Typography
              variant={'body1'}
              sx={{ color: '#3A155A', fontWeight: 600 }}>
              {broker?.detail.middle_name}
            </Typography>

            <MpDividerLine sx={{ marginTop: '12px', marginBottom: '12px' }} />
          </>
        )}
        <Typography variant={'body2'} sx={{ color: '#3A155A' }}>
          Last Name
        </Typography>
        <Typography
          variant={'body1'}
          sx={{ color: '#3A155A', fontWeight: 600 }}>
          {broker?.detail.last_name}
        </Typography>
        <MpDividerLine sx={{ marginTop: '12px', marginBottom: '12px' }} />
        <Typography variant={'body2'} sx={{ color: '#3A155A' }}>
          Age
        </Typography>
        <Typography
          variant={'body1'}
          sx={{ color: '#3A155A', fontWeight: 600 }}>
          {broker?.detail.age}
        </Typography>
        <MpDividerLine sx={{ marginTop: '12px', marginBottom: '12px' }} />
        {broker?.detail.addresses && (
          <>
            <Typography variant={'body2'} sx={{ color: '#3A155A' }}>
              Address
            </Typography>
            {(broker?.detail?.addresses ?? []).map((addressObj, i) => (
              <Box key={i}>
                <Typography
                  variant={'body1'}
                  sx={{ color: '#3A155A', fontWeight: 600 }}>
                  {addressObj.street}
                </Typography>
                <Typography
                  variant={'body1'}
                  sx={{ color: '#3A155A', fontWeight: 600 }}>
                  {addressObj.city}, {addressObj.state}, {addressObj.zip}
                </Typography>
              </Box>
            ))}
            <MpDividerLine sx={{ marginTop: '12px', marginBottom: '12px' }} />
          </>
        )}
        {broker?.detail?.phones && broker?.detail?.phones.length > 0 && (
          <>
            <Typography variant={'body2'} sx={{ color: '#3A155A' }}>
              Phone
            </Typography>
            {(broker?.detail?.phones ?? []).map((phoneObj, i) => (
              <Typography
                key={i}
                variant={'body1'}
                sx={{ color: '#3A155A', fontWeight: 600 }}>
                {phoneObj}
              </Typography>
            ))}
            <MpDividerLine sx={{ marginTop: '12px', marginBottom: '12px' }} />
          </>
        )}
        {broker?.detail.emails && broker.detail.emails.length > 0 && (
          <>
            <Typography variant={'body2'} sx={{ color: '#3A155A' }}>
              Emails
            </Typography>
            {(broker?.detail.emails ?? []).map((emailObj, i) => (
              <Typography
                variant={'body1'}
                key={i}
                sx={{ color: '#3A155A', fontWeight: 600 }}>
                {emailObj}
              </Typography>
            ))}
            <MpDividerLine sx={{ marginTop: '12px', marginBottom: '12px' }} />
          </>
        )}
        <Typography variant={'body2'} sx={{ color: '#3A155A' }}>
          Relatives
        </Typography>
        {broker?.detail.relatives.map((relative, index) => (
          <Typography
            key={index}
            variant={'body1'}
            sx={{ color: '#3A155A', fontWeight: 600 }}>
            {relative}
          </Typography>
        ))}

        <MpDividerLine sx={{ marginTop: '12px', marginBottom: '12px' }} />
      </Box>
    </Grid>
  )
}

export default BrokerDetails
