import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { store } from '../../redux/store'
import {
  fetchFamilyStatus,
  pollForEntitlements,
  postFamilyStatus,
  selectActiveGrant,
  selectFamilyStatus,
} from '../../redux/product/ProductSlice'
import { useAppSelector } from '../../app/hooks'
import { MpSvgIcon } from '../shared'
import { useAppDispatch } from '../../redux/storeExports'

export const subscribeLandingLoader = async () => {
  store.dispatch(fetchFamilyStatus())
  store.dispatch(pollForEntitlements())
  return null
}

const styles = {
  textThemeViolet: {
    color: '#d99ff9',
  },
  textThemeGray: {
    color: '#a0a0a4',
  },
  buttonTheme: {
    minWidth: '30%',
    backgroundColor: '#426bf0',
    color: 'white !important',
    '&:hover': {
      border: '1px solid #426bf0',
      color: '#426bf0 !important',
      backgroundColor: 'white !important',
    },
  },
  skipButtonTheme: {
    width: '30%',
    border: '1px solid #d99ff9',
    backgroundColor: 'white',
    color: '#d99ff9 !important',
    '&:hover': {
      color: 'white !important',
      backgroundColor: '#d99ff9 !important',
    },
  },
  linkColor: {
    color: '#426bf0',
    textDecorationColor: '#426bf0',
  },
  invited: {
    color: '#ffc300 ',
    paddingLeft: '1rem',
  },
  accepted: {
    color: '#2ecc71',
    paddingLeft: '1rem',
  },
}

const SubscribeLanding = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const grant = useAppSelector(selectActiveGrant)
  const familyPlan = useAppSelector(selectFamilyStatus)
  const [email, setEmail] = useState('')
  const [isSuccess, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const handleSubscribe = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const maxChars = 100; // Maximum characters allowed for email

    if (email.length > maxChars) {
      setError('Email exceeds maximum character limit')
      return;
    }
    if (!emailRegex.test(email) ) {
      setError('Invalid email format')
      return
    }
    if (email !== '') {
      await dispatch(postFamilyStatus(email)).unwrap()
      if (!familyPlan.error) {
        setSuccess(true)
      }
    }
  }

  return (
    <Grid container p={10}>
      <Grid item md={6} xs={12}>
        <Box>
          <Typography variant="h1" sx={styles.textThemeGray}>
            <span style={styles.textThemeViolet}>Thank you</span> for
            subscribing to mePrism
          </Typography>
        </Box>
        <Box sx={{ marginBlock: '1.5rem' }}>
          <Typography variant="body2">
            We are working hard to remove your records. Expect some removals
            within 48 hours. It will take upto 3 months of repeated action by
            mePrism to compel the stubborn once the comply.
          </Typography>
        </Box>
        <Grid container columnSpacing={5}>
          <Grid item md={2}>
            <MpSvgIcon icon="subscribelogo" height={45} />
          </Grid>
          <Grid item md={10}>
            <Box sx={{ marginBlockEnd: '0.8rem' }}>
              <Typography variant="h3" sx={{ fontSize: '14px !important' }}>
                Get the most out of your subscription
              </Typography>
            </Box>
            <Typography variant="caption">
              Ensure your profile is fully set up. This allows us to scan all
              broker sites and remove your profile as needed.
            </Typography>
          </Grid>
        </Grid>
        {grant && grant.sku.split('.')[2] === 'pro' && (
          <Box sx={{ marginBlock: '1.5rem' }}>
            <Button
              sx={styles.buttonTheme}
              onClick={() => navigate('/subscription')}>
              Continue
            </Button>
          </Box>
        )}
      </Grid>
      {grant &&
        (grant.sku.split('.')[2] === 'fam' ||
          grant.grant_source.includes('family')) && (
          <Grid item md={6} xs={12}>
            <Card sx={{ maxWidth: '90%' }}>
              <CardContent>
                <Typography variant="subheader">Invite Plus One</Typography>
                <Box sx={{ marginBlock: '1rem' }}>
                  <Typography variant="body2">
                    Invite your plus one to your account. Once you have sent the
                    invite, you cannot change this.{' '}
                    <Link
                      sx={styles.linkColor}
                      href="https://meprism1.atlassian.net/servicedesk/customer/portal/1">
                      Contact mePrism support
                    </Link>{' '}
                    for further assistance.
                  </Typography>
                </Box>
                {familyPlan.email ? (
                  <Box>
                    <Typography variant="body2">
                      {familyPlan.email}
                      <span
                        style={
                          ['invited', 'queued'].includes(
                            familyPlan.invite_status,
                          )
                            ? styles.invited
                            : styles.accepted
                        }>
                        {['invited', 'queued'].includes(
                          familyPlan.invite_status,
                        )
                          ? 'Invited'
                          : familyPlan.invite_status.charAt(0).toUpperCase() +
                            familyPlan.invite_status.slice(1)}
                      </span>
                    </Typography>
                  </Box>
                ) : !isSuccess ? (
                  <Box>
                    <TextField
                      size="small"
                      error={!!error}
                      helperText={error ?? ''}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter email..."
                      sx={{ width: '100%' }}
                    />
                    <Box sx={{ marginBlock: '1rem' }}>
                      <Button sx={styles.buttonTheme} onClick={handleSubscribe}>
                        Send Invite
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="body2">
                      {email}
                      <span style={styles.invited}>Invited</span>
                    </Typography>
                  </Box>
                )}
                {!isSuccess && familyPlan.email === '' ? (
                  <Box sx={{ marginBlockStart: '3rem' }}>
                    <Button
                      sx={styles.skipButtonTheme}
                      onClick={() => navigate('/subscription')}>
                      Skip & Continue
                    </Button>
                    <br />
                    <Typography variant="caption">
                      You can add your plus one later through Profile {'>'} Plus
                      One
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ marginBlockStart: '3rem' }}>
                    <Button
                      sx={styles.buttonTheme}
                      onClick={() => navigate('/subscription')}>
                      Continue
                    </Button>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
    </Grid>
  )
}

export default SubscribeLanding
