import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { store } from './redux/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from '@mui/material'
import { theme } from './theme/theme'
import { AuthProvider } from '@meprism/shared/src/context/AuthContext'
import { setUpExtraListeners } from './redux/extraListeners'
import { startAppListening } from './redux/listenerMiddleware'
import { FormDataProvider } from './utils/hooks/FormDataHelper'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!)

setUpExtraListeners(startAppListening)

root.render(
  <React.StrictMode>
    <AuthProvider>
      <FormDataProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider></FormDataProvider>
    </AuthProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
