import { Grid } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import MpScanCard from '../../shared/molecule/MpScanCard'
import MpSubscriptionWidget from '../../shared/molecule/MpSubscriptionWidget'
import NotMeCard from '../../shared/molecule/NotMeCard'
import {
  selectActiveGrant,
  selectShouldDisplaySubscriptionWidget,
} from '../../../redux/product/ProductSlice'
import { useAppSelector } from '../../../redux/storeExports'
import { PeopleFinderSelectors } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import MpDownloadMobileWidget from '../../shared/molecule/MpDownloadMobileWidget'

const BrokerHome = () => {
  const location = useLocation()
  const inaccurate = location.pathname.includes('inaccurate')
  const displayWidget = useAppSelector(selectShouldDisplaySubscriptionWidget)
  const activeGrant = useAppSelector(selectActiveGrant)
  const inaccurateResults = useAppSelector(
    PeopleFinderSelectors.selectInaccurateBrokers,
  )
  return (
    <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
      <MpScanCard />

      {displayWidget && <MpSubscriptionWidget sx={{ marginTop: '61px' }} />}
      {!inaccurate && inaccurateResults.length > 0 && <NotMeCard />}
      {activeGrant && <MpDownloadMobileWidget />}
    </Grid>
  )
}

export default BrokerHome
