import {
  createPortalSession,
  selectPaymentPlatform,
} from '@meprism/shared/src/redux/product/ProductSlice'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { fonts } from '../../../theme/theme'
import MpSvgIcon from '../atom/MpSvgIcon'
import { Box, Button, Typography } from '../MaterialExports'

const PaymentDeclinedWidget = () => {
  const dispatch = useAppDispatch()
  const paymentPlatform = useAppSelector(selectPaymentPlatform)
  const onStripeClick = async () => {
    const response = await dispatch(createPortalSession()).unwrap()
    window.location.href = response.url
  }
  const onAndroidClick = () => {
    window.open('https://play.google.com/store/paymentmethods')
  }
  const onIosClick = () => {
    window.open('https://apps.apple.com/account/billing')
  }
  const onClick = () => {
    switch (paymentPlatform) {
      case 'android':
        onAndroidClick()
        break
      case 'ios':
        onIosClick()
        break
      case 'stripe':
      default:
        onStripeClick()
        break
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#FFF3F0',
        padding: '20px',
        borderRadius: '24px',
      }}>
      <MpSvgIcon icon={'paymentDeclined'} />
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: '16px' }}>
        <Typography
          variant={'body2'}
          sx={{
            color: 'error.main',
            fontSize: '17px',
            fontFamily: fonts.Jakarta[700],
            flex: 1,
          }}>
          Renewal Payment Declined
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            color: 'error.main',
            fontSize: '17px',
            fontFamily: fonts.Jakarta[400],
          }}>
          We could not process your payment. Your service is paused and will
          resume once payment information is successfully updated.
        </Typography>
      </Box>
      <Button variant={'outlined'} color={'error'} onClick={onClick}>
        Update Payment Information
      </Button>
    </Box>
  )
}

export default PaymentDeclinedWidget
