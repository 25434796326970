import React, { PropsWithChildren } from 'react'
import { selectIsAuthLoading } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import { useAppSelector } from '../../redux/storeExports'
import MpLoadingIcon from '../shared/atom/MpLoadingIcon'
import { useNavigation } from 'react-router-dom'

export const AuthLoader = ({ children }: PropsWithChildren) => {
  const authLoading = useAppSelector(selectIsAuthLoading)
  const navigation = useNavigation()

  if (authLoading || navigation.state === 'loading') {
    return <MpLoadingIcon positioned />
  }

  return <>{children}</>
}
