import React, { ReactNode } from 'react'
import { DownloadProgress, ExportStatus } from '../../redux/export/ExportTypes'
import { MpTypography } from '../shared/atom/MpTypography'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box } from '../shared/MaterialExports'
import { assertNever } from '../../utils/helpers'
import { numberofDaysFromToday } from '../../utils/dateHelpers'
import Stack from '@mui/material/Stack'
import { Button, IconButton, Paper, styled } from '@mui/material'

export interface MpProgressIndicatorProps {
  progressPercent: number
  small?: boolean
  widthContainter?: number
}
const MpProgressIndicator = (props: MpProgressIndicatorProps) => {
  const WIDTH = props.widthContainter ? `${props.widthContainter}px` : '150px'

  const ProgressContainer = styled(Box)(({}) => ({
    display: 'flex',
    width: WIDTH,
    ':before': {
      position: 'absolute',
      background: 'linear-gradient(225deg, #A75DE6, #426BF0 100%)',
      borderRadius: '2px',
      width: WIDTH,
      height: '4px',
      content: '" "',
      opacity: '.33',
    },
  }))
  const ProgressBar = styled(Box)(({}) => ({
    borderRadius: '2px',
    width: props.progressPercent < 3 ? '4px' : `${props.progressPercent}%`,
    background: 'linear-gradient(225deg, #A75DE6, #426BF0 100%)',
    height: '4px',
    opacity: '1',
  }))

  return (
    <ProgressContainer>
      <ProgressBar></ProgressBar>
    </ProgressContainer>
  )
}

type CardPropsReturn = {
  titleComponent: ReactNode
  body?: ReactNode
}

interface ExportCardProps {
  createDate: Date
  status: ExportStatus
  expireDate: Date
  downloadProgress?: DownloadProgress
  onPress: () => void
}

interface CardProps {
  status: ExportStatus
  createDate: Date
  expireDate: Date
  downloadProgress?: DownloadProgress
  onPress: () => void
}

const getCardProps = ({
  status,
  createDate,
  expireDate,
  downloadProgress,
  onPress,
}: CardProps): CardPropsReturn => {
  switch (status) {
    case ExportStatus.NEW:
    case ExportStatus.IN_PROCESS:
      return {
        titleComponent: (
          <MpTypography
            variant={'h4'}
            component={'span'}
            sx={{
              color: 'warning.main',
              fontWeight: 'heavy',
            }}>
            Pending
          </MpTypography>
        ),
        body: <></>,
      }
    case ExportStatus.READY:
      return {
        titleComponent: (
          <MpTypography
            variant={'h4'}
            component={'span'}
            sx={{ color: 'gray' }}>
            Expires in {numberofDaysFromToday(expireDate)} d
          </MpTypography>
        ),
        body: downloadProgress ? (
          <>
            <Box mx={'auto'} width={'100%'}>
              {downloadProgress?.bytesWritten <
              downloadProgress?.contentLength ? (
                <Box>
                  <MpProgressIndicator
                    progressPercent={
                      (downloadProgress.bytesWritten /
                        downloadProgress.contentLength) *
                      100
                    }
                    // the typo here is the LEAST of the problems with this monstrosity
                    // of a component
                    widthContainter={300}
                  />
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <MpTypography
                      variant={'body1'}
                      sx={{ color: 'gray' }}
                      component={'span'}>
                      Downloaded
                    </MpTypography>
                    <IconButton onClick={onPress}>
                      <RefreshIcon color="primary" />
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Button
                variant="outlined"
                onClick={onPress}
                sx={{ width: '100%' }}>
                Retrieve Your Download
              </Button>
            </Box>
          </>
        ),
      }
    case ExportStatus.EXPIRED:
      return {
        titleComponent: (
          <MpTypography
            variant={'h4'}
            component={'span'}
            sx={{ color: 'gray' }}>
            Expired {Math.abs(numberofDaysFromToday(createDate))} d ago
          </MpTypography>
        ),
        body: <></>,
      }
    case ExportStatus.FAILED:
      return {
        titleComponent: (
          <MpTypography
            variant={'h4'}
            component={'span'}
            sx={{ color: 'gray' }}>
            Failed
          </MpTypography>
        ),
        body: (
          <MpTypography
            variant={'h4'}
            component={'span'}
            sx={{ color: 'error.main' }}>
            Please try again
          </MpTypography>
        ),
      }
    default:
      return assertNever(status)
  }
}

export const ExportCard = ({
  createDate,
  status,
  expireDate,
  downloadProgress,
  onPress,
}: ExportCardProps) => {
  const extraProps = getCardProps({
    status,
    createDate,
    expireDate,
    onPress,
    downloadProgress,
  })

  return (
    <Paper sx={{ padding: 4, minWidth: '300px' }}>
      <Stack spacing={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap',
          }}>
          <MpTypography variant="h4" component={'span'}>
            Data Download
          </MpTypography>
          {extraProps.titleComponent}
        </Box>
        <MpTypography variant="body2" sx={{ color: 'gray' }}>
          Requested{' '}
          {createDate.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </MpTypography>

        {extraProps.body && <Box>{extraProps.body}</Box>}
      </Stack>
    </Paper>
  )
}
