import React, { PropsWithChildren, Suspense } from 'react'
import { useAppSelector } from '../../../redux/storeExports'
import { selectOneRepProfileId } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import { Await, Navigate, useRouteLoaderData } from 'react-router-dom'
import { LoadingFallback } from '../../shared/atom/LoadingFallback'

const ProfileWrapper = ({ children }: PropsWithChildren) => {
  const oneRepProfileId = useAppSelector(selectOneRepProfileId)

  if (!oneRepProfileId) {
    return <Navigate to={'/onboarding'} replace />
  }

  return <>{children}</>
}

export const RequireProfile = ({ children }: PropsWithChildren) => {
  const { peopleFinderProfile } = useRouteLoaderData('main') as any
  console.warn('People Finder', peopleFinderProfile)
  return (
    <Suspense fallback={<LoadingFallback />}>
      <Await
        resolve={peopleFinderProfile}
        errorElement={<Navigate to={'/onboarding'} replace />}>
        <ProfileWrapper>{children}</ProfileWrapper>
      </Await>
    </Suspense>
  )
}
