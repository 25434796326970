import React, { SVGProps } from 'react'
import { ReactComponent as Globe } from '@meprism/shared/src/assets/icons/Globe.svg'
import { ReactComponent as ShieldTwoTone } from '@meprism/shared/src/assets/icons/ShieldTwoTone.svg'
import { ReactComponent as FacebookLogo } from '../../../assets/images/facebook.svg'
import { ReactComponent as Phone } from '../../../assets/images/phone.svg'
import { ReactComponent as Envelope } from '../../../assets/images/envelope.svg'
import { ReactComponent as MePrismColorHorz } from '../../../assets/images/logos/logo_color_horz.svg'
import { ReactComponent as MePrismWhiteCenter } from '../../../assets/images/logos/logo_white_center.svg'
import { ReactComponent as MePrismWhiteHorzTag } from '../../../assets/images/logos/logo_white_horz.svg'
import { ReactComponent as MeprismPrivacy } from '../../../assets/images/logos/logo_privacy.svg'
import { ReactComponent as ErrorX } from '../../../assets/images/error-x.svg'
import { ReactComponent as FreeUserLock } from '../../../assets/images/freeuserlock.svg'
import { ReactComponent as ErrorNew } from '@meprism/shared/src/assets/icons/check-close.svg'
import { ReactComponent as SuccessNew } from '@meprism/shared/src/assets/icons/check-circle.svg'
import { ReactComponent as CheckCircle } from '../../../assets/images/check-circle.svg'
import { ReactComponent as CheckCircleOutline } from '../../../assets/images/check_circle_outline.svg'
import { ReactComponent as ZipFile } from '../../../assets/images/icon-file-zip.svg'
import { ReactComponent as InProgress } from '../../../assets/images/icon_in_progress.svg'
import { ReactComponent as Arrow } from '../../../assets/images/arrow-right.svg'
import { ReactComponent as DownloadAppStoreBadge } from '../../../assets/images/download-app-store.svg'
import { ReactComponent as DownloadPlayStoreBadge } from '../../../assets/images/download-play-store.svg'
import { ReactComponent as SubscribeLogo } from '../../../assets/images/profile-document.svg'
import { ReactComponent as AuthenticatorTwoTone } from '@meprism/shared/src/assets/icons/AuthenticatorTwoTone.svg'
import { ReactComponent as User } from '@meprism/shared/src/assets/icons/User.svg'
import { ReactComponent as UserTwoTone } from '@meprism/shared/src/assets/icons/UserTwoTone.svg'
import { ReactComponent as Home } from '@meprism/shared/src/assets/icons/Home.svg'
import { ReactComponent as HomeTwoTone } from '@meprism/shared/src/assets/icons/HomeTwoTone.svg'
import { ReactComponent as Alert } from '@meprism/shared/src/assets/icons/Alert.svg'
import { ReactComponent as TrashCan } from '@meprism/shared/src/assets/icons/TrashCan.svg'
import { ReactComponent as Info } from '@meprism/shared/src/assets/icons/Info.svg'
import { ReactComponent as ThumbsUp } from '@meprism/shared/src/assets/icons/thumbs/thumbs_up.svg'
import { ReactComponent as ThumbsUpSelected } from '@meprism/shared/src/assets/icons/thumbs/thumbs_up_selected.svg'
import { ReactComponent as ThumbsDown } from '@meprism/shared/src/assets/icons/thumbs/thumbs_down.svg'
import { ReactComponent as ThumbsDownSelected } from '@meprism/shared/src/assets/icons/thumbs/thumbs_down_selected.svg'
import { ReactComponent as Close } from '@meprism/shared/src/assets/icons/Close.svg'
import { ReactComponent as Collapse } from '@meprism/shared/src/assets/icons/Collapse.svg'
import { ReactComponent as Unread } from '@meprism/shared/src/assets/icons/Unread.svg'
import { ReactComponent as Back } from '@meprism/shared/src/assets/icons/Back.svg'
import { ReactComponent as Lightbulb } from '@meprism/shared/src/assets/icons/LightBulb.svg'
import { ReactComponent as Error } from '@meprism/shared/src/assets/icons/Error.svg'
import { ReactComponent as Warning } from '@meprism/shared/src/assets/icons/Warning.svg'
import { ReactComponent as Success } from '@meprism/shared/src/assets/icons/Success.svg'
import { ReactComponent as NotMe } from '@meprism/shared/src/assets/icons/NotMe.svg'
import { ReactComponent as Me } from '@meprism/shared/src/assets/icons/Me.svg'
import { ReactComponent as Deleted } from '@meprism/shared/src/assets/icons/Deleted.svg'
import { ReactComponent as Profile } from '@meprism/shared/src/assets/icons/Profile.svg'
import { ReactComponent as AppleLogo } from '@meprism/shared/src/assets/icons/logos/Apple.svg'
import { ReactComponent as Apple } from '@meprism/shared/src/assets/icons/applenew.svg'
import { ReactComponent as GoogleLogo } from '@meprism/shared/src/assets/icons/logos/Google.svg'
import { ReactComponent as Google } from '@meprism/shared/src/assets/icons/Googlenew.svg'
import { ReactComponent as IosButton } from '@meprism/shared/src/assets/buttons/ios_download.svg'
import { ReactComponent as GoogleButton } from '@meprism/shared/src/assets/buttons/google_download.svg'
import { ReactComponent as PaymentDeclined } from '@meprism/shared/src/assets/icons/PaymentDeclined.svg'
import { ReactComponent as Pencil } from '@meprism/shared/src/assets/icons/Pencil.svg'
import { ReactComponent as LocationMarker } from '@meprism/shared/src/assets/icons/LocationMarker.svg'

import MpTheme from '@meprism/shared/src/config/MpTheme'
// type SvgIconProps = SVGProps<SVGSVGElement>

type SvgIconProps = SVGProps<SVGSVGElement> & { title?: string }

export type MpIconList =
  | 'globe'
  | 'shieldTwoTone'
  | 'appleLogo'
  | 'googleLogo'
  | 'google'
  | 'apple'
  | 'facebookLogo'
  | 'phone'
  | 'envelope'
  | 'mePrismColorHorz'
  | 'mePrismWhiteCenter'
  | 'mePrismWhiteHorzTag'
  | 'mePrismPrivacy'
  | 'errorX'
  | 'checkCircle'
  | 'checkCircleOutline'
  | 'zipFile'
  | 'inProgress'
  | 'arrow'
  | 'downloadAppStoreBadge'
  | 'downloadPlayStoreBadge'
  | 'user'
  | 'userTwoTone'
  | 'home'
  | 'homeTwoTone'
  | 'alert'
  | 'trashCan'
  | 'info'
  | 'thumbsUp'
  | 'thumbsUpSelected'
  | 'thumbsDown'
  | 'thumbsDownSelected'
  | 'close'
  | 'collapse'
  | 'unread'
  | 'back'
  | 'lightbulb'
  | 'authenticatorTwoTone'
  | 'error'
  | 'warning'
  | 'success'
  | 'errornew'
  | 'successnew'
  | 'notMe'
  | 'me'
  | 'deleted'
  | 'profile'
  | 'iosButton'
  | 'googleButton'
  | 'paymentDeclined'
  | 'pencil'
  | 'locationMarker'
  | 'freeuserlock'
  | 'subscribelogo'

const icons: { [key in MpIconList]: (svgProps: SvgIconProps) => JSX.Element } =
  {
    globe: (svgProps: SvgIconProps) => <Globe {...svgProps} />,
    shieldTwoTone: (svgProps) => <ShieldTwoTone {...svgProps} />,
    appleLogo: (svgProps: SvgIconProps) => <AppleLogo {...svgProps} />,
    apple: (svgProps: SvgIconProps) => <Apple {...svgProps} />,
    googleLogo: (svgProps: SvgIconProps) => <GoogleLogo {...svgProps} />,
    google: (svgProps: SvgIconProps) => <Google {...svgProps} />,
    facebookLogo: (svgProps: SvgIconProps) => <FacebookLogo {...svgProps} />,
    phone: (svgProps: SvgIconProps) => <Phone {...svgProps} />,
    envelope: (svgProps: SvgIconProps) => <Envelope {...svgProps} />,
    mePrismColorHorz: (svgProps: SvgIconProps) => (
      <MePrismColorHorz {...svgProps} />
    ),
    mePrismWhiteCenter: (svgProps: SvgIconProps) => (
      <MePrismWhiteCenter {...svgProps} />
    ),
    mePrismWhiteHorzTag: (svgProps: SvgIconProps) => (
      <MePrismWhiteHorzTag {...svgProps} />
    ),
    errorX: (svgProps: SvgIconProps) => <ErrorX {...svgProps} />,
    checkCircle: (svgProps: SvgIconProps) => <CheckCircle {...svgProps} />,
    checkCircleOutline: (svgProps: SvgIconProps) => (
      <CheckCircleOutline {...svgProps} />
    ),
    zipFile: (svgProps: SvgIconProps) => <ZipFile {...svgProps} />,
    inProgress: (svgProps: SvgIconProps) => <InProgress {...svgProps} />,
    arrow: (svgProps: SvgIconProps) => <Arrow {...svgProps} />,
    downloadAppStoreBadge: (svgProps: SvgIconProps) => (
      <DownloadAppStoreBadge {...svgProps} />
    ),
    downloadPlayStoreBadge: (svgProps: SvgIconProps) => (
      <DownloadPlayStoreBadge {...svgProps} />
    ),
    mePrismPrivacy: (svgProps) => <MeprismPrivacy {...svgProps} />,
    user: (svgProps) => <User {...svgProps} />,
    userTwoTone: (svgProps) => <UserTwoTone {...svgProps} />,
    home: (svgProps) => <Home {...svgProps} />,
    homeTwoTone: (svgProps) => <HomeTwoTone {...svgProps} />,
    alert: (svgProps) => <Alert {...svgProps} />,
    trashCan: (svgProps) => <TrashCan {...svgProps} />,
    info: (svgProps) => <Info {...svgProps} />,
    thumbsUp: (svgProps) => <ThumbsUp {...svgProps} />,
    thumbsUpSelected: (svgProps) => <ThumbsUpSelected {...svgProps} />,
    thumbsDown: (svgProps) => <ThumbsDown {...svgProps} />,
    thumbsDownSelected: (svgProps) => <ThumbsDownSelected {...svgProps} />,
    close: (svgProps) => <Close {...svgProps} />,
    collapse: (svgProps) => <Collapse {...svgProps} />,
    unread: (svgProps) => <Unread {...svgProps} />,
    back: (svgProps) => <Back {...svgProps} />,
    lightbulb: (svgProps) => <Lightbulb {...svgProps} />,
    warning: (svgProps) => <Warning {...svgProps} />,
    error: (svgProps) => <Error {...svgProps} />,
    errornew: (svgProps) => <ErrorNew {...svgProps} />,
    successnew: (svgProps) => <SuccessNew {...svgProps} />,
    success: (svgProps) => <Success {...svgProps} />,
    authenticatorTwoTone: (svgProps) => <AuthenticatorTwoTone {...svgProps} />,
    notMe: (svgProps) => <NotMe {...svgProps} />,
    me: (svgProps) => <Me {...svgProps} />,
    deleted: (svgProps) => <Deleted {...svgProps} />,
    profile: (svgProps) => <Profile {...svgProps} />,
    iosButton: (svgProps) => <IosButton {...svgProps} />,
    googleButton: (svgProps) => <GoogleButton {...svgProps} />,
    paymentDeclined: (svgProps) => <PaymentDeclined {...svgProps} />,
    pencil: (svgProps) => <Pencil {...svgProps} />,
    locationMarker: (svgProps) => <LocationMarker {...svgProps} />,
    freeuserlock: (svgProps) => <FreeUserLock {...svgProps} />,
    subscribelogo: (svgProps) => <SubscribeLogo {...svgProps} />,
  }

export interface MpSvgIconProps extends SvgIconProps {
  icon: MpIconList
}

const defaultProps = {
  color: MpTheme.colors.primary['800'],
}

const MpSvgIcon = (props: MpSvgIconProps) => {
  const { icon, ...extraProps } = props

  return icons[icon]({ ...defaultProps, ...extraProps })
}

// export const MpMuiIcon = (props: MpSvgIconProps) => {
//   const { icon, color, ...extraProps } = props
//
//   return <SvgIcon component={icons[icon]} color={color} {...extraProps} />
// }

export type TwoToneSvg = {
  [T in MpIconList]: `${T}TwoTone` extends MpIconList ? T : never
}[MpIconList]

export default MpSvgIcon
