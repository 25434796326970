import {
  Box,
  Button,
  Divider,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState  } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/storeExports'
import {
  AuthenticationActions,
  getPreferredMfa,
  logOutUser,
  selectAccountEmail,
  selectMuid,
  selectPreferredMfa,
} from '@meprism/shared/src/redux/authentication/authenticationSlice'
import {
  getCurrentAuthenticatedUser,
  setPreferredMfa
} from '@meprism/shared/src/services/AuthenticationService'
import {
  useAuthContext,
} from '@meprism/shared/src/context/AuthContext'
import { store } from '../../../redux/store'
import MpTheme from '@meprism/shared/src/config/MpTheme'
import { MfaCard } from '../../Authentication/MfaCard'
import { Toast } from '../../toast/toast'
import { LoaderFunction, Link, useNavigate } from 'react-router-dom'
import { AuthLoader } from '../../gateways/AuthLoader'
import { DeleteAccountModal } from '../../shared/molecule/DeleteAccountModal'

const innerBoxSx = {
  maxWidth: MpTheme.layouts.widths.sm,
  mx: 'auto',
  width: '100%',
} as const

export const accountLoader: LoaderFunction = async () => {
  // note that NOT unwrapping this is intentional, if we can't get their MFA, then we'll render the
  // screen as though it's not set
  return  store.dispatch(getPreferredMfa()).unwrap()
}

const Account = () => {
  const accountEmail = useAppSelector(selectAccountEmail)
  const { registeredPreferredMfa, draftPreferredMfa } = useAppSelector(selectPreferredMfa)
  const muid = useAppSelector(selectMuid)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const authContext = useAuthContext()
// 
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false)
  console.log(registeredPreferredMfa, draftPreferredMfa)
  const handleLogout = async () => {
    //@ts-ignore
    Intercom('shutdown')
    //@ts-ignore
    window.intercomSettings = {
      app_id: 'p9mftca9',
      custom_launcher_selector: '#intercom-launcher',
    }
    await dispatch(logOutUser()).unwrap()
    navigate('/login')
  }
  const enableTwoFactor = draftPreferredMfa !== 'NOMFA'

  const onTwoFactorSubmit = async () => {
    authContext.setUser(await getCurrentAuthenticatedUser())
  if (draftPreferredMfa === 'SOFTWARE_TOKEN_MFA') {
      navigate('/totp', { state: { from: location.pathname } })
    } else {
      setPreferredMfa('NOMFA').then(()=>{
         store.dispatch(getPreferredMfa())
        Toast.show({
          type: 'success',
          text1: 'Your MFA settings have been saved',
        })
      })
    
    }
  }

  const handleDeleteClick = () => setDeleteAccountOpen((open) => !open)

  return (
    <AuthLoader>
      <DeleteAccountModal
        open={deleteAccountOpen}
        setOpen={setDeleteAccountOpen}
      />
      <Stack
        divider={<Divider sx={{ mt: 4, mb: 10 }} />}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: MpTheme.layouts.widths.md,
          width: '100%',
          mx: 'auto',
        }}>
        <Box sx={innerBoxSx}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Typography variant={'h2'}>mePrism ID</Typography>
            <Button
              variant="text"
              onClick={() => {
                navigator.clipboard.writeText(muid as string)
              }}>
              Copy
            </Button>
          </Box>
          <Typography variant={'body2'}>{muid}</Typography>
        </Box>

        <Box sx={innerBoxSx}>
          <Typography variant={'h2'}>Sign In Information</Typography>
          <TextField
            value={accountEmail}
            fullWidth
            disabled
            sx={{ marginTop: '20px', marginBottom: '32px' }}
          />

          <Link to={'/resetPassword'} state={{ from: location.href }}>
            <Button variant={'text'}>Forgot Password?</Button>
          </Link>
        </Box>
        <Box sx={innerBoxSx}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography variant={'subheader'}>
              Two-Factor Authentication
            </Typography>
            <Switch
              checked={enableTwoFactor}
              onChange={(e) =>
                dispatch(
                  AuthenticationActions.setDraftMfa(
                    e.target.checked ? 'SOFTWARE_TOKEN_MFA' : 'NOMFA',
                  ),
                )
              }
            />
          </Box>
          {enableTwoFactor && (
            <>
              <MfaCard />
            </>
          )}
          <Button
            variant={'contained'}
            sx={{ mt: 5 }}
            onClick={onTwoFactorSubmit}
            disabled={draftPreferredMfa === registeredPreferredMfa}>
            Save Changes
          </Button>
        </Box>
        <Box sx={innerBoxSx}>
          <Box sx={{ mr: 'auto' }}>
            <Button
              variant={'outlined'}
              color={'darkPurple'}
              onClick={handleLogout}>
              Sign Out
            </Button>
          </Box>
          <Button
            variant={'text'}
            color={'error'}
            sx={{ my: 12 }}
            onClick={handleDeleteClick}>
            Delete Account
          </Button>
        </Box>
      </Stack>
    </AuthLoader>
  )
}

export default Account
