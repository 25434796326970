import { Linking, Platform } from 'react-native'
import { Logger } from '@meprism/app-utils'
// import AnalyticsManager from '../services/AnalyticsManager'

/// Use with caution, do not abuse this method by using it for business logic code.
const isRunningLocally = (): boolean => {
  return __DEV__
}

const isIOS = (): boolean => {
  return Platform.OS === 'ios'
}

export const openLinkWithAnalytics = (url: string) => {
  // AnalyticsManager.trackTypedEvent({ event: 'Link Opened', url: url })
  Logger.info(`Opening URL: ${url}`)
  Linking.openURL(url)
}

export const openExternalSupportLink = () => {
  openLinkWithAnalytics(
    'https://meprism1.atlassian.net/servicedesk/customer/portal/1',
  )
}

export const openExternalFeedbackLink = (email: string) => {
  openLinkWithAnalytics(
    'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/8?email=' +
      email,
  )
}

export const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
] as const

export { isRunningLocally, isIOS }
