import useWebSocket from 'react-use-websocket'
import { useCallback, useEffect, useRef } from 'react'
import { getUserAuthToken } from '../../services/AuthenticationService'
import { handlePeopleFinderEvent } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import { Logger } from '@meprism/app-utils'
import { AlertActions } from '@meprism/shared/src/redux/alert/AlertSlice'
import { handleEntitlementChange } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import { WebSocketPayload } from '@meprism/shared/src/utils/hooks/MessageTypes'
import { useAppDispatch } from '../../redux/storeExports'

interface UseInboxSocketProps {
  prefix?: string
  fqdn: string
}

export const useInboxSocket = ({
  prefix = 'wss://websocket.',
  fqdn,
}: UseInboxSocketProps) => {
  const getSocketUrl = useCallback(async () => {
    const authToken = await getUserAuthToken()
    return `${prefix}${fqdn}?token=${encodeURIComponent(authToken)}`
  }, [fqdn, prefix])
  const dispatch = useAppDispatch()
  const isMounted = useRef(true)
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])
  return useWebSocket(getSocketUrl, {
    share: true,
    shouldReconnect: (closeEvent) => {
      const willReconnect = isMounted.current
      Logger.info(
        `ws got close event: ${closeEvent}, reconnecting: ${willReconnect}`,
      )
      return willReconnect
    },
    onOpen: () => {
      Logger.info('ws opened')
    },
    onError: (event) => Logger.info(`ws error ${event}`),
    onMessage: (event) => {
      Logger.debug(`ws message: ${event.data} `)
      try {
        const message: WebSocketPayload = JSON.parse(event.data)
        switch (message?.type) {
          case 'inboxMessage':
            dispatch(AlertActions.addAlert(message.data))
            break
          case 'EntitlementsChanged':
            handleEntitlementChange()
            break
          default:
            // we'll assume it's a People Finder event, if not, that'll yell
            dispatch(handlePeopleFinderEvent(message))
        }
      } catch (error) {
        Logger.error(`Error parsing websocket message: ${error}`)
      }
    },
  })
}
