import { createSelector } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { AlertResponse, AlertState, AlertType } from './AlertTypes'
import { Logger } from '@meprism/app-utils'
import { dateDefaultUtc } from '../../utils/dateHelpers'
import { APIInterface, createBaseAsyncThunk } from '../base'

type BaseState = {
  alerts: AlertState
}

const initialState: AlertState = {
  alerts: [],
  loading: false,
}

const getAlerts = async (API: APIInterface): Promise<AlertResponse> => {
  return API.get('Alert', '/current/messages', {})
}

const postReadAlerts = async (alertIds: string[], API: APIInterface) => {
  return API.put('Alert', '/current/messages/view', {
    body: alertIds,
  })
}

const deleteAlert = async (alertId: string, API: APIInterface) => {
  return API.del('Alert', `current/messages/${alertId}`, {})
}

const deleteAlerts = async (alertIds: string[], API: APIInterface) => {
  return API.put('Alert', '/current/messages/delete', {
    body: alertIds,
  })
}

export const fetchAlerts = createBaseAsyncThunk(
  'alerts/fetchAlerts',
  async (_, { extra }) => {
    try {
      const response = await getAlerts(extra.API)
      // required as long as the responses are not timezone aware
      // the times ARE utc, they just lack the zone info
      return response.results.map((a) => ({
        ...a,
        create_time: dateDefaultUtc(a.create_time),
      }))
    } catch (error) {
      Logger.error(`Error getting alerts: ${error}`)
      throw error
    }
  },
)

export const readAlerts = createBaseAsyncThunk(
  'alerts/readAlerts',
  async (_, { getState, extra }) => {
    try {
      const state = getState() as BaseState
      const alerts = state?.alerts?.alerts
      await postReadAlerts(
        alerts.map((alert) => alert.id),
        extra.API,
      )
    } catch (error) {
      Logger.error(`Error posting read alerts: ${error}`)
      throw error
    }
  },
)

export const dismissAlert = createBaseAsyncThunk(
  'alerts/dismissAlert',
  async (alertId: string, { extra }) => {
    try {
      await deleteAlert(alertId, extra.API)
    } catch (error) {
      Logger.error(`Error deleting alert ${alertId}: ${error}`)
      throw error
    }
  },
)

export const dismissAllAlerts = createBaseAsyncThunk(
  'alerts/dismissAllAlerts',
  async (_, { getState, extra }) => {
    try {
      const state = getState() as BaseState
      await deleteAlerts(
        state.alerts.alerts.map((alert) => alert.id),
        extra.API,
      )
    } catch (error) {
      Logger.error(`Error deleting alerts: ${error}`)
      throw error
    }
  },
)

const { actions, reducer } = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: (state, { payload }: { payload: AlertType }) => {
      if (!state.alerts.find((alert) => alert.id === payload.id)) {
        state.alerts.push(payload)
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAlerts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAlerts.fulfilled, (state, action) => {
      state.alerts = action.payload
      state.loading = false
    })
    builder.addCase(fetchAlerts.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(readAlerts.fulfilled, (state) => {
      const nowString = new Date().toISOString()
      state.alerts = state.alerts.map((alert) => ({
        ...alert,
        view_time: alert.view_time || nowString,
      }))
    })
    builder.addCase(dismissAlert.fulfilled, (state, action) => {
      state.alerts = state.alerts.filter(
        (alert) => alert.id !== action.meta.arg,
      )
    })
    builder.addCase(dismissAllAlerts.fulfilled, (state) => {
      state.alerts = []
    })
  },
})

export const AlertActions = actions
export const AlertReducer = reducer

export const selectAlerts = (state: BaseState) => state.alerts.alerts

export const selectHasUnreadAlerts = createSelector([selectAlerts], (alerts) =>
  alerts.some((alert) => !alert.view_time),
)

export const AlertSelectors = {
  selectAlerts,
  selectHasUnreadAlerts,
} as const

export const AlertThunks = {
  fetchAlerts,
  readAlerts,
  dismissAlert,
  dismissAllAlerts,
} as const
