import {
  Email,
  GenerateVerificationCodePayload,
  Phone,
  ProfilePost,
  ProfileResponse,
  ProfileSelectorType,
  ProfileSlice,
  VerifyCodePayload,
} from './ProfileTypes'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { Logger } from '@meprism/app-utils'
import { APIInterface, createBaseAsyncThunk } from '../base'
import { AuthenticationState } from '../authentication/authenticationTypes'

export interface BaseProfileState {
  authentication: AuthenticationState
  profile: ProfileSelectorType
}

const postProfileRequest = async (
  profile: ProfilePost,
  API: APIInterface,
): Promise<ProfileResponse> => {
  return API.post('Profile', '', {
    body: profile,
  })
}

export const FetchProfile = createBaseAsyncThunk(
  'fetchProfile',
  async (_, { extra }) => {
    try {
      const payload: ProfileResponse = await extra.API.get('Profile', '', {})
      // For now, manually ensure that things that should be arrays in fact are,
      // because I guess they can come back nullish or not arrayed...
      // @TODO: Remove this logic once data model hardens a little
      const prof: ProfileSlice = {
        ...payload,
        names: {
          first: Array.isArray(payload.names?.first)
            ? payload.names.first
            : [payload?.name?.first ?? ''],
          middle: Array.isArray(payload.names?.middle)
            ? payload.names.middle
            : [payload?.name?.middle ?? ''],
          last: Array.isArray(payload.names?.last)
            ? payload.names.last
            : [payload?.name?.last ?? ''],
        },
        address: Array.isArray(payload.address)
          ? payload.address
          : [payload.address],
      }
      return prof
    } catch (error) {
      Logger.error(`Error getting profile: ${error}`)
      throw error
    }
  },
)

export const PostProfile = createBaseAsyncThunk(
  'postProfile',
  async (payload: ProfileFields, { getState, extra }) => {
    const state = getState() as BaseProfileState
    const muid = state.authentication?.muid
    try {
      const response = await postProfileRequest(
        {
          ...payload,
          userID: muid as string,
          name: {
            first: payload.names?.first?.[0]?.trim?.() ?? '',
            middle: payload.names?.middle?.[0]?.trim?.() ?? '',
            last: payload.names?.last?.[0]?.trim?.() ?? '',
          },
        },
        extra.API,
      )
      extra.AnalyticsManager.trackTypedEvent({
        event: 'Profile Saved',
      })
      extra.Toast.show({
        type: 'success',
        text1: 'Your profile has been saved',
      })
      const prof: ProfileSlice = {
        ...response,
        names: {
          first: Array.isArray(response.names?.first)
            ? response.names.first
            : [response?.name?.first ?? ''],
          middle: Array.isArray(response.names?.middle)
            ? response.names.middle
            : [response?.name?.middle ?? ''],
          last: Array.isArray(response.names?.last)
            ? response.names.last
            : [response?.name?.last ?? ''],
        },
        address: Array.isArray(response.address)
          ? response.address
          : [response.address],
      }
      return prof
    } catch (error) {
      Logger.error(`Error posting profile: ${error} ${JSON.stringify(error)}`)
      extra.Toast.show({
        type: 'error',
        text1: 'We were unable to save your profile',
        text2:
          'Please ensure all required fields are correctly filled out. Otherwise, please contact support.',
      })
      throw error
    }
  },
)

export const GenerateVerificationCode = createBaseAsyncThunk(
  'generateCode',
  async (payload: GenerateVerificationCodePayload, { extra }) => {
    try {
      await extra.API.post('Profile', '/generate', { body: payload })
    } catch (error) {
      Logger.error(`Error generating verification code: ${error}`)
      throw error
    }
  },
)

export const VerifyCode = createBaseAsyncThunk(
  'veriifyCode',
  async (payload: VerifyCodePayload, { extra }) => {
    try {
      await extra.API.post('Profile', '/verify', { body: payload })
    } catch (error) {
      Logger.error(`Error validating verification code: ${error}`)
      throw error
    }
  },
)

const initialState: ProfileSelectorType = {
  profile: {
    name: {
      first: '',
      middle: '',
      last: '',
    },
    names: {
      first: [''],
      middle: [''],
      last: [''],
    },
    address: [
      {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
    ],
    emails: [{ address: '', validated: false }] as Email[],
    phones: [{ number: '', validated: false }] as Phone[],
    gender: '',
    birthdate: '',
    accountAge: undefined,
    dataUsage: 0,
    userID: '',
    employer: '',
    jobTitle: '',
    industry: '',
    mp_auth_letter_signature: '',
    auth_letter_version: undefined,
  },
  error: false,
  meta: {
    blockingRequestIds: [],
    profileFetchIds: [],
  },
}

const { actions, reducer } = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(FetchProfile.fulfilled, (state, { payload, meta }) => {
      state.error = false
      state.profile = payload
      state.meta.profileFetchIds = state.meta.profileFetchIds.filter(
        (id) => id !== meta.requestId,
      )
    })
    builder.addCase(FetchProfile.pending, (state, { meta }) => {
      state.meta.profileFetchIds.push(meta.requestId)
      state.error = false
    })
    builder.addCase(FetchProfile.rejected, (state, { meta }) => {
      state.meta.profileFetchIds = state.meta.profileFetchIds.filter(
        (id) => id !== meta.requestId,
      )
      state.error = true
    })
    builder.addCase(PostProfile.fulfilled, (state, { payload, meta }) => {
      state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
        (id) => id !== meta.requestId,
      )
      state.error = false
      state.profile = payload
    })
    builder.addCase(PostProfile.pending, (state, { meta }) => {
      state.meta.blockingRequestIds.push(meta.requestId)
      state.error = false
    })
    builder.addCase(PostProfile.rejected, (state, { meta }) => {
      state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
        (id) => id !== meta.requestId,
      )
      state.error = true
    })
    builder.addCase(GenerateVerificationCode.fulfilled, (state, { meta }) => {
      state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
        (id) => id !== meta.requestId,
      )
      state.error = false
    })
    builder.addCase(GenerateVerificationCode.pending, (state, { meta }) => {
      state.meta.blockingRequestIds.push(meta.requestId)
      state.error = false
    })
    builder.addCase(GenerateVerificationCode.rejected, (state, { meta }) => {
      state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
        (id) => id !== meta.requestId,
      )
      state.error = true
    })
    builder.addCase(VerifyCode.fulfilled, (state, { meta }) => {
      state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
        (id) => id !== meta.requestId,
      )
      state.error = false
    })
    builder.addCase(VerifyCode.pending, (state, { meta }) => {
      state.meta.blockingRequestIds.push(meta.requestId)
      state.error = false
    })
    builder.addCase(VerifyCode.rejected, (state, { meta }) => {
      state.meta.blockingRequestIds = state.meta.blockingRequestIds.filter(
        (id) => id !== meta.requestId,
      )
      state.error = true
    })
  },
})

export const selectProfileSlice = (state: BaseProfileState) => state.profile

export const selectProfile = (state: BaseProfileState) => state.profile.profile

// The "modern" profile only uses SOME fields. Rather than have to deal with updating the whole world
// (not to mention that the backend probably still has this legacy cruft)
// easiest to just select the fields we WILL use for privacy profile updates
// Primary use case is asking ex. "Are there unsaved changes to this profile"
// without having to examine stuff we don't even use like jobTitle
export const selectProfileFields = createSelector(
  [selectProfile],
  ({
    names,
    emails,
    birthdate,
    phones,
    address,
    mp_auth_letter_signature,
    gender,
    auth_letter_version,
  }) => ({
    names,
    emails,
    birthdate,
    phones,
    address,
    mp_auth_letter_signature,
    gender,
    auth_letter_version,
  }),
)

export const selectProfileName = createSelector(
  selectProfileFields,
  ({ names }) =>
    [names?.first?.[0], names?.middle?.[0], names?.last?.[0]]
      .filter(Boolean)
      .join(' '),
)

// OK this is not the best name, but this one is for us POSTing a profile
export const selectProfileLoading = (state: BaseProfileState) =>
  state.profile.meta.blockingRequestIds.length > 0
// ...and this one is for us fetching, which we do a lot more frequently
export const selectProfileFetching = (state: BaseProfileState) =>
  state.profile.meta.profileFetchIds.length > 0

  export const selectShouldShowAuthorizationModal = createSelector(
    [
      selectProfileFetching,
      (state: BaseProfileState) =>
        !!state.profile?.profile?.mp_auth_letter_signature,
    ],
    (isFetching, hasSignature) => {
      return !isFetching && !hasSignature;
    }
  )

export const isFetchingSignature = createSelector(
  [selectProfileFetching],
  (isFetching) => isFetching
);
export const ProfileActions = actions
export const ProfileReducer = reducer

export type ProfileFields = ReturnType<typeof selectProfileFields>

export const ProfileSelectors = {
  selectProfileSlice,
  selectProfile,
  selectProfileFields,
  selectProfileLoading,
  selectProfileFetching,
  selectProfileName,
} as const

export const CURRENT_AUTH_LETTER_VERSION = '1.0.0'
