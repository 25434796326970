import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Link, { LinkProps } from '@mui/material/Link'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { MpSvgIcon } from '..'
import { useNavigate } from 'react-router-dom'

const MpMenuBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '93px',
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.grey[900],
  background: 'rgba(255,255,255,0.85)',
}))

const MpAppBar = styled(AppBar)(() => ({
  boxShadow: 'none',
  background: 'none',
  // maxWidth: theme.maxWidth || '1440px',
  maxWidth: '1440px',
  // flex: `1 1 ${theme.maxWidth || '1440px'}`,
  flex: `1 1 1440px`,
  color: 'inherit',
  height: '100%',
}))

const MpToolbar = styled(Toolbar)(() => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
}))

const LinkBox = styled(Box)(() => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end',
}))

export const MpNavLink = (props: LinkProps) => {
  const { className = '', ...linkProps } = props

  const active =
    typeof location != 'undefined' && props.href === location.pathname
      ? 'active'
      : ''
  const StyledNavLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '93px',
    borderBottom: '3px solid transparent',
    transition: 'border .25s ease-out',
    padding: '0 25px',
    cursor: 'pointer',

    '&, &:visited': {
      textDecoration: 'none',
      color: theme.palette.grey[900],
    },

    '&:focus': {
      outline: 0,
    },

    '&:hover,&:focus,&.active': {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },

    '&.nav-logo': {
      padding: '0',
      svg: {
        flexShrink: 0,
        width: '178px',
      },
    },
  }))

  return <StyledNavLink className={`${className} ${active}`} {...linkProps} />
}

const BlurBackdrop = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backdropFilter: 'blur(2px)',
    }}
  />
)

interface MpHeaderBarProps {
  home: string
  links: any[]
  anchor: 'top' | 'right' | 'bottom' | 'left'
}
export const MpHeaderBar = (props: MpHeaderBarProps) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const [open, setOpen] = useState(false)

  return (
    <MpMenuBox component="nav" className="mp-main-menu" {...props}>
      <BlurBackdrop />
      <MpAppBar position="static">
        <MpToolbar>
          <Box>
            <MpNavLink
              aria-label="Homepage"
              className="nav-logo"
              onClick={() => {
                navigate(props.home)
              }}>
              <MpSvgIcon icon="mePrismColorHorz" />
            </MpNavLink>
          </Box>
          <LinkBox>
            {isDesktop &&
              props.links.map(({ key, to, text, component: Component }) => {
                if (Component) return <Component key={key} />
                return (
                  <MpNavLink
                    key={key}
                    onClick={() => {
                      navigate(to)
                    }}
                    variant="body1">
                    {text}
                  </MpNavLink>
                )
              })}
            {!isDesktop && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="Open Menu"
                onClick={() => setOpen(!open)}>
                <MenuIcon />
              </IconButton>
            )}
          </LinkBox>
          {!isDesktop && (
            <Drawer
              anchor={props.anchor}
              open={open}
              onClose={() => setOpen(false)}>
              <List>
                {props.links.map(({ key, to, text, component: Component }) => {
                  return (
                    <ListItem key={key}>
                      {Component ? (
                        <Component />
                      ) : (
                        <MpNavLink
                          style={{
                            height: '40px',
                            width: '100%',
                          }}
                          variant="body1"
                          onClick={() => {
                            navigate(to)
                          }}>
                          {text}
                        </MpNavLink>
                      )}
                    </ListItem>
                  )
                })}
              </List>
            </Drawer>
          )}
        </MpToolbar>
      </MpAppBar>
    </MpMenuBox>
  )
}

MpHeaderBar.defaultProps = {
  home: '/',
  links: [
    {
      key: 'logout',
      to: '/logout',
      text: 'Logout',
    },
  ],
  anchor: 'left',
}
