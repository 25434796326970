import React, { useEffect, useState } from 'react'
import { prettyPrintTimeSince } from '@meprism/shared/src/utils/dateHelpers'
import { useAppDispatch, useAppSelector } from '../../../redux/storeExports'
import {
  backFillBrokerDetails,
  PeopleFinderSelectors,
  selectLastScanDate,
} from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import Typography from '@mui/material/Typography/Typography'
import { fonts } from '../../../theme/theme'
import Box from '@mui/material/Box/Box'
import scanningAnimation from '@meprism/shared/src/assets/lottie/scan.gif'
import { Broker } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderTypes'
import { addAppListener } from '../../../redux/listenerMiddleware'
import shield from '@meprism/shared/src/assets/icons/business_userscan.svg'

export const ScanWidget = () => {
  const peopleFinderProfile = useAppSelector(
    PeopleFinderSelectors.selectPeopleFinderProfile,
  )
  const isScanActive = useAppSelector(PeopleFinderSelectors.selectIsScanActive)
  const lastScanComplete = useAppSelector(selectLastScanDate)
  const dispatch = useAppDispatch()
  const [lastBroker, setLastBroker] = useState<Broker | undefined>(undefined)
  useEffect(() => {
    const unsubscribe = dispatch(
      addAppListener({
        actionCreator: backFillBrokerDetails.fulfilled,
        effect: (action) => {
          setLastBroker(action.payload.summary)
        },
      }),
    )
    return unsubscribe
  }, [dispatch])

  if (isScanActive) {
    return (
      <Box
        sx={{
          alignItems: 'flex-end',
          flexDirection: 'column',
          display: 'flex',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <img src={scanningAnimation} width='24px'/>
          <Typography
            variant={'body2'}
            sx={{
              color: 'caption',
              marginLeft: '10px',
              display: {
                sm: 'block',
                xs: 'none',
              },
            }}>
            Scanning in progress:
          </Typography>
          {lastBroker?.data_broker && (
          <Typography
           sx={{ marginLeft: '10px'}}
            variant={'caption'}
          >
            Record found on {lastBroker?.data_broker}
          </Typography>
        )}
        </Box>
      </Box>
    )
  }

  return (
    <>
      {lastScanComplete && (
        <>
          <Box
        sx={{
          alignItems: 'flex-end',
          flexDirection: 'column',
          display: 'flex',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
         <img src={shield} alt="Icon" style={{ width: '24px', height: '24px', display: 'block', margin: 'auto',marginRight:'10px' }} />
        <Typography
          variant={'caption'}
          sx={{
            display: {
              xs: 'none',
              sm: 'block',
            },
          }}>
        
          Scan complete:{' '}
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 'bold', fontFamily: fonts.Jakarta.Bold }}>
            {peopleFinderProfile?.stats?.brokers_found ?? 0} records
          </Typography>{' '}
          found {prettyPrintTimeSince(new Date(lastScanComplete))}
        </Typography></Box></Box></>
      )}
    </>
  )
}
