import React from 'react'
import { ThemeProvider } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { theme } from '../../../theme/DashboardTheme'
import { ScanWidget } from '../../Views/HomeViews/ScanWidget'

interface HeaderCardProps {
  count:string
}

const ScannedSites: React.FC<HeaderCardProps> = ({ }) => {
  return (
    <ThemeProvider theme={theme}>
    <Box display="flex" alignItems="center" bgcolor={'#fff'} borderRadius={3} p={2}>
      <Typography variant="h6" sx={{ marginLeft: 1 }}>
      <ScanWidget />
      </Typography>
    </Box></ThemeProvider>
  )
}

export default ScannedSites