import React, { useEffect, useState } from 'react'
import PasswordField from '../shared/atom/PasswordField'
import ValidationHint from '../shared/atom/MpValidationHint'
import { Button, Container, Box } from '../shared/MaterialExports/index'
import { useLocation, useNavigate } from 'react-router-dom'
import { MpTypography } from '../shared'
import { Logger } from '@meprism/app-utils'
import { AuthLoader } from '../gateways/AuthLoader'
import { fonts } from '../../theme/OnboardingTheme'

export interface ChangePasswordRouteParam {
  username: string
}

const minPasswordLength = 10

const ChangePassword = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { username } = state as ChangePasswordRouteParam
  const email = username
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const [shouldDisplayPasswordInfo, setShouldDisplayPasswordInfo] =
    useState<boolean>(false)
  const [shouldShowMatchingPasswords, setShouldShowMatchingPasswords] =
    useState<boolean>(false)
  const [hasSufficientLength, setHasSufficientLength] = useState<boolean>(false)
  const [hasUpperCase, setHasUpperCase] = useState<boolean>(false)
  const [hasSymbol, setHasSymbol] = useState<boolean>(false)
  const [hasMatchingPassword, setHasMatchingPassword] = useState<boolean>(true)
  const isPasswordValid =
    hasSufficientLength && hasUpperCase && hasSymbol && hasMatchingPassword

  useEffect(() => {
    setHasSufficientLength(password.length >= minPasswordLength)
    setHasUpperCase(new RegExp(/[A-Z]/).test(password))
    setHasSymbol(new RegExp(/[^A-Za-z0-9]/).test(password))
    setHasMatchingPassword(password === confirmPassword)
  }, [password, confirmPassword])

  const onPasswordReset = async () => {
    try {
      navigate('/verifyotp', {
        state: { actionType: 'CHANGE_PASSWORD', username: email, password },
      })
    } catch (error) {
      Logger.error(`Error in navigation from changePassword to otp ${error}`)
    }
  }

  return (
    <AuthLoader>
      <Container fixed maxWidth={'sm'}>
        <Box
          mt="10px"
          display="flex"
          flexDirection="column"
          alignItems="left"
          justifyContent="left">
          <MpTypography my={3} variant={'subheader'}>
            Enter a new password for {email}
          </MpTypography>
          <PasswordField
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onFocus={() => {
              setShouldDisplayPasswordInfo(true)
            }}
            placeholder={'Password'}
            variant={'outlined'}
            sx={{
              width:'100%',
              margin:1
            }}
            autoComplete="new-password"
          />
          <PasswordField
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            onFocus={() => {
              setShouldShowMatchingPasswords(true)
            }}
            placeholder="Confirm Password"
            variant={'outlined'}
            sx={{
              width:'100%',
              margin: 1,
            }}
            autoComplete="new-password"
          />
          <Box sx={{ minHeight: shouldDisplayPasswordInfo ? 100:50 }}>
            {shouldDisplayPasswordInfo && (
              <Box>
                <ValidationHint
                  isValid={hasSufficientLength}
                  text={`At least ${minPasswordLength} characters`}
                />
                <ValidationHint
                  isValid={hasUpperCase}
                  text={'Contains at least one upper case letter'}
                />
                <ValidationHint
                  isValid={hasSymbol}
                  text={'Contains number or symbol'}
                />
                {shouldShowMatchingPasswords && (
                  <ValidationHint
                    isValid={hasMatchingPassword}
                    text={'Passwords match'}
                  />
                )}
              </Box>
            )}
          </Box>
          <Box
  sx={{
    display: 'flex',
    justifyContent: 'space-between',
    mt: 1,
    mb: 2,
    flexDirection: ['column', 'row'],
    width: '100%', // Set the container to 100% width
  }}
>
  <Button
    variant={'contained'}
    disabled={!isPasswordValid}
    sx={{
      minWidth: '180px',
      width: '100%', 
      mb: [2, 0],
      mr: [0, '10px'], 
    }}
    onClick={onPasswordReset}
  >
    Reset Password
  </Button>
  <Button
    color={'darkPurple'}
    onClick={() => navigate(-1)}
    sx={{
      background: '#1A1B20',
      border: '2px solid #C67EFF',
      fontFamily: fonts.Inter.SemiBold,
      color: '#C67EFF',
      minWidth: '180px',
      width: '100%', 
      '&.Mui-disabled': {
        background: '#426BF0',
        color: '#C67EFF',
      },
      ':hover': {
        background: '#1A1B20',
        color: '#C67EFF',
      },
    }}
  >
    Change Email
  </Button>
</Box>
        </Box>
      </Container>
    </AuthLoader>
  )
}

export default ChangePassword
