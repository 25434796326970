import React from 'react'
import { MpButton } from '../..'
import { ButtonProps } from '@mui/material/Button/Button'
import { fonts } from '../../../../theme/theme'

export const MpSecondaryButton = (props: ButtonProps) => {
  const { sx, ...rest } = props

  return (
    <MpButton
      sx={{
        ...sx,
        ...fonts.Jakarta.Bold,
        color: 'primary.main',
        bgcolor: 'purple.50',
        textTransform: 'none',
      }}
      {...rest}
    />
  )
}
