import { Box, ButtonBase, Typography } from '@mui/material'
import React from 'react'
import MpSvgIcon from '../atom/MpSvgIcon'

const MpDownloadMobileWidget = () => {
  return (
    <Box
      sx={{
        border: 1,
        borderRadius: '10px',
        borderColor: '#EDEDED',
        padding: '40px',
        marginTop: '40px',
      }}>
      <Typography variant={'h2'}>Do more with the mobile app</Typography>
      <Typography variant={'body2'}>
        Download our mobile app to get access to Social Media Privacy controls
        and stop targetred ads from following you around the internet.
      </Typography>
      <Box sx={{ marginTop: '20px' }}>
        <ButtonBase
          href={'https://apps.apple.com/us/app/meprism-privacy/id1658583655'}>
          <MpSvgIcon icon={'iosButton'} />
        </ButtonBase>
        <ButtonBase
          sx={{ marginLeft: '20px' }}
          href={
            'https://play.google.com/store/apps/details?id=com.meprism.privacy'
          }>
          <MpSvgIcon icon={'googleButton'} />
        </ButtonBase>
      </Box>
    </Box>
  )
}

export default MpDownloadMobileWidget
