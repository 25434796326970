import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { IsUserAuthenticatedSelector } from '../../redux/authentication/AuthenticationSlice'
import { logOutUser } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import MpLoadingIcon from '../shared/atom/MpLoadingIcon'
import { Container } from '../shared/MaterialExports'
import { useAppDispatch } from '../../app/hooks'

const Logout = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isUserAuthenticated = IsUserAuthenticatedSelector()

  useEffect(() => {
    dispatch(logOutUser())
  }, [dispatch])

  useEffect(() => {
    if (!isUserAuthenticated) {
      navigate('/login')
    }
  }, [isUserAuthenticated, navigate])

  return (
    <Container fixed maxWidth={'sm'}>
      <MpLoadingIcon positioned />
    </Container>
  )
}

export default Logout
