import { Button, SxProps, Typography } from '@mui/material'
import React from 'react'
import { fonts } from '../../../theme/theme'
import MpSvgIcon from '../atom/MpSvgIcon'
import { Link } from 'react-router-dom'
import { Box } from '../MaterialExports'

interface MpSubscriptionWidgetProps {
  sx?: SxProps
}

const MpSubscriptionWidget = (props: MpSubscriptionWidgetProps) => {
  return (
    <Link to={'/subscribe'}>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          backgroundColor: '#3A155A',
          alignItems: 'center',
          borderRadius: 5,
          padding: '20px',
          ...props.sx,
        }}>
        <MpSvgIcon
          icon={'trashCan'}
          width={'50px'}
          height={'50px'}
          color={'white'}
        />
        <Typography
          variant={'body1'}
          sx={{
            color: 'white.main',
            fontFamily: fonts.Jakarta.SemiBold,
            marginLeft: '10px',
          }}>
          Subscribe to remove your information
        </Typography>

        <Button
          variant="outlined"
          color={'white'}
          sx={{ paddingLeft: '30px', paddingRight: '30px' }}>
          {' '}
          Subscribe{' '}
        </Button>
      </Box>
    </Link>
  )
}

export default MpSubscriptionWidget
