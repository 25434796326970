import { Button, SxProps } from '@mui/material'
import React from 'react'
import { Box } from '../MaterialExports'
import MpSvgIcon from './MpSvgIcon'
import { MpTypography } from './MpTypography'
import { NavLink } from 'react-router-dom'
import { Broker } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderTypes'
import { putDataBrokerAccuracy } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import { useAppDispatch } from '../../../app/hooks'

interface MpScanResultProps {
  onPress?: () => void
  broker: Broker
  details?: (broker: Broker) => string
  sxOverride?: SxProps
  inaccurate?: boolean
  accordion?: boolean
}

const MpScanResult = (props: MpScanResultProps) => {
  const dispatch = useAppDispatch()
  const onClick = () => {
    if (props.onPress) {
      props.onPress()
    }
  }
  const onAccuracyPress = (event: any, value: boolean) => {
    event.preventDefault()
    dispatch(
      putDataBrokerAccuracy({
        scan_result_id: props.broker.scan_result_id,
        accurate: value,
      }),
    )
  }
  return (
    <NavLink
      to={
        props.inaccurate
          ? `/home/inaccurate/broker/${props.broker.scan_result_id}`
          : `/home/broker/${props.broker.scan_result_id}`
      }>
      {({ isActive }) => (
        <Box
          onClick={onClick}
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: isActive ? '#9135E0' : 'white.main',
            py: '20px',
            pr: '20px',
            pl: '35px',
            borderRadius: '20px',
            justifyContent: 'space-between',
            border: `1px solid ${isActive ? '#9135E0' : '#E0E0E0'}`,
            ...props.sxOverride,
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Box sx={{ minWidth: 0 }}>
              {!props.accordion && (
                <MpTypography
                  noWrap
                  variant={'body1'}
                  sx={{
                    fontWeight: 600,
                    color: isActive ? 'white.main' : '#3A155A',
                    textAlign: 'start',
                  }}>
                  {props.broker.data_broker}
                </MpTypography>
              )}
              <MpTypography
                noWrap
                variant={'body2'}
                sx={{
                  color: isActive ? 'white.main' : '#767676',
                  textAlign: 'start',
                }}>
                {props.details ? props.details(props.broker) : ''}
              </MpTypography>
              {props.broker.status === 'removed' ? (
                <MpTypography
                  variant={'body2'}
                  color={isActive ? 'white.main' : 'success.main'}
                  sx={{ textAlign: 'start' }}>
                  Removed
                </MpTypography>
              ) : (
                <Button
                  variant={'text'}
                  onClick={(event) =>
                    onAccuracyPress(event, !props.broker.accurate)
                  }
                  color={isActive ? 'white' : 'primary'}
                  sx={{ textAlign: 'start', alignSelf: 'flex-start' }}>
                  {props.broker.accurate ? 'Not me' : 'This is me'}
                </Button>
              )}
            </Box>
            <MpSvgIcon
              icon={'collapse'}
              color={isActive ? '#FFFFFF' : '#919191'}
            />
          </Box>
        </Box>
      )}
    </NavLink>
  )
}

export default MpScanResult
