import { TypographyProps } from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'
import React from 'react'
import MpSvgIcon, { MpSvgIconProps } from '../atom/MpSvgIcon'
import { MpTypography } from '../atom/MpTypography'

interface MpIconAndTextProps extends MpSvgIconProps {
  text: string
  textImageSpace: number
  typographyProps?: TypographyProps
  boxProps?: BoxProps
}
const MpIconAndText = (props: MpIconAndTextProps) => {
  const { text, typographyProps, textImageSpace, boxProps, ...otherProps } =
    props
  return (
    <Box display="flex" alignItems="center" {...boxProps}>
      <MpSvgIcon {...otherProps} />
      <MpTypography ml={`${textImageSpace}px`} {...typographyProps}>
        {text}
      </MpTypography>
    </Box>
  )
}

export default MpIconAndText
