import React from 'react'
import MpLoader from '../../../assets/images/mp_loading.gif'
import { Box } from '../MaterialExports'

interface MpLoadingIconProps {
  positioned?: boolean
}
const MpLoadingIcon = (props: MpLoadingIconProps) => {
  return props.positioned ? (
    <Box
      mt="60px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center">
      <img src={MpLoader} alt="loading..." />
    </Box>
  ) : (
    <img src={MpLoader} alt="loading..." />
  )
}

export default MpLoadingIcon
