import React from 'react'
import Typography from '@mui/material/Typography'
import { useAppSelector } from '../../../redux/storeExports'
import {
  selectIsScanActive,
  selectLastScanDate,
} from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import { Link } from 'react-router-dom'
import { BrokerSkeleton } from './BrokerSkeleton'

interface EmptyBrokerListProps {
  inaccurate?: boolean
}

export const EmptyBrokerList = (props: EmptyBrokerListProps) => {
  const skels = new Array(5).fill(0)
  const lastScanComplete = useAppSelector(selectLastScanDate)

  const hasCompletedScan = !!lastScanComplete
  const isScanActive = useAppSelector(selectIsScanActive)

  if (props.inaccurate) {
    return (
      <>
        <Typography variant={'body1'}>
          {`You have not flagged any scan results as inaccurate.`}
        </Typography>
      </>
    )
  }

  if (hasCompletedScan) {
    return (
      <>
        <Typography variant={'body1'}>
          {`We didn't find any data brokers that had your information.`}
        </Typography>
        <Typography variant={'body1'}>
          {`Sometimes brokers sell your information under different names and/or locations. You can try `}
          <Link to={'/settings'}>
            <Typography
              variant={'body1'}
              color={'primary'}
              sx={{ textDecoration: 'underline' }}>
              editing your profile
            </Typography>
          </Link>
          {' to try to find more results.'}
        </Typography>
        <Typography variant={'body1'}>
          Alternatively, this could be very good news! Perhaps you have been
          successful in minimizing your digital footprint.
        </Typography>
        <Typography>
          If you believe this result to be in error, you can use the buttons in
          the footer to contact support.
        </Typography>
      </>
    )
  }

  return (
    <>
      <Typography variant={'body2'}>
        Your scan is {isScanActive ? 'running' : 'about to start'}. You will see
        the results below.
      </Typography>
      {skels.map((_, index) => (
        <BrokerSkeleton key={index} />
      ))}
    </>
  )
}
