import React, { Suspense } from 'react'
import { Await, defer, Navigate, useLoaderData } from 'react-router-dom'
import { store } from '../../redux/store'
import { setUpOauthListener } from '../../redux/extraListeners'
import { LoadingFallback } from '../shared/atom/LoadingFallback'

export const oauthLoader = async () => {
  const destinationPromise = store
    .dispatch(setUpOauthListener())
    .catch(() => '/login')
  return defer({ destination: destinationPromise })
}

const ValidateAuthStatus = () => {
  const data = useLoaderData() as any

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Await
        resolve={data.destination}
        errorElement={<Navigate to={'/login'} replace />}>
        {(destination) => <Navigate to={destination} replace />}
      </Await>
    </Suspense>
  )
}

export default ValidateAuthStatus
