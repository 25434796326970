import React from 'react'
import {
  ToastRefType,
  ToastType,
} from '@meprism/shared/src/services/ToastInterface'
import Box from '@mui/material/Box'
import { toast } from 'react-toastify'
import { fonts, MpColorPalette } from '../../theme/theme'
import Typography from '@mui/material/Typography'
import MpSvgIcon, { MpIconList } from '../shared/atom/MpSvgIcon'
import './toastStyle.css'

const getIcon = (type: ToastType | 'default'): MpIconList => {
  switch (type) {
    case 'info':
      return 'lightbulb'
    case 'success':
      return 'success'
    case 'warning':
      return 'warning'
    case 'error':
      return 'error'
    case 'default':
    default:
      return 'lightbulb'
  }
}

type WebToastProps = {
  text1?: string
  text2?: string
  toastType: ToastType
}

export const WebToastComponent = ({ text1, text2 }: WebToastProps) => {
  return (
    <Box>
      <Typography variant={'body2'} sx={{ fontFamily: fonts.Jakarta.SemiBold }}>
        {text1 || ''}
      </Typography>
      <Typography variant={'caption'}>{text2 || ''}</Typography>
    </Box>
  )
}

export const Toast: ToastRefType = {
  show: ({ type = 'info', text1, text2 }) => {
    toast(
      (toastProps) => (
        <WebToastComponent
          toastType={type}
          text1={text1}
          text2={text2}
          {...toastProps}
        />
      ),
      {
        type,
        icon: (
          <MpSvgIcon icon={getIcon(type)} color={MpColorPalette[type].main} />
        ),
      },
    )
  },
  // @TODO: this
  hide: () => {},
}
