// noinspection JSConstantReassignment
// yea you know this is gonna be a train wreck when we start here

import { CognitoUser } from '@aws-amplify/auth'
import React, {
  createContext,
  createRef,
  PropsWithChildren,
  useContext,
  useImperativeHandle,
  useState,
} from 'react'

// Why is this file here?
// I am ashamed BUT
// - We need to be able to access the current CognitoUser anywhere, even outside components
// - The current CognitoUser is non-serializeable, state dependent, and needs to be available globally
// - Hence I can't put it in Redux
// - Amplify's own handling uses lots of private methods to say "give me the current user"
// - I am NOT trying to do that directly, but when we for example get a user who logs in and just needs confirmation
// - I just want to store that value, be able to use/update it anywhere.

export type AuthContextType = {
  user: CognitoUser | null | undefined
  setUser: React.Dispatch<React.SetStateAction<CognitoUser | null>>
}

// Lord forgive me, I know not what I do
export const GlobalAuthContextRef = createRef<AuthContextType>()

let userBeforeRender: CognitoUser | null = null
const preRenderContextValue = {
  user: null,
  setUser: (user: CognitoUser | null) => {
    userBeforeRender = user
  },
}

// React's refs are literally objects - we set the initial value here once safely, and we let the component below take
// over. This means that this is relatively functional even when the context has not rendered - "set" actions
// take effect on the default value of the setState below...
// @ts-ignore
GlobalAuthContextRef.current = preRenderContextValue

export const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: (user) => {
    if (typeof user === 'function') {
      userBeforeRender = user(null)
    } else {
      userBeforeRender = user
    }
  },
})

const getInitialContext = () => userBeforeRender

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<CognitoUser | null>(getInitialContext)

  const value = { user, setUser }

  // we can make this context value globally available...
  // shield thine eyes
  useImperativeHandle(GlobalAuthContextRef, () => value)

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => useContext(AuthContext)
