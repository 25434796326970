import { PeopleFinderSelectors } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAppSelector } from '../../../redux/storeExports'
import MpSvgIcon from '../atom/MpSvgIcon'

const NotMeCard = () => {
  const brokers = useAppSelector(
    PeopleFinderSelectors.selectGroupedInaccurateBrokers,
  )
  return (
    <NavLink to={`/home/inaccurate`}>
      <Paper
        sx={{
          borderRadius: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px',
          marginTop: '40px',
          marginBottom: '40px',
        }}>
        <Typography>Not Me</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <Typography
            sx={{
              border: '1px solid gray',
              paddingLeft: '10px',
              paddingRight: '10px',
              borderRadius: 10,
            }}>
            {Object.entries(brokers).length}
          </Typography>
          <MpSvgIcon icon={'collapse'} />
        </Box>
      </Paper>
    </NavLink>
  )
}

export default NotMeCard
