import { useLocation, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import OtpInput from 'react-otp-input'
import {
  confirmSignUp,
  forgotPassword,
  resendSignup,
  submitForgotPassword,
} from '../../services/AuthenticationService'
import { Container, Box, Button, Typography } from '../shared/MaterialExports'
import { MpTypography } from '../shared'
import { AnalyticsService } from '../../services/AnalyticsService'
import { useAppDispatch } from '../../app/hooks'
import { AuthLoader } from '../gateways/AuthLoader'
import { Auth } from '@aws-amplify/auth'
import { Toast } from '../toast/toast'
import { signInWithEmail } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import useMediaQuery from '@mui/material/useMediaQuery'
import { theme } from '../../theme/OnboardingTheme'
import { useAuthContext } from '@meprism/shared/src/context/AuthContext'
export type OtpAction =
  | 'SIGNUP_EMAIL'
  | 'CHANGE_PASSWORD'
  | 'SIGNIN_SOFTWARE_TOKEN_MFA'

export interface OtpRouteParam {
  username?: string
  password?: string
  actionType: OtpAction
  from?: Location
  destination?: string
}

const LoginOTP = () => {
  const { state } = useLocation()
  const from = (state as OtpRouteParam)?.from || '/'
  const { username, password, actionType, destination } = state as OtpRouteParam
  const [otp, setOtp] = useState<string>('')
  const [errorText, setErrorText] = useState<string>('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const authContext = useAuthContext()

  const getVerificationAction = (): (() => void) => {
    switch (actionType) {
      case 'SIGNUP_EMAIL':
        return async () => {
          try {
            await confirmSignUp(username as string, otp)
            await dispatch(
              signInWithEmail({ username, password } as {
                username: string
                password: string
              }),
            ).unwrap()
            navigate(from)
          } catch (error) {
            if (error instanceof Error) {
              switch (error.name) {
                case 'CodeMismatchException':
                  setErrorText(
                    'The code you have entered is incorrect. Please try again',
                  )
                  break
                case 'ExpiredCodeException':
                  await resendSignup(username as string)
                  setErrorText(
                    `The code you entered has expired. We have sent a new one to ${username}`,
                  )
                  break
                default:
                  setErrorText(
                    'An error occurred processing your request. Please try again later.',
                  )
                  AnalyticsService.error(
                    `Unhandled Authorization error: `,
                    error,
                  )
                  break
              }
            } else {
              AnalyticsService.error(`Unhandled Authorization error: `, error)
            }
          }
        }
      case 'CHANGE_PASSWORD':
        return async () => {
          try {
            await submitForgotPassword(
              username as string,
              otp as string,
              password as string,
            )
            await dispatch(
              signInWithEmail({ username, password } as {
                username: string
                password: string
              }),
            ).unwrap()
            navigate('/changePasswordSuccess', { state: from })
          } catch (error) {
            if (error instanceof Error) {
              switch (error.name) {
                case 'CodeMismatchException':
                  setErrorText(
                    'The code you have entered is incorrect. Please try again',
                  )
                  break
                case 'ExpiredCodeException':
                  await forgotPassword(username as string)
                  setErrorText(
                    `The code you entered has expired. We have sent a new one to ${username}`,
                  )
                  break
                case 'LimitExceededException':
                  setErrorText(
                    `Unfortunately, you have exceeded a limit on the number of failed login attempts. Please wait 15 minutes and try again.`,
                  )
                  AnalyticsService.error('CognitoLimit', error)
                  break
                default:
                  setErrorText(
                    'An error occurred processing your request. Please try again later.',
                  )
                  AnalyticsService.error(
                    `Unhandled Authorization error: `,
                    error,
                  )
                  break
              }
            } else {
              AnalyticsService.error(`Unhandled Authorization error: `, error)
            }
          }
        }
      case 'SIGNIN_SOFTWARE_TOKEN_MFA':
        return async () => {
          try {
            await Auth.confirmSignIn(
              authContext.user,
              otp,
              'SOFTWARE_TOKEN_MFA',
            )
            navigate(state.from || '/', { replace: true })
          } catch (error) {
            switch (error?.name) {
              case 'CodeMismatchException':
                setErrorText(
                  'The code you have entered is incorrect. Please try again',
                )
                break
              case 'ExpiredCodeException':
                navigate('/login', {
                  replace: true,
                  state: { from: state.from },
                })
                Toast.show({
                  type: 'error',
                  text1: 'Your code has expired. You must log in again',
                })
                break
              case 'LimitExceededException':
                setErrorText(
                  `Unfortunately, you have exceeded a limit on the number of failed login attempts. Please wait 15 minutes and try again.`,
                )
                AnalyticsService.error('CognitoLimit', error)
                break
              default:
                setErrorText(
                  'An error occurred processing your request. Please try again later.',
                )
                AnalyticsService.error(`Unhandled Authorization error: `, error)
                break
            }
          }
        }
    }
  }

  const onVerify = getVerificationAction()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <AuthLoader>
      <Container fixed maxWidth={'sm'}>
        <Box
          mt="30px"
          display="flex"
          flexDirection="column"
          alignItems="left"
          justifyContent="left">
          <Typography
            variant={'h2'}
            sx={{ marginTop: '50px', marginBottom: '10px' }}>
            Enter Account Verification Code
          </Typography>
          <MpTypography
            mt="40px"
            mb="40px"
            variant="subheader"
            textAlign="left">
            {`Enter the verification code sent to ${
              destination ?? username
            } in the field below.`}
          </MpTypography>
          <OtpInput
            value={otp}
            onChange={setOtp}
            inputStyle={{
              width: isMobile ? '16.666%' : '25%',
              height: isMobile ? '42px' : '60px',
              border: '2px solid #EFEDFDB2',
              borderRadius: '10px',
              background: '#1A1B20',
              color: '#ffffff',
            }}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
          {isMobile ? (
            <Box sx={{ minHeight: errorText ? 100 : 30 }}>
              <MpTypography color={'error'}>{errorText}</MpTypography>
            </Box>
          ) : (
            <Box sx={{ minHeight: 90 }}>
              <MpTypography color={'error'}>{errorText}</MpTypography>
            </Box>
          )}

          <Button onClick={onVerify} sx={{ my: 2, width: '100%' }}>
            Verify Code
          </Button>
        </Box>
      </Container>
    </AuthLoader>
  )
}

export default LoginOTP
