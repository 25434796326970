import { AlertColor } from '@mui/material/Alert'

type DateString = string

export enum ExportStatus {
  NEW = 'New',
  IN_PROCESS = 'In Process',
  READY = 'Ready',
  EXPIRED = 'Expired',
  FAILED = 'Failed',
}

export type ExportListResponse = {
  exports: ExportResponse[]
}

export type ExportResponse = {
  id: string
  create_time: DateString
  expiration_time: DateString
  categories?: string[]
  email?: string
  status: ExportStatus
  muid: string
}

export type DownloadProgress = {
  jobId: string
  contentLength: number
  bytesWritten: number
}

export interface AxiosProgressEvent {
  loaded: number
  total?: number
  progress?: number
  bytes: number
  rate?: number
  estimated?: number
  upload?: boolean
  download?: boolean
}

export type ExportDescription = {
  id: string
  createTime: DateString
  expirationTime: DateString
  categories?: string[]
  email?: string
  status: ExportStatus
  muid: string
  downloadProgress?: DownloadProgress
}

export type DownloadResponse = {
  url: string
}

export type ExportToast = {
  shown: boolean
  severity: AlertColor
  text: string
}

export type ExportSlice = {
  exports: ExportDescription[]
  loading: boolean
  toast?: ExportToast
}

export type DownloadFileProps = {
  id: string
}

export type DownloadStartPayload = { id: string } & DownloadProgress
