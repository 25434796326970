import { createAsyncThunk } from '@reduxjs/toolkit'
import { AnalyticsManagerInterface } from '../services/AnalyticsManager'
import { KeychainInterface } from '../services/KeychainInterface'
import { ToastRefType } from '../services/ToastInterface'
import { API, Hub } from 'aws-amplify'

export type APIInterface = typeof API
export type HubInterface = typeof Hub

type Platform = 'ios' | 'android' | 'web'

export type ConfigurationInterface = {
  PLATFORM: Platform
  WEBAPP_DOMAIN?: string
}

export interface BaseThunkExtra {
  AnalyticsManager: AnalyticsManagerInterface
  Keychain: KeychainInterface
  // Iaphub: IaphubInterface
  Toast: ToastRefType
  handleSupport: () => void
  API: APIInterface
  Hub: HubInterface
  Configuration?: ConfigurationInterface
}

export const createBaseAsyncThunk = createAsyncThunk.withTypes<{
  extra: BaseThunkExtra
}>()
