export const numberofDaysFromToday = (
  date: string | undefined | Date,
): number => {
  if (!date) {
    return 0
  }
  const DAY_IN_MS = 1000 * 60 * 60 * 24
  const today = new Date()
  const newDate = new Date(date)
  const diffInTime = newDate.getTime() - today.getTime()
  return Number((diffInTime / DAY_IN_MS).toFixed(0))
}
