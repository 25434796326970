import {
  Box,
  Collapse,
  TableBody,
  Grid,
  IconButton,
  Modal,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  useMediaQuery,
} from '@mui/material'
import React, { useState } from 'react'
import { fonts, theme } from '../../../theme/DashboardTheme'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material'
import closeicon from '@meprism/shared/src/assets/icons/successclose.png'
import { ThemeProvider } from '@emotion/react'
import {
  PeopleFinderSelectors,
  putDataBrokerAccuracy,
} from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/storeExports'
import Notme from './Notme'
import { MpTypography } from '../atom/MpTypography'

interface ExposedDataModalProps {
  open: boolean
  rows: Array<Record<string, any>>
  setOpen: (open: boolean) => void
}

const ExposedDataModal = (props: ExposedDataModalProps) => {
  const handleClose = () => {
    props.setOpen(false)
  }
  const brokerdata = props.rows.map((item, value) => {
    const hasPhones = item.detail?.phones[0]
    const firstname = item.detail?.first_name
    const middlename = item.detail?.middle_name
    const lastname = item.detail?.last_name
    const age = item.detail?.age
    const address = item.detail.addresses[0]
    const email = item.detail.emails[0]
    const cityAndState = `${address?.city}, ${address?.state}`
    const Relatives = item.detail.relatives
    return {
      id: value + 1,
      col1: firstname,
      col2: middlename,
      col3: lastname,
      col4: age,
      col5: cityAndState,
      col6: hasPhones,
      col7: Relatives,
      col8: email,
      col9: item.detail.status,
      col10: item.accurate,
      col11: item.scan_result_id,
    }
  })

  const dispatch = useAppDispatch()
  const [expandedRow, setExpandedRow] = useState<number | null>(null)

  const handleRowToggle = (rowId: number) => {
    setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId))
  }
  const broker = useAppSelector(PeopleFinderSelectors.selectBrokerDetails)

  const columns = [
    { key: 'col1', name: 'First Name', resizable: true },
    { key: 'col2', name: 'Middle Name', resizable: true, width: 100 },
    { key: 'col3', name: 'Last Name', resizable: true, width: 100 },
    { key: 'col4', name: 'Age', resizable: true, width: 100 },
    { key: 'col5', name: 'Address', resizable: true, width: 100 },
    { key: 'col6', name: 'Phones', resizable: true, width: 100 },
    { key: 'col7', name: 'Relatives', resizable: true, width: 100 },
    { key: 'col8', name: 'Emails', resizable: true, width: 100 },
    { key: 'col9', name: 'Action', resizable: true, width: 150 },
    { key: 'col10', name: '', resizable: true },
    { key: 'col11', name: '', resizable: true },
  ]
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ThemeProvider theme={theme}>
      <Modal open={props.open} onClose={handleClose}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{
            outline: 'none',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: !isMobile ? '820px' : '80%',
            background: '#fff',
            p: 10,
            borderRadius: '10px',
          }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: -60,
              right: 10,
              zIndex: 1,
              color: '#ffffff',
            }}>
            <img src={closeicon} style={{ width: '42px' }} alt="close" />
          </IconButton>
          <Box
            sx={{
              width:'100%',
              ...(brokerdata.length > 5 &&{
                height: '400px',
                overflowY: 'auto',
              }),
            }}>
            {brokerdata.map((rownew) => (
              <Box key={`box-${rownew.id}`} mt={2} width="100%">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #ddd"
                  bgcolor="#C67EFF1A"
                  sx={{ cursor: 'pointer' }}
                  border="1px solid rgba(198, 126, 255, 0.50)"
                  onClick={() => handleRowToggle(rownew.id)}
                  borderRadius={
                    expandedRow === rownew.id
                      ? '20px 20px 0 0'
                      : '0 0 20px 20px'
                  }>
                  <Typography
                    variant="body5"
                    ml={5}>{`Record ${rownew.id}`}</Typography>
                  <IconButton
                    aria-expanded={expandedRow === rownew.id}
                    aria-label="show more">
                    {expandedRow === rownew.id ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Box>
                <Collapse
                  key={`collapse-${rownew.id}`}
                  in={expandedRow === rownew.id}
                  timeout="auto"
                  unmountOnExit>
                  {!isMobile ? (
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {columns.map(
                              (col) =>
                                col.key != 'col10' &&
                                col.key != 'col11' && (
                                  <TableCell
                                    style={{
                                      width: col.width,
                                      borderBottom: '1px solid #9135E0',
                                      ...fonts.Inter.SemiBold,
                                      fontSize: '12px',
                                    }}
                                    key={`${col.key}-${rownew.id}`}>
                                    {col.name}
                                  </TableCell>
                                ),
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            {columns.map((col) => (
                              <TableCell
                                key={`${col.key}-${rownew.id}`}
                                sx={{
                                  fontSize: '12px',
                                  ...fonts.Inter.Medium,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}>
                                {col.key !== 'col10' &&
                                  col.key !== 'col11' &&
                                  col.key !== 'col9' &&
                                  rownew[col.key as keyof typeof rownew] && (
                                    <div>
                                      {Array.isArray(
                                        rownew[col.key as keyof typeof rownew],
                                      )
                                        ? rownew[
                                            col.key as keyof typeof rownew
                                          ].map(
                                            (value: string, index: number) => (
                                              <React.Fragment key={index}>
                                                {value}
                                                <br />
                                              </React.Fragment>
                                            ),
                                          )
                                        : rownew[
                                            col.key as keyof typeof rownew
                                          ]}
                                    </div>
                                  )}

                                {col.key === 'col9' &&
                                  (rownew[col.key as keyof typeof rownew] ===
                                  'removed' ? (
                                    <MpTypography
                                      variant={'body2'}
                                      sx={{ textAlign: 'start' }}>
                                      Removed
                                    </MpTypography>
                                  ) : (
                                    <>
                                      <Notme
                                        onAccuratePress={() =>
                                          dispatch(
                                            putDataBrokerAccuracy({
                                              scan_result_id: rownew.col11,
                                              accurate: rownew.col10,
                                            }),
                                          )
                                        }
                                        validated={broker?.summary.accurate}
                                      />
                                    </>
                                  ))}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridGap: '8px',
                      }}>
                      {columns.map((col) => (
                        <div
                          key={`${col.key}-${rownew.id}`}
                          style={{
                            backgroundColor: '#fff',
                            padding: '10px',
                            borderRadius: '10px',
                          }}>
                          {
                            <Typography
                              variant="h5"
                              style={{
                                ...fonts.Inter.SemiBold,
                                fontSize: '12px',
                              }}>
                              {col.name}
                              {col.key != 'col10' && col.key != 'col11' && (
                                <span>:</span>
                              )}
                            </Typography>
                          }
                          <Typography
                            variant="label"
                            style={{
                              ...fonts.Inter.Medium,
                              fontSize: '12px',
                            }}>
                            {col.key !== 'col10' &&
                              col.key !== 'col11' &&
                              col.key !== 'col9' &&
                              rownew[col.key as keyof typeof rownew] && (
                                <div>
                                  {Array.isArray(
                                    rownew[col.key as keyof typeof rownew],
                                  )
                                    ? rownew[
                                        col.key as keyof typeof rownew
                                      ].map((value: string, index: number) => (
                                        <React.Fragment key={index}>
                                          {value},
                                          <br />
                                        </React.Fragment>
                                      ))
                                    : rownew[col.key as keyof typeof rownew]}
                                </div>
                              )}
                          </Typography>
                          {col.key === 'col9' &&
                            (rownew[col.key as keyof typeof rownew] ===
                            'removed' ? (
                              <MpTypography
                                variant="subtitle2"
                                sx={{ textAlign: 'start' }}>
                                Removed
                              </MpTypography>
                            ) : (
                              <>
                                <Notme
                                  onAccuratePress={() =>
                                    dispatch(
                                      putDataBrokerAccuracy({
                                        scan_result_id: rownew.col11,
                                        accurate: rownew.col10,
                                      }),
                                    )
                                  }
                                  validated={broker?.summary.accurate}
                                />
                              </>
                            ))}
                        </div>
                      ))}
                    </div>
                  )}
                </Collapse>
              </Box>
            ))}
          </Box>
        </Grid>
      </Modal>
    </ThemeProvider>
  )
}

export default ExposedDataModal
