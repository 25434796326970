import { Container } from '../shared/MaterialExports'
import React, { useEffect, useCallback, useMemo } from 'react'
import { MpButton, MpTypography } from '../shared'
import { MpHeaderBar } from '../shared/organism/MpHeaderBar'
import Stack from '@mui/material/Stack'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import {
  ActiveExportsSelector,
  downloadExport,
  ExpiredExportsSelector,
  ExportActions,
  ExportToastSelector,
  fetchExport,
} from '../../redux/export/ExportSlice'
import { ExportCard } from './ExportCard'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

export const ExportData = () => {
  const dispatch = useDispatch<AppDispatch>()
  const activeExports = ActiveExportsSelector()
  const expiredExports = ExpiredExportsSelector()
  const toast = ExportToastSelector()

  useEffect(() => {
    dispatch(fetchExport())
  }, [dispatch])

  const onPressBuilder = useCallback(
    (id: string) => () => {
      dispatch(
        downloadExport({
          id,
        }),
      )
    },
    [dispatch],
  )

  const activeExportsCards = useMemo(
    () =>
      activeExports.map(
        ({ createTime, status, expirationTime, downloadProgress, id }) => (
          <ExportCard
            createDate={new Date(createTime)}
            status={status}
            expireDate={new Date(expirationTime)}
            downloadProgress={downloadProgress}
            onPress={onPressBuilder(id)}
            key={id}
          />
        ),
      ),
    [activeExports, onPressBuilder],
  )

  const expiredExportCards = useMemo(
    () =>
      expiredExports.map(
        ({ createTime, status, expirationTime, downloadProgress, id }) => (
          <ExportCard
            createDate={new Date(createTime)}
            status={status}
            expireDate={new Date(expirationTime)}
            downloadProgress={downloadProgress}
            onPress={onPressBuilder(id)}
            key={id}
          />
        ),
      ),
    [expiredExports, onPressBuilder],
  )

  // copypasta from https://mui.com/material-ui/react-snackbar/ don't @ me
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    dispatch(ExportActions.hideToast())
  }

  return (
    <Container fixed maxWidth="md">
      <MpHeaderBar />
      <Stack
        maxWidth="sm"
        direction={'column'}
        sx={{ alignItems: 'center', mx: 'auto' }}
        spacing={8}>
        <MpTypography variant="body1">
          You have a right to see the personal data that companies have
          collected from you. Even though mePrism is your advocate, we still
          believe you should have easy access to see all the data stored in your
          Data Bank.
        </MpTypography>

        <MpButton variant="contained">Download a Copy of Your Data</MpButton>

        <MpTypography variant="h3">Available Downloads</MpTypography>
        {activeExportsCards}
        {expiredExportCards?.length > 0 && (
          <MpTypography variant="h3">Expired Downloads</MpTypography>
        )}
        {expiredExportCards?.length > 0 && expiredExportCards}
      </Stack>
      <Snackbar
        open={toast?.shown}
        autoHideDuration={6000}
        onClose={handleClose}>
        <Alert
          variant="filled"
          onClose={handleClose}
          severity={toast?.severity}
          sx={{ width: '100%' }}>
          {toast?.text}
        </Alert>
      </Snackbar>
    </Container>
  )
}
