import Container from '@mui/material/Container'
import React, { useEffect } from 'react'
import MpFooter from '../../shared/organism/MpFooter'
import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom'
import { Logger } from '@meprism/app-utils'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

export const RootErrorFallback = () => {
  const error = useRouteError()

  useEffect(() => {
    // @TODO: Get a louder and more structured way to handle this
    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        // This is basically the only case where we don't want to scream
        Logger.info(`Got 404 response for ${error.data}`)
      } else {
        Logger.error(
          `Unhandled route error: ${error.status} ${error.statusText} ${error.data}`,
        )
      }
    } else {
      Logger.error(`Unhandled fatal error ${error} ${(error as any)?.message}`)
    }
  }, [error])

  const header =
    isRouteErrorResponse(error) && error.status === 404
      ? 'We could not find that page'
      : 'Sorry, an unexpected error has occurred.'

  const body =
    isRouteErrorResponse(error) && error.status === 404
      ? error.statusText
      : 'A log of this incident has been sent to the team, but feel free to contact support for further assistance. We appreciate your patience'

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}>
      <Stack
        spacing={10}
        sx={{ textAlign: 'center', mt: 20, px: 10, maxWidth: 'md' }}>
        <Typography variant={'h1'}>Error</Typography>
        <Typography variant={'body1'}>{header}</Typography>
        <Typography variant={'body2'}>{body}</Typography>
        <Link to={'/'}>
          <Button variant={'contained'} color={'darkPurple'}>
            Go Home
          </Button>
        </Link>
      </Stack>
      <MpFooter />
    </Container>
  )
}
