import {  Button, Grid, IconButton, Modal, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import person from '../../../assets/images/successloginImg.png'
import closeicon from '../../../assets/images/successclose.png'
import { useNavigate } from 'react-router-dom'
import { theme } from '../../../theme/OnboardingTheme'
interface SuccessLoginModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}


const SuccessLoginModal = (props: SuccessLoginModalProps) => {
  const navigate=useNavigate()
  const handleClose = () => {
    props.setOpen(false)
    navigate('/', { replace: true })
  }
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Modal open={props.open} onClose={handleClose}>
       <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{outline: 'none',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: !isMobile ?'720px':'80%', background:'#1D1A2C',p:10,borderRadius:'10px' }}
        >
          <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: -60,
            right: 10,
            zIndex: 1, // Adjust the zIndex to make sure it's above other elements
            color: '#ffffff',
          }}
        >
          <img src={closeicon} style={{width:'42px'}}/>
        </IconButton>
          <Grid item xs={12} sm={6}>
            <img
              src={person}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h2" gutterBottom>
            <span style={{color:'#EFEDFDB2'}}>Thank you for choosing</span> mePrism <span style={{color:'#EFEDFDB2'}}>to help</span> protect your Data
            </Typography>
            <Button  style={{color:'#ffffff'}} onClick={handleClose}>
            See your scan results
            </Button>
          </Grid>
        </Grid>
    </Modal>
  )
}

export default SuccessLoginModal
