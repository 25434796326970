import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types'
import { Logger } from '@meprism/app-utils'
import type { CognitoUser } from 'amazon-cognito-identity-js'
import { MfaType } from '../redux/authentication/authenticationTypes'

export const AuthenticationService = {
  currentUser: async () => {
    return await Auth.currentUserPoolUser()
  },
  signInWithApple: async (destination = '/') => {
    try {
      Logger.debug('Attempting to Sign In With Apple')
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Apple,
        customState: destination,
      })
    } catch (error) {
      Logger.error(`Sign In With Apple Failed ${error}`)
      throw error
    }
  },
  signInWithGoogle: async (destination = '/') => {
    try {
      Logger.debug('Attempting to Sign In With Google')
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
        customState: destination,
      })
    } catch (error) {
      Logger.error(`Sign In With Google Failed ${error}`)
      throw error
    }
  },
  signUpWithEmail: async (email: string, password: string) => {
    try {
      Logger.debug('Attempting to sign in with email')
      const confirmation = await Auth.signUp({
        username: email,
        password: password,
        autoSignIn: {
          enabled: true,
        },
      })
      Logger.debug('Login with email successful, awaiting confirmation')
      return confirmation
    } catch (error: any) {
      throw error.code
    }
  },
  confirmEmailSignup: async (userName: string, otp: string) => {
    Logger.debug(`Confirming user email signup for ${userName}`)
    try {
      await Auth.confirmSignUp(userName, otp)
    } catch (error: any) {
      Logger.error(`Error confirming user signup ${error}`)
      throw error.code
    }
  },
}
export const getUserAuthToken = async () => {
  return (await Auth.currentSession()).getIdToken().getJwtToken()
}

export const getMuidFromToken = async (): Promise<string | undefined> => {
  const idToken = (await Auth.currentSession()).getIdToken()
  return idToken.payload['custom:muid']
}

export const getCurrentAuthenticatedUser = async (): Promise<CognitoUser> =>
  Auth.currentAuthenticatedUser()

export const updateUserAttributes = async (attributes: Record<string, any>) => {
  const user = await Auth.currentAuthenticatedUser()
  return await Auth.updateUserAttributes(user, attributes)
}

export const setPreferredMfa = async (method: MfaType) => {
  const user = await Auth.currentAuthenticatedUser()
  return await Auth.setPreferredMFA(user, method)
}
