import { BaseThunkExtra } from '@meprism/shared'
import AnalyticsManager from '../services/AnalyticsManager'
import { Toast } from '../components/toast/toast'
import { configuredApi, configuredHub } from '../app/aws-exports'

export type WebAppThunkExtraType = BaseThunkExtra

export const webAppThunkExtra: WebAppThunkExtraType = {
  API: configuredApi,
  Hub: configuredHub,
  AnalyticsManager: AnalyticsManager,
  Toast: Toast,
  // @TODO: This
  handleSupport: () => {},
  Keychain: {
    setGenericPassword: async () => ({ service: '', storage: '' }),
    getGenericPassword: async () => false,
    getAllGenericPasswordServices: async () => [],
    resetGenericPassword: async () => true,
    hasInternetCredentials: async () => false,
    setInternetCredentials: async () => ({ service: '', storage: '' }),
    requestSharedWebCredentials: async () => false,
    setSharedWebCredentials: async () => {},
  },
}
