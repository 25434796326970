import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AnalyticsService } from '../../services/AnalyticsService'

export const usePageTracking = () => {
  const location = useLocation()
  const { pathname, search } = location
  useEffect(() => {
    AnalyticsService.trackTypedEvent({
      event: 'Page View',
      path: pathname,
      search,
    })
  }, [pathname, search])
}
