import React, { useState } from 'react'
import {
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  MenuItem,
  Menu,
  ButtonBase,
} from '@mui/material'
import notify from '@meprism/shared/src/assets/icons/Notifications.svg'

import { theme } from '../../../theme/DashboardTheme'
import { useAppDispatch, useAppSelector } from '../../../redux/storeExports'
import { ProfileSelectors } from '@meprism/shared/src/redux/profile/ProfileSlice'
import { logOutUser } from '../../../redux/authentication/AuthenticationSlice'
import { Link, useNavigate } from 'react-router-dom'
import { ExpandMore } from '@mui/icons-material'
interface TitleProps {
  title: string
  count?: number
}
const MainHeader = (props: TitleProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const profileName = useAppSelector(ProfileSelectors.selectProfileName)
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget)
  }
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleMenuClose = () => {
    setMenuAnchor(null)
  }
  const handleLogout = async () => {
    //@ts-ignore
    Intercom('shutdown')
    //@ts-ignore
    window.intercomSettings = {
      app_id: 'p9mftca9',
      custom_launcher_selector: '#intercom-launcher',
    }
    await dispatch(logOutUser()).unwrap()
    navigate('/login')
  }
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={isMobile ? 10 : 5}>
      <Typography variant="h2">
        {props.title}
        {props.count !== undefined && props.count > 0 && (
          <span style={{ marginLeft: 5 }}>{'(' + props.count + ')'}</span>
        )}
      </Typography>
      {!isMobile && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            edge="end"
            style={{ marginRight: 5 }}
            onClick={handleMenuOpen}>
            <img src={notify} alt="icon" width="100%" />
          </IconButton>
          <ButtonBase
            sx={{ borderRadius: '10px' }}
            onClick={handleMenuOpen}
            aria-controls={menuAnchor ? 'profile-menu' : undefined}
            aria-haspopup="true">
            <Typography variant="h4">Hello {profileName}</Typography>
            <ExpandMore />
          </ButtonBase>
          <Menu
            id="profile-menu"
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
            sx={{ borderRadius: '20px' }}>
            {/* Add other menu items if needed */}
            <MenuItem>
              <Typography
                component={Link}
                to="https://meprism.com/terms-conditions"
                target="_blank"
                sx={{ color: '#615F6B', textDecoration: 'none' }}>
                Terms & Conditions
              </Typography>
            </MenuItem>
            <MenuItem>
              <Typography
                component={Link}
                to="https://meprism.com/privacy-policy"
                target="_blank"
                sx={{ color: '#615F6B', textDecoration: 'none' }}>
                Privacy policy
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout} sx={{ color: '#615F6B' }}>
              Sign Out
            </MenuItem>
          </Menu>
        </div>
      )}
    </Box>
  )
}
export default MainHeader
