import { combineReducers } from '@reduxjs/toolkit'
import { AuthenticationReducer } from '@meprism/shared/src/redux/authentication/authenticationSlice'
import { ExportReducer } from './export/ExportSlice'
import { PeopleFinderReducer } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import { ProfileReducer } from '@meprism/shared/src/redux/profile/ProfileSlice'
import { AlertReducer } from '@meprism/shared/src/redux/alert/AlertSlice'
import { ProductReducer } from './product/ProductSlice'

export const appReducer = combineReducers({
  authentication: AuthenticationReducer,
  alerts: AlertReducer,
  export: ExportReducer,
  peopleFinder: PeopleFinderReducer,
  profile: ProfileReducer,
  product: ProductReducer
})

export const RootReducer: typeof appReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
