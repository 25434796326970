import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

// @TODO: Look at replacing CircularProgress with a custom loader?
// BUT it's nice that it had native cascade support so if you chain 3 loaders,
// it doesn't "reset" its state and jitter as the loads finish.
export const LoadingFallback = () => {
  return (
    <Backdrop open={true} invisible>
      <CircularProgress />
    </Backdrop>
  )
}
