import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { Logger } from '@meprism/app-utils'
import { Auth } from 'aws-amplify'
import { AnalyticsService } from './AnalyticsService'

export interface UserInfo {
  username: string
  muid: string
}

export interface AuthUser {
  userInfo: UserInfo
}
//dff
const loggingPrefix = 'Auth-Service: '

export const buildAuthUser = (authData: Record<string, any>): AuthUser => {
  const userInfo = {
    username: authData?.username,
    muid: authData?.attributes?.sub,
  }

  return { userInfo }
}

export const getAuthenticatedUser = async (): Promise<AuthUser> => {
  let authUser = {} as AuthUser
  try {
    Logger.debug(loggingPrefix + 'Attempting to get Authenticated user info')
    authUser = buildAuthUser(await Auth.currentAuthenticatedUser())
  } catch (error) {
    Logger.debug(loggingPrefix + 'Get Authenticated User Info Failed ' + error)
  }
  return authUser
}

export const getUserAuthToken = async () => {
  return (await Auth.currentSession()).getIdToken().getJwtToken()
}

export const signInWithApple = async (destination = '/') => {
  try {
    Logger.debug(loggingPrefix + 'Attempting to Sign In With Apple')
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Apple,
      customState: destination,
    })
  } catch (error) {
    Logger.debug(loggingPrefix + 'Sign In With Apple Failed ' + error)
  }
}

export const signInWithGoogle = async (destination = '/') => {
  try {
    Logger.debug(loggingPrefix + 'Attempting to Sign In With Google')
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: destination,
    })
  } catch (error) {
    Logger.debug(loggingPrefix + 'Sign In With Google Failed ' + error)
  }
}

export const signOut = async () => {
  try {
    Logger.debug(loggingPrefix + 'Attempting to Sign Out')
    await Auth.signOut()
    AnalyticsService.reset()
  } catch (error) {
    Logger.info(loggingPrefix + 'error signing out: ' + error)
  }
}

export const signInWithEmail = async (username: string, password: string) => {
  try {
    return await Auth.signIn(username, password)
  } catch (error) {
    Logger.info(loggingPrefix + 'error signing in : ' + error)
    throw error
  }
}

export const signUpWithEmail = async (username: string, password: string) => {
  try {
    await Auth.signUp(username, password)
  } catch (error) {
    Logger.debug(loggingPrefix + `error signing up : ${error}`)
    throw error
  }
}

export const confirmSignUp = async (username: string, otp: string) => {
  Logger.debug(`${loggingPrefix} Confirming signup for ${username}`)
  try {
    await Auth.confirmSignUp(username, otp)
  } catch (error) {
    Logger.debug(`${loggingPrefix} Error confirming signup ${error}`)
    throw error
  }
}

export const forgotPassword = async (username: string) => {
  Logger.debug(`Resetting password for ${username}`)
  try {
    await Auth.forgotPassword(username)
  } catch (error) {
    Logger.debug(`${loggingPrefix} Error confirming signup ${error}`)
    throw error
  }
}

export const submitForgotPassword = async (
  username: string,
  otp: string,
  newPassword: string,
) => {
  Logger.debug(`${loggingPrefix} Submitting reset password for ${username}`)
  try {
    await Auth.forgotPasswordSubmit(username, otp, newPassword)
  } catch (error) {
    Logger.debug(`${loggingPrefix} Error confirming forgot password ${error}`)
    throw error
  }
}

export const submitChangePassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  Logger.debug(`${loggingPrefix} Submitting change password`)
  try {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, oldPassword, newPassword)
  } catch (error) {
    Logger.debug(`${loggingPrefix} Error confirming forgot password ${error}`)
    throw error
  }
}

export const resendSignup = async (username: string) => {
  try {
    Logger.info(`${loggingPrefix} Resending signup for ${username}`)
    await Auth.resendSignUp(username)
  } catch (error) {
    Logger.debug(`${loggingPrefix} Error resending signup ${error}`)
    throw error
  }
}
