import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@mui/material'
import MainHeader from '../shared/organism/MainHeader'
import React, { useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AccSecurity from '../shared/molecule/AccSecurity'
import { theme } from '../../theme/DashboardTheme'
import DeleteAcc from '../shared/molecule/DeleteAcc'
import { ExpandMore } from '@mui/icons-material'

const styles = {
  listItem: {
    color: 'gray !important',
  },
  listItemActive: {
    color: 'inherit !important',
  },
  menuList: {
    backgroundColor: '#fff',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '4px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
}

export const SettingsView = () => {
  const [selectedItem, setSelectedItem] = useState('Account & Security')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const menuList = ['Account & Security', 'Delete Account']

  const renderMenu = () => {
    switch (selectedItem) {
      case 'Account & Security':
        return <AccSecurity />
      case 'Delete Account':
        return <DeleteAcc />
      default:
        return <></>
    }
  }

  return (
    <Container fixed maxWidth={'lg'} sx={{ marginInlineStart: '0.5rem' }}>
      <MainHeader title={'Settings'} />
      {isMobile ? (
        <div style={{ marginBlock: '1rem' }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content">
              Account & Security
            </AccordionSummary>
            <AccordionDetails>
              <AccSecurity />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              Delete Account
            </AccordionSummary>
            <AccordionDetails>
              <DeleteAcc />
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <Grid container columnSpacing={5} sx={{ minHeight: '90dvh' }}>
          <Grid item md={3} sx={styles.menuList}>
            <nav>
              <List>
                {menuList.map((menu, index) => (
                  <>
                    <ListItem
                      key={index}
                      disablePadding
                      onClick={() => setSelectedItem(menu)}>
                      <ListItemButton>
                        <ListItemText
                          disableTypography
                          sx={
                            selectedItem !== menu
                              ? styles.listItem
                              : styles.listItemActive
                          }>
                          {menu}
                        </ListItemText>
                        <ListItemIcon>
                          <ArrowForwardIosIcon sx={{ fontSize: '12px' }} />
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            </nav>
          </Grid>
          <Grid item md={9}>
            {renderMenu()}
          </Grid>
        </Grid>
      )}
    </Container>
  )
}
