import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import {
  AxiosProgressEvent,
  DownloadFileProps,
  DownloadProgress,
  DownloadResponse,
  DownloadStartPayload,
  ExportDescription,
  ExportListResponse,
  ExportResponse,
  ExportSlice,
  ExportStatus,
} from './ExportTypes'
import type { RootState } from '../store'
import { useSelector } from 'react-redux'
import { Logger } from '@meprism/app-utils'
import axios from 'axios'
import { AlertColor } from '@mui/material/Alert'

export const initialExportState: ExportSlice = {
  exports: [],
  loading: false,
  toast: undefined,
}

const getExports = async (): Promise<ExportListResponse> =>
  API.get('Export', '', {})

const postExport = async (): Promise<ExportResponse> =>
  API.post('Export', '', { body: {} })

const getDownloadUrlForExport = async (
  exportId: string,
): Promise<DownloadResponse> => API.get('Export', `/${exportId}`, {})

const convertExportResponseToDescription = (
  response: ExportResponse,
): ExportDescription => {
  const status =
    new Date() > new Date(response.expiration_time)
      ? ExportStatus.EXPIRED
      : response.status
  return {
    id: response.id,
    createTime: response.create_time,
    expirationTime: response.expiration_time,
    email: response.email,
    categories: response.categories,
    status,
    muid: response.muid,
  }
}

export const fetchExport = createAsyncThunk('fetchExport', async () => {
  try {
    const response = await getExports()
    return response.exports.map(convertExportResponseToDescription)
  } catch (error: any) {
    if (error?.response?.status === 404) {
      Logger.info('fetchExport: Export not found')
    }
    throw error
  }
})

export const requestExport = createAsyncThunk(
  'requestExport',
  async (_, { dispatch }) => {
    try {
      const response = await postExport()
      return convertExportResponseToDescription(response)
    } catch (error) {
      Logger.error(`Error requesting export: ${error}`)
      dispatch(
        ExportActions.showToast({
          severity: 'error',
          text: 'There was an error requesting your takeout',
        }),
      )
      throw error
    }
  },
)

export const downloadExport = createAsyncThunk(
  'downloadExport',
  async ({ id }: DownloadFileProps, { dispatch }) => {
    Logger.debug(`Attempting to get download for ${id}`)
    let url: string
    try {
      const downloadResponse = await getDownloadUrlForExport(id)
      url = downloadResponse.url
    } catch (error) {
      Logger.error(`Error getting download url for ${id}: ${error}`)
      throw error
    }
    Logger.debug(`Downloading file from ${new URL(url).pathname}`)
    dispatch(
      ExportActions.startDownload({
        id,
        jobId: id,
        contentLength: 1000,
        bytesWritten: 0,
      }),
    )
    const response = await axios.get(url, {
      responseType: 'blob',
      onDownloadProgress: (progress: AxiosProgressEvent) => {
        dispatch(
          ExportActions.updateDownloadProgress({
            jobId: id,
            bytesWritten: progress.loaded,
            contentLength: progress.total as number,
          }),
        )
      },
    })
    const linkUrl = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/zip' }),
    )
    const link = document.createElement('a')
    link.setAttribute('download', 'my-data-from-meprism.zip')
    link.setAttribute('href', linkUrl)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },
)

const { actions, reducer } = createSlice({
  name: 'export',
  initialState: initialExportState,
  reducers: {
    startDownload: (state, { payload }: { payload: DownloadStartPayload }) => {
      const { id, ...progress } = payload
      const index = state.exports.findIndex((exp) => exp.id === id)
      if (index >= 0) {
        const exports = [...state.exports]
        exports[index].downloadProgress = progress
        state.exports = exports
      }
    },
    updateDownloadProgress: (
      state,
      { payload }: { payload: DownloadProgress },
    ) => {
      const index = state.exports.findIndex(
        (exp) => exp?.downloadProgress?.jobId === payload.jobId,
      )
      if (index >= 0) {
        const exports = [...state.exports]
        exports[index].downloadProgress = payload
        state.exports = exports
      }
    },
    showToast: (
      state,
      { payload }: { payload: { severity: AlertColor; text: string } },
    ) => {
      state.toast = { shown: true, ...payload }
    },
    hideToast: (state) => {
      if (state.toast) {
        state.toast.shown = false
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExport.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchExport.fulfilled, (state, action) => {
      state.loading = false
      if (!state.exports.some((exp) => exp.downloadProgress)) {
        state.exports = action.payload
      }
    })
    builder.addCase(fetchExport.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(requestExport.pending, (state) => {
      state.loading = true
    })
    builder.addCase(requestExport.fulfilled, (state, action) => {
      state.loading = false
      state.exports.push(action.payload)
    })
    builder.addCase(requestExport.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(downloadExport.fulfilled, (state) => {
      state.toast = {
        shown: true,
        severity: 'success',
        text: 'Your download has completed',
      }
    })
    builder.addCase(downloadExport.rejected, (state) => {
      state.toast = {
        shown: true,
        severity: 'error',
        text: 'We were unable to download your file',
      }
    })
  },
})

export const ExportActions = actions
export const ExportReducer = reducer

const selectExports = (state: RootState) => state.export.exports

export const ExportsSelector = () =>
  useSelector((state: RootState) => state.export)

const selectExpiredExports = createSelector([selectExports], (exports) =>
  exports.filter((exp) => exp.status === ExportStatus.EXPIRED),
)

const selectActiveExports = createSelector([selectExports], (exports) =>
  exports.filter((exp) => exp.status !== ExportStatus.EXPIRED),
)

export const ExpiredExportsSelector = () => useSelector(selectExpiredExports)
export const ActiveExportsSelector = () => useSelector(selectActiveExports)
export const ExportToastSelector = () =>
  useSelector((state: RootState) => state.export.toast)
