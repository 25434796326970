import { Logger } from '@meprism/app-utils'
import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  LoaderFunction,
  Navigate,
  Outlet,
  redirect,
  Route,
  RouterProvider,
} from 'react-router-dom'
import RequireAuth, {
  requireAuthLoader,
} from './components/Authentication/RequireAuth'
import ValidateAuthStatus, {
  oauthLoader,
} from './components/Authentication/ValidateAuthStatus'
// import LoginOTP from './components/Authentication/LoginOTP'
import SignUpEmail from './components/Authentication/SignUpWithEmail'
import LoginWithEmail, {
  LoginWithEmailInner,
  loginWithEmailLoader,
} from './components/Authentication/LoginWithEmail'
import { currentEnvironment, Environments, logLevel } from './utils/helpers'
import Logout from './components/Authentication/Logout'
import ChangePasswordRedirect from './components/Authentication/ChangePasswordRedirectScreen'
import { ExportData } from './components/Export/ExportData'
import Home from './components/Views/Home'
// import { PrivacyOnboardingView } from './components/Views/PrivacyOnboardingView'
import { MainLayout, mainLayoutLoader } from './components/layouts/MainLayout'
import { SettingsView } from './components/layouts/SettingsView'
import { store } from './redux/store'
import { RootLayout, rootLayoutLoader } from './components/layouts/RootLayout'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { fetchBrokerDetailsV2 } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import BrokerHome from './components/Views/HomeViews/BrokerHome'
import BrokerDetails from './components/Views/HomeViews/BrokerDetails'
// import { AuthHeader } from './components/shared/organism/AuthHeader'
import { SignInLayout } from './components/layouts/SignInLayout'
import SubscribeLanding, {
  subscribeLandingLoader,
} from './components/Views/SubscribeLanding'
import { SubscribeLayout } from './components/layouts/SubscribeLayout'
import { RequireProfile } from './components/gateways/profile/RequireProfile'
import { RootErrorFallback } from './components/Views/ErrorViews/RootErrorFallback'
import { CompletePassword } from './components/Authentication/CompletePassword'
import { SignInCopyWrapper } from './components/layouts/SignInCopyWrapper'
import { VerifyOtpWrapper } from './components/Authentication/VerifyOtpWrapper'
import { PrivacyOnboardingWrapper } from './components/Views/PrivacyOnboardingViewWrapper'
import { AuthorizationAgentWrapper } from './components/Authentication/AuthorizationAgentWrapper'
import { PasswordResetWrapper } from './components/Authentication/PasswordResetWrapper'
import { ChangePasswordWrapper } from './components/Authentication/ChangePasswordWrapper'
import { ResetVerifyWrapper } from './components/Authentication/ResetVerifyWrapper'
import { TotpView } from './components/Authentication/Totp'
import SidebarNavigation from './components/layouts/SidebarNavigation'
import DashboardView from './components/Views/DashboardView'
import ProfileDashboard, { profileLandingLoader } from './components/Views/SettingsViews/ProfileDashboard'
import { accountLoader } from './components/Views/SettingsViews/Account'
import SubscriptionView from './components/Views/SubscriptionView'

Logger.initializeLogger(
  {
    apiHtmlPath: '/html',
    apiLogPath: '/logs',
    apiName: 'UILogging',
    isRunningLocally: currentEnvironment() === Environments.local,
    logLevel: logLevel('DEBUG'),
    platform: 'web',
  },
  () => {},
)

export const brokerDetailLoader: LoaderFunction = async ({ params }) => {
  try {
    if (!params.scan_result_id) {
      throw Error('No scan result id in url')
    }
    store.dispatch(fetchBrokerDetailsV2(params.scan_result_id as string))
  } catch (error) {
    Logger.error(`Error fetching broker details: ${error}`)
    Logger.error(`This is an error`)
    // @TODO: toast etc? Maybe show not found?
    throw redirect('/home')
  }
  return null
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<RootLayout />}
      loader={rootLayoutLoader}
      errorElement={<RootErrorFallback />}>
      <Route
        element={
          <>
            {/* <AuthHeader /> */}
            <Outlet />
          </>
        }>
        <Route element={<SignInCopyWrapper />} path={'login'}>
          <Route element={<SignInLayout />}>
            <Route path="create" element={<SignUpEmail />} />
            <Route
              path="signin"
              element={<LoginWithEmail />}
              loader={loginWithEmailLoader}
              // In case of login error, we can just render the inner page and let the user figure it out
              errorElement={<LoginWithEmailInner />}
            />
            <Route index element={<Navigate to={'create'} replace />} />
          </Route>
        </Route>
        <Route
          path="/oauth2/idpresponse"
          element={<ValidateAuthStatus />}
          loader={oauthLoader}
        />
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<SignUpEmail />} />
        <Route path="/otp" element={<VerifyOtpWrapper />} />
        <Route path="/totp" element={<TotpView />} />
        <Route path="/verifyotp" element={<ResetVerifyWrapper />} />
        <Route path="/signInWithEmail" element={<LoginWithEmail />} />
        {/* <Route path="/resetPassword" element={<PasswordReset />} /> */}
        <Route path="/resetPassword" element={<PasswordResetWrapper />} />
        <Route path="/completePassword" element={<CompletePassword />} />
        <Route path="/changePassword" element={<ChangePasswordWrapper />} />
        <Route
          path="/changePasswordSuccess"
          element={<ChangePasswordRedirect />}
        />
      </Route>

      <Route
        loader={requireAuthLoader}
        element={
          <RequireAuth>
            <Outlet />
          </RequireAuth>
        }>
        <Route path="/authorization" element={<AuthorizationAgentWrapper />} />
        <Route path="/onboarding" element={<PrivacyOnboardingWrapper />} />

        <Route
          element={
            <RequireProfile>
              <MainLayout />
            </RequireProfile>
          }
          loader={mainLayoutLoader}
          id={'main'}>
          <Route path={'/home/:inaccurate?'} element={<Home />}>
            <Route path="" element={<BrokerHome />} />
            <Route
              path="broker/:scan_result_id"
              element={<BrokerDetails />}
              loader={brokerDetailLoader}
            />
          </Route>
          <Route index element={<Navigate to={'dashboard'} replace />} />
          <Route
              path="/home"
              errorElement={<RootErrorFallback />}
              element={<SidebarNavigation />}>
            <Route index element={<DashboardView />} />

            <Route path={'profile'} element={<ProfileDashboard />}  loader={profileLandingLoader}/>
            <Route path="help-support/FAQs" element={<Home />} />
            <Route path="help-support/CustomerSupport" element={<Home />} />
          </Route>
          <Route
            path="/dashboard"
            errorElement={<RootErrorFallback />}
            element={<SidebarNavigation />}>
            <Route index element={<DashboardView />} />

            <Route path={'profile'} element={<ProfileDashboard />}  loader={profileLandingLoader}/>
            <Route path="help-support/FAQs" element={<Home />} />
            <Route path="help-support/CustomerSupport" element={<Home />} />
          </Route>
          <Route
            path="/subscription"
            errorElement={<RootErrorFallback />}
            element={<SidebarNavigation />}>
            <Route
              index
              loader={accountLoader}
              element={<SubscriptionView />}
            />
          </Route>
         
          <Route
            path="/settings"
            errorElement={<RootErrorFallback />}
            element={<SidebarNavigation />}>
            <Route index loader={accountLoader} element={<SettingsView />} />
          </Route>
          <Route index element={<Navigate to={'home'} replace />} />
          <Route path={'subscribe'} element={<SubscribeLayout />}>
            <Route
              path="success"
              element={<SubscribeLanding />}
              loader={subscribeLandingLoader}
            />
          </Route>
        </Route>
        <Route path="/export" element={<ExportData />} />
      </Route>
      <Route path="/splash" element={<Navigate to={'/home'} replace />} />
    </Route>,
  ),
)

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RouterProvider router={router} />
    </LocalizationProvider>
  )
}

export default App
