import { PeopleFinderSelectors } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import { Box, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useAppSelector } from '../../../redux/storeExports'
import { BrokerAccordion } from './BrokerAccordion'
import { Broker } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderTypes'
import MpScanResult from '../../shared/atom/MpScanResult'
import { useLocation } from 'react-router-dom'
import { EmptyBrokerList } from './EmptyBrokerList'

export const getSubtitle = (broker: Broker) => {
  const detail = broker?.detail
  const name = [detail?.first_name, detail?.middle_name, detail?.last_name]
    .filter((e) => e)
    .join(' ')
  const address = detail?.addresses?.[0]
  const addressString = [
    address?.street,
    address?.city,
    address?.state,
    address?.zip,
  ]
    .filter((e) => e)
    .join(', ')
  return [name, addressString].filter((e) => e).join(' · ')
}

const BrokerList = () => {
  const location = useLocation()
  const inaccurate = location.pathname.includes('inaccurate')

  const accurateBrokers = useAppSelector(
    PeopleFinderSelectors.selectGroupedAccurateBrokers,
  )
  const inaccurateBrokers = useAppSelector(
    PeopleFinderSelectors.selectGroupedInaccurateBrokers,
  )
  const brokers = inaccurate ? inaccurateBrokers : accurateBrokers

  const [selectedBrokerName, setSelectedBrokerName] = useState<
    string | undefined
  >(undefined)
  return (
    <Stack spacing={2}>
      {Object.entries(brokers).length === 0 ? (
        <EmptyBrokerList inaccurate={inaccurate} />
      ) : (
        Object.entries(brokers).map(([brokerName, brokerArray]) =>
          brokerArray.length > 1 ? (
            <Box key={brokerName}>
              <BrokerAccordion
                brokerName={brokerName}
                results={brokerArray}
                setExpanded={setSelectedBrokerName}
                expanded={brokerName === selectedBrokerName}
                details={getSubtitle}
                inaccurate={inaccurate}
              />
            </Box>
          ) : (
            <MpScanResult
              broker={brokerArray[0]}
              key={brokerName}
              details={getSubtitle}
              sxOverride={{ pl: '54px', pr: '40px' }}
              inaccurate={inaccurate}
            />
          ),
        )
      )}
    </Stack>
  )
}

export default BrokerList
