import React, { ReactNode, useState } from 'react'
import {
  useMediaQuery,
  ThemeProvider,
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Tooltip,
  IconButton,
} from '@mui/material'
import { fonts, theme } from '../../../theme/DashboardTheme'
import Pagination from '@mui/material/Pagination'
import arrowimage from '@meprism/shared/src/assets/icons/next.svg'
import { useAppSelector } from '../../../redux/storeExports'
import { PeopleFinderSelectors } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import ExposedDataModal from './ExposedDataModal'
import { selectActiveGrant } from '@meprism/shared/src/redux/product/ProductSlice'
import { HelpOutlineOutlined } from '@mui/icons-material'

const DashboardGrid = () => {
  const accurateBrokers = useAppSelector(
    PeopleFinderSelectors.selectGroupedAccurateBrokers,
  )
  const grant = useAppSelector(selectActiveGrant)
  const [page, setPage] = useState(0)
  const rowsPerPage = 5
  const rows = Object.entries(accurateBrokers)
    .map(([brokerName, brokerArray], index) => {
      const matchingBroker = brokerArray.find((item) =>
        ['new', 'waiting_for_verification', 'optout_in_progress'].includes(
          item.status,
        ),
      )
      if (matchingBroker) {
        const hasPhones = matchingBroker.detail?.phones?.length ?? 0 > 0
        const hasRelatives = matchingBroker.detail?.relatives?.length ?? 0 > 0
        const email = matchingBroker.detail?.emails?.length ?? 0 > 0
        const address = matchingBroker.detail?.addresses?.length ?? 0 > 0
        const name = matchingBroker.detail?.first_name
        const hasAge = matchingBroker.detail?.age
        const col3Text = []

        if (name) {
          col3Text.push('Name')
        }
        if (hasPhones) {
          col3Text.push('Phone')
        }
        if (hasRelatives) {
          col3Text.push('Relatives')
        }
        if (hasAge) {
          col3Text.push('Age')
        }
        if (email) {
          col3Text.push('Email')
        }
        if (address) {
          col3Text.push('Address')
        }

        const brokerlength = brokerArray.length
        const dataBroker = brokerName

        return [
          {
            id: index + 1,
            col1: dataBroker,
            col2: brokerlength,
            col3: col3Text.join(', '),
            col4: 'new',
          },
        ]
      }

      const removedBroker = brokerArray.find(
        (item) => item.status === 'removed',
      )
      if (removedBroker) {
        const hasPhones = removedBroker.detail?.phones?.length ?? 0 > 0
        const hasRelatives = removedBroker.detail?.relatives?.length ?? 0 > 0
        const email = removedBroker.detail?.emails?.length ?? 0 > 0
        const address = removedBroker.detail?.addresses?.length ?? 0 > 0
        const name = removedBroker.detail?.first_name
        const hasAge = removedBroker.detail?.age
        const col3Text = []

        if (name) {
          col3Text.push('Name')
        }
        if (hasPhones) {
          col3Text.push('Phone')
        }
        if (hasRelatives) {
          col3Text.push('Relatives')
        }
        if (hasAge) {
          col3Text.push('Age')
        }
        if (email) {
          col3Text.push('Email')
        }
        if (address) {
          col3Text.push('Address')
        }

        const brokerlength = brokerArray.length
        const dataBroker = brokerName

        return [
          {
            id: index + 1,
            col1: dataBroker,
            col2: brokerlength,
            col3: col3Text.join(', '),
            col4: 'removed',
          },
        ]
      }

      const brokerRows = brokerArray.map((item, subIndex) => {
        const hasPhones = item.detail?.phones?.length ?? 0 > 0
        const hasRelatives = item.detail?.relatives?.length ?? 0 > 0
        const email = item.detail?.emails?.length ?? 0 > 0
        const address = item.detail?.addresses?.length ?? 0 > 0
        const name = item.detail?.first_name
        const hasAge = item.detail?.age
        const col3Text = []

        if (name) {
          col3Text.push('Name')
        }
        if (hasPhones) {
          col3Text.push('Phone')
        }
        if (hasRelatives) {
          col3Text.push('Relatives')
        }
        if (hasAge) {
          col3Text.push('Age')
        }
        if (email) {
          col3Text.push('Email')
        }
        if (address) {
          col3Text.push('Address')
        }

        const brokerlength = brokerArray.length
        const dataBroker = brokerName

        return {
          id: index * rowsPerPage + subIndex + 1,
          col1: dataBroker,
          col2: brokerlength,
          col3: col3Text.join(', '),
          col4: item.status,
        }
      })
      return brokerRows
    })
    .flat()
    .filter(Boolean)

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1)
  }
  const [openmodal, setopenmodal] = useState(false)
  const [selectedBrokerData, setSelectedBrokerData] = useState([])
  const handleOpenModal = (brokerData: any) => {
    setopenmodal(true)
    setSelectedBrokerData(brokerData)
  }
  const columns = [
    { key: 'col1', name: 'Data Brokers', resizable: true, width: 150 },
    { key: 'col2', name: 'Records found', resizable: true, width: 150 },
    { key: 'col3', name: 'Exposed Data', resizable: true, width: 150 },
    { key: 'col4', name: 'Status', resizable: true, width: 150 },
    { key: '', name: '', resizable: false, width: 50 },
  ]
  const paginatedRows = rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
  const renderExposedData = (data: string) => {
    const returnedHtml: ReactNode[] = []
    let maxShownData = 4
    let currentIdx = 0
    const checkingKey = [
      'Name',
      'Age',
      'Address',
      'Phone',
      'Relatives',
      'Email',
    ]
    const lenOfCheckingKey = checkingKey.length
    while (maxShownData && currentIdx < lenOfCheckingKey) {
      if (data.includes(checkingKey[currentIdx])) {
        returnedHtml.push(
          <Box
            key={maxShownData}
            sx={{
              background: '#EFEDFDB2',
              display: 'inline-block',
              padding: '4px',
              borderRadius: '4px',
              marginRight: '5px',
            }}>
            {checkingKey[currentIdx]}
          </Box>,
        )
        maxShownData -= 1
      }
      currentIdx += 1
    }
    return returnedHtml
  }
  return (
    <ThemeProvider theme={theme}>
      {!isMobile ? (
        <Box
          style={{ height: 'auto', width: '100%', paddingBottom: '16px' }}
          bgcolor="#fff"
          borderRadius={5}
          p={3}>
          <Typography variant="h3" mx={3} mt={2} mb={8}>
            Removal Stats
          </Typography>
          <TableContainer>
            <Table
              style={{
                background: '#fff',
                color: '#040115',
                borderRadius: 5,
                padding: 3,
              }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.key}
                      style={{
                        borderBottom: '1px solid #9135E0',
                        ...fonts.Inter.SemiBold,
                      }}>
                      {column.name}
                      {column.name === 'Records found' && (
                        <Tooltip title="A data broker can create multiple records or profiles of you using your data">
                          <IconButton>
                            <HelpOutlineOutlined
                              sx={{
                                fontSize: '16px',
                                color: 'rgba(145, 53, 224, 1)',
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {paginatedRows.map((row) => (
                  <TableRow key={row.id}>
                    {columns.map((column) => (
                      <TableCell
                        onClick={() => {
                          const brokerData = accurateBrokers[row.col1]
                          if (grant) {
                            handleOpenModal(brokerData)
                          }
                        }}
                        key={column.key}
                        style={{
                          borderBottom: '1px solid rgba(97, 95, 107, 0.5)',
                        }}>
                        {column.key === '' && grant ? (
                          <img
                            src={arrowimage}
                            alt="Arrow"
                            style={{ width: '20px', height: '20px' }}
                          />
                        ) : column.key === 'col1' ? (
                          `${row.col1}`
                        ) : column.key === 'col3' ? (
                          <>{renderExposedData(row.col3)}</>
                        ) : column.key === 'col4' ? (
                          grant ? (
                            <span
                              style={{
                                color: ['optout_in_progress', 'new'].includes(
                                  row.col4,
                                )
                                  ? '#E2B93B'
                                  : '#EB5757',
                              }}>
                              {
                                ['optout_in_progress', 'new'].includes(row.col4)
                                  ? 'Removing'
                                  : 'Removed' // Adjust with actual status names
                              }
                            </span>
                          ) : (
                            <Link href="/subscription">Upgrade to remove</Link>
                          )
                        ) : (
                          row[column.key as keyof typeof row]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
                padding: 2,
                borderRadius: 5,
                background: '#fff',
              }}>
              <Pagination
                variant="outlined"
                shape="rounded"
                count={Math.ceil(rows.length / rowsPerPage)}
                page={page + 1}
                onChange={handleChangePage}
              />
            </Box>
          </TableContainer>
        </Box>
      ) : (
        <Box
          style={{ height: 'auto', width: '100%' }}
          bgcolor="#fff"
          borderRadius={5}
          p={3}>
          <Typography variant="h3" mx={2} mt={2} mb={5}>
            Removal Stats({rows.length})
          </Typography>

          {rows.map((row) => (
            <Grid
              key={row?.id}
              container
              direction="row"
              spacing={2}
              sx={{
                borderBottom: '1px solid #615F6B',
                padding: 2,
                position: 'relative',
              }}>
              {columns.map((col) => (
                <Grid
                  item
                  key={col.key}
                  xs={6}
                  onClick={() => {
                    if (row) {
                      const brokerData = accurateBrokers[row.col1]
                      if (grant) {
                        handleOpenModal(brokerData)
                      }
                    }
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                      wordWrap: 'break-word',
                    }}>
                    {col.key !== '' && (
                      <Typography variant="h5">{col.name}:</Typography>
                    )}

                    {col.key === 'col3' ? (
                      <Typography variant="label">
                        {renderExposedData(
                          row?.col3 !== undefined ? row.col3 : '',
                        )}
                      </Typography>
                    ) : col.key === 'col4' ? (
                      grant ? (
                        <Typography
                          variant="label"
                          color={
                            [
                              'optout_in_progress',
                              'new',
                              'waiting_for_verification',
                            ].includes(row?.col4 || '')
                              ? '#E2B93B'
                              : '#EB5757'
                          }>
                          {
                            [
                              'optout_in_progress',
                              'new',
                              'waiting_for_verification',
                            ].includes(row?.col4 || '')
                              ? 'Removing'
                              : 'Removed' // Adjust with actual status names
                          }
                        </Typography>
                      ) : (
                        <Link href="/subscription">Upgrade to remove</Link>
                      )
                    ) : (
                      <Typography variant="label">
                        {' '}
                        {row?.[col.key as keyof typeof row]}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              ))}
              {grant && (
                <Box>
                  {' '}
                  <img
                    src={arrowimage}
                    alt="Arrow"
                    onClick={() => {
                      if (row) {
                        const brokerData = accurateBrokers[row.col1]
                        handleOpenModal(brokerData)
                      }
                    }}
                    style={{
                      marginBottom: '2rem',
                      width: '20px',
                      height: '20px',
                      position: 'absolute',
                      bottom: '0',
                      right: '0',
                    }}
                  />
                </Box>
              )}
            </Grid>
          ))}
        </Box>
      )}
      <ExposedDataModal
        rows={selectedBrokerData}
        open={openmodal}
        setOpen={setopenmodal}
      />
    </ThemeProvider>
  )
}

export default DashboardGrid
