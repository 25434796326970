import React, { useEffect, useState } from 'react'
import PasswordField from '../shared/atom/PasswordField'
import ValidationHint from '../shared/atom/MpValidationHint'
import { Button, Box, TextField } from '../shared/MaterialExports'
import { signUpWithEmail } from '../../services/AuthenticationService'
import { useLocation, useNavigate } from 'react-router-dom'
import { MpTypography } from '../shared'
import { AnalyticsService } from '../../services/AnalyticsService'
import Cookies from 'universal-cookie'
import MpTheme from '@meprism/shared/src/config/MpTheme'
import Stack from '@mui/material/Stack/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MpReTheme, fonts, theme } from '../../theme/OnboardingTheme'
import { Link } from '@mui/material'

const minPasswordLength = 10

const SignUpEmail = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = (location?.state as any)?.from || '/'
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [errorText, setErrorText] = useState<string>('')

  const [shouldDisplayPasswordInfo, setShouldDisplayPasswordInfo] =
    useState<boolean>(false)
  const [shouldShowMatchingPasswords, setShouldShowMatchingPasswords] =
    useState<boolean>(false)
  const [hasSufficientLength, setHasSufficientLength] = useState<boolean>(false)
  const [hasUpperCase, setHasUpperCase] = useState<boolean>(false)
  const [hasSymbol, setHasSymbol] = useState<boolean>(false)
  const [hasMatchingPassword, setHasMatchingPassword] = useState<boolean>(true)
  const isPasswordValid =
    hasSufficientLength && hasUpperCase && hasSymbol && hasMatchingPassword

  const clickID = location.search
    .split('?')?.[1]
    ?.split('&')
    ?.find((paramStr) => paramStr.includes('irclickid'))
    ?.split('=')?.[1]
  if (clickID) {
    const cookies = new Cookies()
    cookies.set('irclickid', clickID, { path: '/' })
  }
  useEffect(() => {
    setHasSufficientLength(password.length >= minPasswordLength)
    setHasUpperCase(new RegExp(/[A-Z]/).test(password))
    setHasSymbol(new RegExp(/[^A-Za-z0-9]/).test(password))
    setHasMatchingPassword(password === confirmPassword)
  }, [password, confirmPassword])

  const onSignUp = async () => {
    try {
      await signUpWithEmail(email, password)
      navigate('/otp', {
        state: { actionType: 'SIGNUP_EMAIL', username: email, password, from },
      })
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'UsernameExistsException':
            setErrorText(
              'A username with that email already exists. Try logging in by clicking "I Already Have An Account"',
            )
            break
          case 'LimitExceededException':
            setErrorText(
              `Unfortunately, mePrism is experiencing increased traffic and is unable to handle your sign up request. Please wait 15 minutes and try again.`,
            )
            AnalyticsService.error('CognitoLimit', error)
            break
          default:
            AnalyticsService.error(`Unhandled Authorization error: `, error)
            setErrorText(
              'An error occurred processing your signup request. Please try again later.',
            )
            break
        }
      } else {
        AnalyticsService.error(`Unhandled Authorization error: `, error)
      }
    }
  }
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const textStyle = {
    ...fonts.Inter.Regular,
    fontSize: '16px',
  }
  return (
    <Stack
      sx={{ maxWidth: MpTheme.layouts.widths.sm, mx: 'auto', width: !isMobile?'100%':'90%' }}
      spacing={5}>
      <TextField
        fullWidth
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        color={'primary'}
        variant={'outlined'}
        autoComplete="email"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          placeholder: 'Email',
        }}
      />
      <PasswordField
        value={password}
        placeholder='Password'
        onChange={(event) => setPassword(event.target.value)}
        onFocus={() => {
          setShouldDisplayPasswordInfo(true)
        }}
        variant={'outlined'}
        autoComplete="new-password"
        InputLabelProps={{ shrink: true }}
      />
      <PasswordField
      placeholder='Confirm Password'
        value={confirmPassword}
        onChange={(event) => setConfirmPassword(event.target.value)}
        onFocus={() => {
          setShouldShowMatchingPasswords(true)
        }}
        variant={'outlined'}
        InputLabelProps={{ shrink: true }}
        autoComplete="new-password"
      />
      <Box>
        {shouldDisplayPasswordInfo && (
          <Box>
            <ValidationHint
              isValid={hasSufficientLength}
              text={`At least ${minPasswordLength} characters`}
            />
            <ValidationHint
              isValid={hasUpperCase}
              text={'Contains at least one upper case letter'}
            />
            <ValidationHint isValid={hasSymbol} text={'Contains a symbol'} />
            {shouldShowMatchingPasswords && (
              <ValidationHint
                isValid={hasMatchingPassword}
                text={'Passwords match'}
              />
            )}
          </Box>
        )}
      </Box>
      {errorText && (
        <Box>
          <MpTypography color={'error'}>{errorText}</MpTypography>
        </Box>
      )}
      <Box>
        <Button
          disabled={!isPasswordValid}
          fullWidth
          onClick={onSignUp}>
          Create Account
        </Button>
      </Box>
      {!isMobile &&(<Box sx={{  maxWidth: MpTheme.layouts.widths.sm,width: '100%',position:'absolute',bottom:!isMobile?'30px':'80px'}}>
              <Stack
                direction={'row'}
                sx={{ alignItems: 'center', mt: 15,}}
                spacing={!isMobile?15:10}
                justifyContent={'space-between'}>
                <Link
                  href={'https://meprism.com/terms-conditions'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Terms & Conditions
                </Link>
                <Link
                  href={'https://meprism.com/privacy-policy'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Privacy Policy
                </Link>
                <Link
                  href={'https://meprism1.atlassian.net/servicedesk/customer/portal/1/group/1/create/17'}
                  sx={{
                    ...textStyle,
                    color: MpReTheme.colors.secondary.default,
                  }}>
                  Contact Us
                </Link>
              </Stack>
            </Box>)}
    </Stack>
  )
}

export default SignUpEmail
