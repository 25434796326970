import React from 'react'
import { defer, Outlet, redirect } from 'react-router-dom'
// import { MpHeader } from '../shared/organism/MpHeader'
import {
  fetchBrokers,
  fetchPeopleFinderProfile,
} from '@meprism/shared/src/redux/peopleFinder/PeopleFinderSlice'
import message from '@meprism/shared/src/assets/icons/messenger.png'
import { useInboxSocket } from '../../utils/hooks/useInboxSocket'
import { store } from '../../redux/store'
import { FetchProfile } from '@meprism/shared/src/redux/profile/ProfileSlice'
import { fetchAlerts } from '@meprism/shared/src/redux/alert/AlertSlice'
import {
  checkPendingOffers,
  fetchGrants,
} from '../../redux/product/ProductSlice'
import { Logger } from '@meprism/app-utils'
import { Box, useMediaQuery } from '@mui/material'
import { theme } from '../../theme/DashboardTheme'
// import { MpHeader } from '../shared/organism/MpHeader'


export const mainLayoutLoader = async () => {
  try {
    store.dispatch(fetchAlerts())
    store.dispatch(fetchGrants())
    store.dispatch(checkPendingOffers())
    const peopleFinderProfile = store
      .dispatch(fetchPeopleFinderProfile())
      .unwrap()
    const brokerList = store.dispatch(fetchBrokers())
    const profile = store.dispatch(FetchProfile()).unwrap()
    return defer({ peopleFinderProfile, brokerList, profile })
  } catch (error) {
    Logger.error(`Error fetching : ${error} ${error?.message}`)
    return redirect('/onboarding')
  }
}


export const MainLayout = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  useInboxSocket({ fqdn: process.env.REACT_APP_API_FQDN as string })
  const stylesbottom = {
    bottomRightIcon: {
      position: 'fixed',
      bottom:20,
      zIndex: 9999,
      right: 20
    },
  }
  return (
    <>
      {/* <MpHeader /> */}

      <Outlet />
     {!isMobile && <Box sx={{ ...stylesbottom.bottomRightIcon,cursor:'pointer' }}>
        <img
          src={message}
          id={'intercom-launcher'}
          alt="Description of the image"
          style={{
            maxWidth: '50px',
            maxHeight: '50px',
            marginRight: '10px',
          }}
        /></Box>}
    </>
  )
}
