import { Broker } from '@meprism/shared/src/redux/peopleFinder/PeopleFinderTypes'
import React from 'react'
import Accordion from '@mui/material/Accordion'
import { AccordionDetails, AccordionSummary, Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import MpSvgIcon from '../../shared/atom/MpSvgIcon'
import MpScanResult from '../../shared/atom/MpScanResult'

interface BrokerAccordionProps {
  brokerName: string
  results: Broker[]
  expanded: boolean
  setExpanded: (name: string | undefined) => void
  details?: (broker: Broker) => string
  inaccurate?: boolean
}

const styles = {
  expanded: {
    bgcolor: 'grey.50',
  },
}

export const BrokerAccordion = ({
  brokerName,
  results,
  expanded,
  setExpanded,
  details = () => '',
  inaccurate,
}: BrokerAccordionProps) => {
  const handleChange = (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? brokerName : undefined)
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      elevation={0}
      disableGutters
      // Below looks stupid, but if you don't set this true MUI puts border styles we can't override
      square={true}
      sx={{
        borderRadius: '20px',
        border: '1px solid #E0E0E0',
        '.MuiPaper-root-MuiAccordion-root:first-of-type': {
          borderTopLeftRadius: '20px',
        },
        '& .Mui-expanded': {
          ...styles.expanded,
        },
        '& .MuiCollapse-root': styles.expanded,
        '& .MuiCollapse-entered': {
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px',
        },
        '& .MuiAccordionSummary-root': {
          px: '20px',
          // Below is required to force text inside it to truncate based on length
          minWidth: 0,
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
        },
        '& .MuiAccordionDetails-root': {
          px: '20px',
        },
      }}>
      <AccordionSummary
        expandIcon={
          <MpSvgIcon
            icon={'collapse'}
            style={{
              backgroundColor: '#EFE0FA',
              borderRadius: '50%',
            }}
          />
        }
        sx={{
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
          '& .MuiAccordionSummary-content': {
            justifyContent: 'space-between',
            minWidth: 0,
          },
        }}>
        <Box sx={{ minWidth: 0, marginLeft: '10px' }}>
          <Typography variant={'body1'} noWrap>
            {brokerName}
          </Typography>
          <Typography
            variant={'body2'}
            noWrap
            sx={{
              lineHeight: 1,
              color: '#767676',
              whiteSpace: 'pre',
            }}>
            {expanded ? ' ' : details(results[0])}
          </Typography>
        </Box>
        <Box sx={{ mr: '20px', justifySelf: 'flex-end' }}>
          <Typography
            sx={{
              px: 3,
              // py: 1,
              borderRadius: '1000px',
              border: '1px solid #E0E0E0',
              bgcolor: 'white.main',
              color: '#767676',
            }}>
            {results.length}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {results.map((broker, index) => (
          <MpScanResult
            key={broker.scan_result_id}
            broker={broker}
            sxOverride={{ marginTop: index > 0 ? '10px' : '0px' }}
            details={details}
            inaccurate={inaccurate}
            accordion={true}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  )
}
