import { Box, ButtonBase, Typography,useMediaQuery } from '@mui/material'
import React from 'react'
import { theme } from '../../../theme/DashboardTheme'
// import MpSvgIcon from '../atom/MpSvgIcon'

interface IsThisYouProps {
  style?: any
  onAccuratePress: (accurate: boolean) => void
  validated: undefined | boolean
}

const Notme = (props: IsThisYouProps) => {
  const onThumbsUpPress = () => {
    if (props.validated !== true) {
      props.onAccuratePress(true)
    }
  }

  const onThumbsDownPress = () => {
    if (props.validated !== false) {
      props.onAccuratePress(false)
    }
  }
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      sx={[
        {
          width:80,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '32px',
        },
        props.style,
      ]}>
      {props.validated === true || props.validated === undefined ? (
        <ButtonBase onClick={onThumbsDownPress}>
          {/* <MpSvgIcon icon={'notMe'} /> */}
          <Typography
            variant={'label'}
            color={'#426BF0'}
            sx={{ marginLeft: !isMobile?'0px':'unset' , textDecoration: 'underline'}}>
            Not Me
          </Typography>
        </ButtonBase>
      ) : (
        <ButtonBase onClick={onThumbsUpPress}>
          {/* <MpSvgIcon icon={'me'} /> */}
          <Typography
            variant={'label'}
            color={'success.main'}
            sx={{ marginLeft: '7px' }}>
            This Is Me
          </Typography>
        </ButtonBase>
      )}
    </Box>
  )
}

export default Notme
