// For analytics purposes, we can get the user's sign in type from their username
import { Auth } from 'aws-amplify'
import { Logger } from '@meprism/app-utils'
import {
  AuthenticationActions,
  handleAutoSigninFailure,
  logOutUser,
  postConfirmLogin,
  refreshEntitlementsFromSession,
} from './authenticationSlice'
import { BaseThunkExtra } from '../base'
import { AnyAction, ThunkAction } from '@reduxjs/toolkit'
import { AmplifyAuthEvent, AuthenticationState } from './authenticationTypes'

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  { authentication: AuthenticationState },
  BaseThunkExtra,
  AnyAction
>

const getSignInTypeFromCapsule = (data: Record<string, any> | undefined) => {
  const username = data?.username as unknown
  if (typeof username !== 'string') {
    return 'Unknown'
  }
  if (username.startsWith('google_')) {
    return 'Google'
  } else if (username.toLowerCase().startsWith('signinwithapple')) {
    return 'Apple'
  } else {
    return 'Email'
  }
}

export type CreateAmplifyListenerProps = {
  onAutoSignInFailure: () => void
}

export const createAmplifyListener =
  ({ onAutoSignInFailure }: CreateAmplifyListenerProps): AppThunk =>
  (dispatch, getState, extra) => {
    return extra.Hub.listen('auth', (capsule) => {
      const { channel, payload } = capsule
      // this shouldn't be necessary but you can't be too careful these days
      if (channel !== 'auth') {
        return
      }
      const { event, data } = payload
      if (!event) {
        return
      }
      Logger.debug(`Amplify auth event: ${event}`)
      switch (event) {
        case 'autoSignIn':
          Logger.info('Auto signin')
          // nothing else to do here - this event is always accompanied by the signIn event below
          // and the real work happens there.
          // UNLESS we need to handle some MFA challenges or similar
          break
        case 'signIn':
          // sign in actions are handled here, because we don't have direct control over
          // when federated signin happens, best just to react to the event when it does
          Auth.currentAuthenticatedUser()
            .then((user) => {
              dispatch(postConfirmLogin(user)).then(() => {
                extra.AnalyticsManager.trackTypedEvent({
                  event: 'Sign In',
                  signInType: getSignInTypeFromCapsule(data),
                })
              })
            })
            .catch((error) => {
              Logger.error(`Error handling signin: ${error}`)
              dispatch(AuthenticationActions.removeBlockingRequest('SIGNIN'))
            })
          break
        case 'signOut':
          // not much to do here, actions on signout are handled via dispatch
          break
        case 'forgotPassword':
          extra.AnalyticsManager.trackTypedEvent({
            event: 'Forgot Password',
          })
          break
        case 'tokenRefresh':
          // on token refresh, pass data from the token to Redux to keep it in sync
          dispatch(refreshEntitlementsFromSession())
          break
        case 'tokenRefresh_failure':
          const state = getState()
          if (state?.authentication?.muid) {
            Logger.error(`Cognito token refresh failure ${data}`)
            extra.Toast.show({
              type: 'error',
              text1: 'Your session expired',
            })
            dispatch(logOutUser())
          } else {
            // token refresh failures not logged in I THINK are something wrong
            // but important thing is we should not be infinite looping our toasts
            extra.AnalyticsManager.error('TokenRefreshFailure')
          }
          break
        case 'autoSignIn_failure':
          // Reading thru Amplify docs, seems most likely this is caused
          // by app being hard-closed during the code flow, which is something
          // we handle that by signing in with stored credentials
          // @TODO: Find some way to hack around this...

          dispatch(handleAutoSigninFailure())
            .then(() => {
              dispatch(AuthenticationActions.removeBlockingRequest('SIGNIN'))
            })
            .catch((error) => {
              Logger.error(`Error handling autosignin failure: ${error}`)
              dispatch(AuthenticationActions.removeBlockingRequest('SIGNIN'))
              // in case of fallback failing, best we can do is to send the user to login
              // maybe we toast this case? IDK
              onAutoSignInFailure()
            })
          break
        case 'updateUserAttributes_failure':
          extra.Toast.show({
            type: 'error',
            text1: 'Your attributes could not be saved',
          })
          break
        case 'updateUserAttributes':
          Logger.info(`UpdateUserAttributes: ${JSON.stringify(data)}`)
          break
        case 'verify':
          extra.Toast.show({
            type: 'success',
            text1: 'Your MFA settings have been saved',
          })
          break
      }
      dispatch(
        AuthenticationActions.emitAmplifyEvent(event as AmplifyAuthEvent),
      )
    })
  }
