export const minPasswordLength = 10

export const validatePasswords = (
  password: string,
  confirmPassword: string,
) => ({
  hasSufficientLength: password.length >= minPasswordLength,
  hasUpperCase: new RegExp(/[A-Z]/).test(password),
  hasSymbol: new RegExp(/[^A-Za-z0-9]/).test(password),
  hasMatchingPassword: password === confirmPassword,
})
