import React, { createContext, useContext, ReactNode, useState } from 'react';

interface FormDataContextProps {
  formData: Record<string, any>;
  setFormValue: (name: string, value: any) => void;
}

const FormDataContext = createContext<FormDataContextProps | undefined>(undefined);

export const FormDataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [formData, setFormData] = useState<Record<string, any>>({});

  const setFormValue = (name: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <FormDataContext.Provider value={{ formData, setFormValue }}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => {
  const context = useContext(FormDataContext);
  if (!context) {
    throw new Error('useFormData must be used within a FormDataProvider');
  }
  return context;
};
