import { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import MpSvgIcon, { MpIconList } from '../atom/MpSvgIcon'
import { Button, Box } from '../MaterialExports'

const SignInButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  boxShadow: '0px 0px 5px #00000029',
  backgroundColor: theme.palette.background.default,
  padding: '11px 24px',
  borderRadius: '32px',
  minWidth: '260px',
}))

type SignInSources = 'apple' | 'google' | 'facebook' | 'phone' | 'mail'
type OmittedButtonProps = Omit<ButtonProps, 'variant' | 'startIcon'>
interface MpSignInButtonProps extends OmittedButtonProps {
  source: SignInSources
}
const MpSignInButton = (props: MpSignInButtonProps) => {
  const [signInConfigs, setSignInConfigs] = useState<{
    text: string
    icon: MpIconList
  } | null>(null)

  useEffect(() => {
    const getButtonText = (source: SignInSources) => {
      if (source === 'apple') {
        setSignInConfigs({ text: 'Apple', icon: 'appleLogo' })
      } else if (source === 'google') {
        setSignInConfigs({ text: 'Google', icon: 'googleLogo' })
      } else if (source === 'facebook') {
        setSignInConfigs({ text: 'Facebook', icon: 'facebookLogo' })
      } else if (source === 'phone') {
        setSignInConfigs({ text: 'Phone #', icon: 'phone' })
      } else if (source === 'mail') {
        setSignInConfigs({ text: 'Email', icon: 'envelope' })
      } else {
        throw new Error('MpSignInButton - sign in source not found')
      }
    }

    getButtonText(props.source)
  }, [props.source])

  return (
    <Box>
      {signInConfigs && (
        <SignInButton
          variant={'text'}
          startIcon={<MpSvgIcon icon={signInConfigs.icon} />}
          {...props}>
          {`Sign In With ${signInConfigs.text}`}
        </SignInButton>
      )}
    </Box>
  )
}

export default MpSignInButton
